import { Card } from "antd";
import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserOrderAction } from "../../../redux/User/user.actions";
import { isAPhone } from "../../../utils";
import MyOrdersTable from "./MyOrdersTable";

interface Props {}

const MyOrdersPanel = (props: Props) => {
  const dispatch = useDispatch();
  const { orders, fetching } = useSelector(
    (state: RootStateOrAny) => state.user.orders
  );
  useEffect(() => {
    dispatch(getUserOrderAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadOthersOrders = () => {
    dispatch(getUserOrderAction());
  };

  return (
    <>
      <Card
        headStyle={{ fontWeight: "bold" }}
        bodyStyle={{ padding: 0 }}
        title="I miei ordini"
        bordered={false}
      >
        <div className="desktop-card-order-table">
          <MyOrdersTable
            loading={fetching}
            orders={orders}
            isAPhone={isAPhone()}
            loadOthers={loadOthersOrders}
          />
        </div>
      </Card>
    </>
  );
};

export default MyOrdersPanel;
