import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  List,
  Row,
  Typography,
} from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FirestoreOrder } from "../../../types/firestore/order.firestore.type";
import CheckoutPriceComponent from "./CheckoutPriceComponent";
import { FaStoreAlt } from "react-icons/fa";

//Import for date
import moment from "moment";
import "moment/locale/it";
import locale from "antd/es/date-picker/locale/it_IT";
import { capitalizeDate } from "../../../utils";
import { RiTruckLine } from "react-icons/ri";
import { checkoutConstant } from "../../../utils/checkoutUtils";
import {
  FirestoreAddress,
  FirestoreUser,
} from "../../../types/firestore/user.firestore.type";
import SelectableCard from "../../../components/SelectableCard";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { updateUserInfo } from "../../../redux/User/user.actions";
import { logAnalyticsEventAction } from "../../../redux/Website/website.actions";

const shippingCheckoutFormName = "shippingCheckoutForm";

const basicStyleShippingAddressCard = {
  width: "100%",
  textAlign: "left",
} as React.CSSProperties;

const basicSelectedStyleShippingAddressCard = {
  ...basicStyleShippingAddressCard,
  outline: "4px solid #07b07a",
  outlineOffset: "-4px",
  boxShadow:
    "0 1px 2px -2px rgba(0, 0, 0, 0.16),0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
} as React.CSSProperties;

const disabledDeliveringDate = (
  currentDate: moment.Moment,
  nextAvailableMoment: moment.Moment
): boolean => currentDate < nextAvailableMoment;

const checkShipmentStep = (orderState: FirestoreOrder): boolean => {
  if (orderState.delivering_date !== undefined) {
    if (
      orderState.sm_in_store_collection &&
      orderState.billing &&
      orderState.billing.address !== undefined &&
      orderState.billing.address.zipcode !== undefined &&
      orderState.billing.address.zipcode !== null &&
      orderState.billing.address.zipcode !== ""
    ) {
      return true;
    } else if (
      orderState.sm_delivered &&
      orderState.billing &&
      orderState.billing.address !== undefined &&
      orderState.billing.address.zipcode !== undefined &&
      orderState.billing.address.zipcode !== null &&
      orderState.billing.address.zipcode !== "" &&
      orderState.shipping &&
      (orderState.shipping.address !== undefined ||
        orderState.shipping.same_as_billing_address)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

interface Props {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  orderState: FirestoreOrder;
  setOrderState: Dispatch<SetStateAction<FirestoreOrder>>;
  userInfo: FirestoreUser;
  updateBillingAddress: (userInfo: FirestoreUser) => void;
  availableCities?: any[];
  toggleAddFavoriteAddress: () => void;
  nextAvailableMoment: moment.Moment;
  firebaseIsInitializing?: boolean;
}

const ShipmentStep = ({
  currentStep,
  setCurrentStep,
  orderState,
  setOrderState,
  userInfo,
  updateBillingAddress,
  availableCities,
  toggleAddFavoriteAddress,
  nextAvailableMoment,
  firebaseIsInitializing,
}: Props) => {
  const dispatch = useDispatch();
  const onClickNextStep = () => {
    setCurrentStep(2);
  };
  const [shippingForm] = Form.useForm();

  const [isNextStepButtonDisable, setIsNextStepButtonDisable] = useState(true);

  const zipcodeList = availableCities?.map((city) =>
    (city?.Postal_Code).toString()
  );

  const onClickShippingMethodCard = (shippingMethod) => {
    switch (shippingMethod) {
      case checkoutConstant.SM_DELIVERED:
        setOrderState({
          ...orderState,
          sm_delivered: true,
          sm_in_store_collection: false,
          shipping_cost: 5,
        });
        break;
      case checkoutConstant.SM_IN_STORE_COLLECTION:
        setOrderState({
          ...orderState,
          sm_delivered: false,
          sm_in_store_collection: true,
          shipping_cost: 0,
        });
        break;
      default:
        break;
    }
  };

  const onClickShippingAddressCard = (address: FirestoreAddress) => {
    setOrderState({
      ...orderState,
      shipping: { ...orderState?.shipping, address: address },
    });
  };

  const onChangeCheckboxShippingAddress = (e) => {
    setOrderState({
      ...orderState,
      shipping: {
        ...orderState?.shipping,
        same_as_billing_address: e.target.checked,
      },
    });
  };

  const onChangeDeliveringDate = (value: moment.Moment | null) => {
    setOrderState({
      ...orderState,
      delivering_date: value?.toDate(),
    });
  };

  const onFinishShippingForm = (value) => {
    setCurrentStep(2);
  };

  useEffect(() => {
    setOrderState({
      ...orderState,
      billing: {
        address: userInfo?.billing_address,
        info: {
          email: userInfo?.email,
          first_name: userInfo?.first_name,
          last_name: userInfo?.last_name,
          phone: userInfo?.phone,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    setIsNextStepButtonDisable(!checkShipmentStep(orderState));
  }, [orderState]);

  useEffect(() => {
    if (orderState.delivering_date !== undefined) {
      shippingForm.setFieldsValue({
        ...orderState,
        delivering_date: moment(orderState.delivering_date),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState.delivering_date]);

  useEffect(() => {
    if (
      !firebaseIsInitializing &&
      orderState &&
      (orderState?.sm_delivered || orderState?.sm_in_store_collection)
    ) {
      dispatch(
        logAnalyticsEventAction({
          key: "add_shipping_info",
          value: JSON.parse(JSON.stringify(orderState)),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState, firebaseIsInitializing]);

  const removeFavoriteAddressByUid = (uid: string | undefined) => {
    let newUserInfo = Object.assign({}, userInfo);
    let newShippingAddresses = newUserInfo?.shipping_addresses?.filter(
      (address) => address.uid !== uid
    );
    newUserInfo = {
      ...newUserInfo,
      shipping_addresses: newShippingAddresses,
    };
    dispatch(updateUserInfo(newUserInfo));
  };

  return (
    <Row style={{ display: "flex", justifyContent: "space-around" }}>
      <Col md={12} xs={24}>
        <Row className="full-space-between-row">
          <Form
            style={{ width: "100%", textAlign: "start" }}
            name={shippingCheckoutFormName}
            layout="vertical"
            form={shippingForm}
            onFinish={onFinishShippingForm}
          >
            <Form.Item
              name="shipping_method"
              label={
                <Typography.Text strong>
                  <span style={{ color: "red" }}>*</span> Modalità di consegna
                </Typography.Text>
              }
              rules={[
                {
                  message: "Seleziona una modalità di consegna",
                },
              ]}
            >
              <Row className="full-space-between-row">
                <Col span={11}>
                  <SelectableCard
                    icon={<FaStoreAlt style={{ fontSize: "2.5rem" }} />}
                    title="Ritiro in negozio"
                    hoverable
                    selected={orderState.sm_in_store_collection}
                    onClickCardFunction={() =>
                      onClickShippingMethodCard(
                        checkoutConstant.SM_IN_STORE_COLLECTION
                      )
                    }
                  />
                </Col>
                <Col span={11}>
                  <SelectableCard
                    icon={<RiTruckLine style={{ fontSize: "2.5rem" }} />}
                    title="Spedizione a domicilio"
                    hoverable
                    selected={orderState.sm_delivered}
                    onClickCardFunction={() =>
                      onClickShippingMethodCard(checkoutConstant.SM_DELIVERED)
                    }
                  />
                </Col>
              </Row>
            </Form.Item>
            {(orderState.sm_delivered !== undefined ||
              orderState.sm_in_store_collection !== undefined) && (
              <>
                <Form.Item
                  name="delivering_date"
                  label={
                    <Typography.Text strong>
                      Data di {orderState.sm_delivered ? "consegna" : "ritiro"}
                    </Typography.Text>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Seleziona una data",
                    },
                  ]}
                >
                  <DatePicker
                    locale={locale}
                    format={(value) =>
                      capitalizeDate(value.format("dddd DD MMMM YYYY"))
                    }
                    disabledDate={(currentDate) =>
                      disabledDeliveringDate(currentDate, nextAvailableMoment)
                    }
                    onChange={onChangeDeliveringDate}
                  />
                </Form.Item>
                <Form.Item
                  name="billing_address"
                  label={
                    <Typography.Text strong>
                      <span style={{ color: "red" }}>*</span> Indirizzo di
                      fatturazione
                    </Typography.Text>
                  }
                  rules={[
                    {
                      message: "Seleziona un indirizzo",
                    },
                  ]}
                >
                  <Row className="full-space-between-row">
                    {userInfo?.billing_address?.zipcode ? (
                      <Card
                        style={{
                          width: "100%",
                          textAlign: "left",
                        }}
                        className="card-fixed-shadow"
                        bordered={false}
                        title={`${
                          userInfo?.billing_address?.first_name ||
                          userInfo?.first_name
                        } ${
                          userInfo?.billing_address?.last_name ||
                          userInfo?.last_name
                        }`}
                        extra={
                          <Button
                            onClick={() => updateBillingAddress(userInfo)}
                          >
                            Modifica
                          </Button>
                        }
                      >
                        <>
                          {userInfo?.billing_address?.street_name},{" "}
                          {userInfo?.billing_address?.street_number}
                          <br />
                          {userInfo?.billing_address?.city},{" "}
                          {userInfo?.billing_address?.zipcode}
                          <br />
                          {userInfo?.billing_address?.province}{" "}
                          {userInfo?.billing_address?.state}
                          <br />
                          <br />
                          {userInfo?.phone}
                        </>
                      </Card>
                    ) : (
                      <Button
                        onClick={() => updateBillingAddress(userInfo)}
                        block
                      >
                        Aggiungi indirizzo
                      </Button>
                    )}
                  </Row>
                </Form.Item>
                {orderState.sm_delivered && (
                  <>
                    <Form.Item
                      name="shipping_address"
                      label={
                        <Typography.Text strong>
                          <span style={{ color: "red" }}>*</span> Indirizzo di
                          spedizione
                        </Typography.Text>
                      }
                      rules={[
                        {
                          message: "Seleziona un indirizzo",
                        },
                      ]}
                    >
                      <>
                        {zipcodeList?.includes(
                          userInfo?.billing_address?.zipcode
                        ) && (
                          <Row
                            style={{ margin: "14px 0" }}
                            className="full-space-between-row"
                          >
                            <Checkbox
                              checked={
                                orderState?.shipping?.same_as_billing_address
                              }
                              onChange={onChangeCheckboxShippingAddress}
                            >
                              Uguale all'indirizzo di fatturazione
                            </Checkbox>
                          </Row>
                        )}

                        {!orderState?.shipping?.same_as_billing_address && (
                          <Row className="full-space-between-row">
                            <List
                              style={{ width: "100%" }}
                              grid={{
                                gutter: 12,
                                xs: 1,
                                sm: 1,
                                md: 2,
                                lg: 2,
                                xl: 2,
                                xxl: 2,
                              }}
                              dataSource={userInfo?.shipping_addresses}
                              renderItem={(address: FirestoreAddress) => (
                                <List.Item>
                                  <SelectableCard
                                    extra={
                                      <Button
                                        onClick={() =>
                                          removeFavoriteAddressByUid(
                                            address?.uid
                                          )
                                        }
                                        icon={<CloseOutlined />}
                                        type="text"
                                      />
                                    }
                                    selected={
                                      orderState?.shipping?.address?.uid ===
                                      address?.uid
                                    }
                                    headerTitle={`${address?.first_name} ${address?.last_name}`}
                                    onClickCardFunction={() =>
                                      zipcodeList?.includes(address?.zipcode) &&
                                      onClickShippingAddressCard(address)
                                    }
                                    hoverable={zipcodeList?.includes(
                                      address?.zipcode
                                    )}
                                    notClickableMessage="Indirizzo non raggiungibile"
                                    title={
                                      <>
                                        {address?.street_name},{" "}
                                        {address?.street_number}
                                        <br />
                                        {address?.city}, {address?.zipcode}
                                        <br />
                                        {address?.province} {address?.state}
                                      </>
                                    }
                                    additionalSelectedStyle={
                                      basicSelectedStyleShippingAddressCard
                                    }
                                    additionalStyle={
                                      basicStyleShippingAddressCard
                                    }
                                    additionalBodyStyle={{
                                      height: "none",
                                      padding: "none",
                                      textAlign: "left",
                                      fontWeight: 400,
                                    }}
                                  />
                                </List.Item>
                              )}
                            />
                            <Button onClick={toggleAddFavoriteAddress} block>
                              Aggiungi indirizzo
                            </Button>
                          </Row>
                        )}
                      </>
                    </Form.Item>
                  </>
                )}
              </>
            )}
          </Form>
        </Row>
      </Col>
      <Col md={9} xs={24}>
        <CheckoutPriceComponent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          orderState={orderState}
          onClickNextStep={onClickNextStep}
          form={shippingCheckoutFormName}
          isButtonDisabled={isNextStepButtonDisable}
          availableCities={availableCities}
          nextAvailableMoment={nextAvailableMoment}
        />
      </Col>
    </Row>
  );
};

export default ShipmentStep;
