import React from "react";
import { Carousel, Row, Col } from "antd";
import CarouselCard from "./CarouselCard";
import { FirestoreHomePageCarousel } from "../../../types/firestore/homePage.firestore.type";
import CarouselVideo from "./CarouselVideo";

interface Props {
  items: FirestoreHomePageCarousel[];
}

const HomeCarousel = ({ items }: Props) => {
  return (
    <Carousel autoplay autoplaySpeed={6000}>
      {items.map((item, index) => {
        if (item.visible) {
          return (
            <Row key={index} align="middle">
              <Col className="top-height">
                {item?.video_visible ? (
                  <CarouselVideo url={item?.video_url} />
                ) : (
                  <img className="carousel-image" src={item.image_url} alt="" />
                )}
                {item.card?.visible && (
                  <Row
                    justify="end"
                    align="middle"
                    style={{
                      position: "relative",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Col
                      xs={24}
                      sm={24}
                      md={12}
                      lg={9}
                      xl={6}
                      style={{ padding: "0px 16px 0px 16px" }}
                    >
                      <CarouselCard
                        title={item.card?.title}
                        description={item.card?.text}
                        buttonTitle={item.card?.button?.title}
                        navigateTo={item.card?.button?.to_path}
                        isButtonVisible={item.card.button?.visible}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          );
        } else {
          return "";
        }
      })}
    </Carousel>
  );
};

export default HomeCarousel;
