import React, { useEffect } from "react";
import { Row, Typography, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ProductTable from "./ProductTable";
import { NavLink } from "react-router-dom";
import Path from "../../../utils/pathConstants";
import { useDispatch } from "react-redux";
import { resetFormProductData } from "../../../redux/Products/products.actions";

const { Title } = Typography;

interface Props {}

const Products = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFormProductData());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row>
        <Title level={3}>Prodotti</Title>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div></div>
        <div>
          <NavLink to={`${Path.ADMIN}/products/add-product`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Aggiungi prodotto
            </Button>
          </NavLink>
        </div>
      </Row>
      <Row style={{ marginTop: "12px" }}>
        <ProductTable />
      </Row>
    </div>
  );
};

export default Products;
