const tradStatus = {
  CANCELED: "CANCELLATO",
  REFUNDED: "RIMBORSATO",
  PENDING: "IN ATTESA",
  PAID: "PAGATO",
  PROCESSING: "IN ELABORAZIONE",
  CONFIRMED: "CONFERMATO",
  PICKUPABLE: "RITIRABILE",
  SHIPPING: "IN CONSEGNA",
  COMPLETED: "COMPLETATO",
};

const orderStatus = {
  CANCELED: "CANCELED",
  REFUNDED: "REFUNDED",
  PENDING: "PENDING",
  PAID: "PAID",
  PROCESSING: "PROCESSING",
  CONFIRMED: "CONFIRMED",
  PICKUPABLE: "PICKUPABLE",
  SHIPPING: "SHIPPING",
  COMPLETED: "COMPLETED",
};

export const getTradStatus = (status: string): string => {
  return tradStatus[status];
};

export const nextActions = (
  status: string,
  inStoreCollection: boolean,
  isPaid: boolean
): { isPrimary?: boolean; isDanger?: boolean; status: string }[] => {
  const response = [] as {
    isPrimary?: boolean;
    isDanger?: boolean;
    status: string;
  }[];

  switch (status) {
    case orderStatus.PENDING:
      break;
    case orderStatus.PAID:
      response.push({
        isPrimary: true,
        status: orderStatus.CONFIRMED,
      });
      response.push({
        isDanger: true,
        status: orderStatus.REFUNDED,
      });
      break;

    case orderStatus.PROCESSING:
      response.push({
        isPrimary: true,
        status: orderStatus.CONFIRMED,
      });
      break;

    case orderStatus.CONFIRMED:
      if (inStoreCollection) {
        response.push({
          isPrimary: true,
          status: orderStatus.PICKUPABLE,
        });
      } else {
        response.push({
          isPrimary: true,
          status: orderStatus.SHIPPING,
        });
      }
      if (isPaid) {
        response.push({
          isDanger: true,
          status: orderStatus.REFUNDED,
        });
      }
      break;

    case orderStatus.SHIPPING:
      response.push({
        isPrimary: true,
        status: orderStatus.COMPLETED,
      });
      break;

    case orderStatus.PICKUPABLE:
      response.push({
        isPrimary: true,
        status: orderStatus.COMPLETED,
      });

      break;

    default:
      break;
  }

  return response;
};
