import React from "react";
import { FirestoreProduct } from "../../../types/firestore/product.firestore.type";
import ProductDetailCardBouquetDetails from "./ProductDetailCardBouquetDetails";
import ProductDetailCardPlantDetails from "./ProductDetailCardPlantDetails";

interface Props {
  isProductLoading: boolean;
  product: FirestoreProduct;
}

const ProductDetailCardDetails = ({ isProductLoading, product }: Props) => {
  return (
    <>
      {product?.is_other && ""}
      {product?.is_a_plant && (
        <ProductDetailCardPlantDetails
          isProductLoading={isProductLoading}
          product={product}
        />
      )}
      {(product?.is_a_bouquet || product?.is_a_single_flower) && (
        <ProductDetailCardBouquetDetails
          isProductLoading={isProductLoading}
          product={product}
        />
      )}
    </>
  );
};

export default ProductDetailCardDetails;
