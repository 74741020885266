import React from "react";
import HomeCarousel from "./HomeCarousel";
import HomeCard from "./HomeCard";
import InfoCard from "./InfoCard";
import { Row, Col } from "antd";
import { RootStateOrAny, useSelector } from "react-redux";
import { FirestorePages } from "../../../types/firestore/pages.firestore.type";
import { Helmet } from "react-helmet";

interface Props {}

const Home = (props: Props) => {
  const pages: FirestorePages = useSelector(
    (state: RootStateOrAny) => state.website.pages.pages
  );
  const { shopHours } = useSelector(
    (state: RootStateOrAny) => state.website.openingInfo
  );
  const hours = shopHours.map((hour) => {
    const formattedHours = hour.hours.map((h) => `${h.start} - ${h.end}`);
    const hours = formattedHours.join(" | ");
    return {
      day: hour.day,
      hours: hours,
    };
  });
  const { home_page } = pages;
  return (
    <div className="home">
      <Helmet>
        <title>Home | Le Clematis Fioreria</title>
        <meta
          name="description"
          content="Fioreria Le Clematis Galzignano Terme"
        />
        <meta name="keywords" content="fioreria,flowers,weddings,fiori" />
      </Helmet>
      <Row className="top-height">
        <Col>
          <HomeCarousel items={home_page.carousel} />
        </Col>
      </Row>
      <Row className="divider-row">
        <Col className="home-card-left" lg={12} sm={24}>
          <div className="bottom-height">
            <HomeCard
              button={{ text: "SHOP" }}
              img={{ src: "/assets/images/img-shop.webp" }}
            />
          </div>
        </Col>
        <Col className="home-card-right" lg={12} sm={24}>
          <div className="bottom-height">
            <InfoCard
              hours={hours}
              img={{
                src: "/assets/images/img-info.webp",
                optionalStyle: { objectPosition: "0 0" },
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
