import { Typography } from "antd";
import React from "react";

interface Props {}

const UnderMaintenance = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <img src="/assets/logo/logo.svg" width="200px" alt="" />
      <Typography.Text style={{ marginTop: "20px", fontSize: "1.3em" }} strong>
        OPENING SOON
      </Typography.Text>
    </div>
  );
};

export default UnderMaintenance;
