import { Card, Col, Row, Typography } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { FirestoreProduct } from "../../../types/firestore/product.firestore.type";

interface Props {
  product: FirestoreProduct;

  typeSelected: string;
  selectedProduct: any;
  setTypeSelected: Dispatch<SetStateAction<string>>;
  setSelectedProduct: Dispatch<SetStateAction<any>>;
}

const ProductDetailCardBouquet = ({
  product,
  typeSelected,
  setTypeSelected,
  setSelectedProduct,
  selectedProduct,
}: Props) => {
  return (
    <Row style={{ marginTop: "12px" }}>
      <Row>
        <Typography.Text strong>
          {`seleziona la misura`.toLocaleUpperCase()}
        </Typography.Text>
      </Row>
      <Row
        style={{
          width: "100%",
          marginTop: "8px",
          flexWrap: "nowrap",
        }}
        justify="space-between"
      >
        {product?.prices?.map((price, index) => {
          const isSelected =
            price?.type === typeSelected
              ? {
                  outline: "4px solid #07b07a",
                  outlineOffset: "-4px",
                }
              : {};
          return (
            <Col key={price?.type} span={7}>
              <Card
                style={{
                  width: "100%",
                  marginRight: "12px",
                  ...isSelected,
                }}
                bodyStyle={{ padding: "12px" }}
                hoverable
                onClick={() => {
                  setTypeSelected(price?.type);
                  setSelectedProduct({
                    ...selectedProduct,
                    price: price?.price,
                    configuration: {
                      ...selectedProduct?.configuration,
                      price: price?.price,
                      stems_number: price?.stems_number,
                      type: price?.type,
                    },
                  });
                }}
              >
                <Row style={{ justifyContent: "center" }}>
                  <Typography.Text
                    style={{ fontSize: "0.9em", color: "#666666" }}
                    strong
                  >
                    {price?.type?.toLocaleUpperCase()}
                  </Typography.Text>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                  <Typography.Text style={{ fontSize: "0.8em" }}>
                    {`€ ${parseFloat(price?.price?.toString()).toFixed(2)}`}
                  </Typography.Text>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                  <Typography.Text
                    style={{ fontSize: "0.7em", color: "#666666" }}
                  >
                    {`${price?.stems_number} steli`}
                  </Typography.Text>
                </Row>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Row>
  );
};

export default ProductDetailCardBouquet;
