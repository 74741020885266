import { ShopFilters } from "../../types/shopFilters.type";
import { shopTypes } from "./shop.types";

const initialFilters: ShopFilters = {
  types: "all",
  is_by_string: false,
};

const INITIAL_STATE = {
  isFilterDrawerOpen: false,
  price: [0, 400],
  filters: undefined,
  queriedProducts: [],
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case shopTypes.TOGGLE_FILTER_DRAWER:
      return {
        ...state,
        isFilterDrawerOpen: !state.isFilterDrawerOpen,
      };
    case shopTypes.CHANGE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case shopTypes.CHANGE_GET_SHOP_PRODUCTS_STATUS:
      return {
        ...state,
        getShopProductsStatus: action.payload,
      };
    case shopTypes.SET_SHOP_PRODUCTS:
      return {
        ...state,
        queriedProducts: action.payload,
      };
    case shopTypes.SET_SHOP_PRODUCT_DETAILS:
      return {
        ...state,
        price: action.payload,
      };
    case shopTypes.REMOVE_ALL_SHOP_FILTERS_PRODUCTS:
      return {
        ...state,
        filters: initialFilters,
      };
    default:
      return state;
  }
};

export default shopReducer;
