import { Button, Card, Divider, Row, Tooltip, Typography } from "antd";
import moment from "antd/node_modules/moment";
import React, { ReactElement } from "react";
import { GiFlowerPot } from "react-icons/gi";
import { RiTruckLine } from "react-icons/ri";
import DataDisplayWithEdit from "../../../components/DataDisplayWithEdit";
import { FirestoreOrder } from "../../../types/firestore/order.firestore.type";
import { capitalizeDate } from "../../../utils";

interface Props {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  orderState: FirestoreOrder;
  onClickNextStep?: () => void;
  isButtonDisabled: boolean;
  form?: string;
  availableCities?: any[];
  buttonTitle?: string | ReactElement;
  nextAvailableMoment?: moment.Moment;
  isButtonLoaded?: boolean;
}

const CheckoutPriceComponent = ({
  currentStep,
  setCurrentStep,
  orderState,
  onClickNextStep,
  form,
  isButtonDisabled,
  availableCities,
  buttonTitle,
  nextAvailableMoment,
  isButtonLoaded,
}: Props) => {
  const returnOnShipmentStep = () => {
    setCurrentStep(1);
  };
  return (
    <>
      {currentStep < 2 && (
        <>
          <Row className="full-space-between-row">
            <Card style={{ width: "100%", marginBottom: "16px" }}>
              <Row
                className="full-space-between-row"
                style={{ justifyContent: "center" }}
              >
                <RiTruckLine style={{ fontSize: "2.5rem" }} />
              </Row>
              <Row>
                <Typography.Paragraph style={{ margin: 0 }} strong>
                  La <u>consegna a domicilio</u> e' disponibile solo per
                  determinate{" "}
                  <Tooltip
                    title={availableCities
                      ?.filter(
                        (thing, index, self) =>
                          index ===
                          self.findIndex(
                            (t) => t.Place_Name === thing.Place_Name
                          )
                      )
                      .map((city) => (
                        <div key={city?.objectId}>{city?.Place_Name}</div>
                      ))}
                  >
                    <u>città</u>
                  </Tooltip>
                  .
                </Typography.Paragraph>
              </Row>
            </Card>
          </Row>
          <Row className="full-space-between-row">
            <Card style={{ width: "100%", marginBottom: "16px" }}>
              <Row
                className="full-space-between-row"
                style={{ justifyContent: "center" }}
              >
                <GiFlowerPot style={{ fontSize: "2.5rem" }} />
              </Row>
              <Row className="full-space-row centered">
                <Typography.Paragraph style={{ margin: 0 }} strong>
                  Ricevi il tuo ordine
                  <div style={{ color: "#07b07a" }}>
                    {capitalizeDate(nextAvailableMoment?.format("dddd D MMMM"))}
                  </div>
                </Typography.Paragraph>
              </Row>
            </Card>
          </Row>
        </>
      )}
      {currentStep === 2 && (
        <>
          <Row className="full-space-row">
            <DataDisplayWithEdit
              name="Modalità consegna"
              value={
                orderState.sm_in_store_collection
                  ? "Ritiro in negozio"
                  : `${
                      orderState?.shipping?.same_as_billing_address
                        ? `${orderState.billing?.address?.street_name} ${orderState.billing?.address?.street_number},\n ${orderState.billing?.address?.city} ${orderState.billing?.address?.zipcode}`
                        : `${orderState.shipping?.address?.street_name} ${orderState.shipping?.address?.street_number},\n ${orderState.shipping?.address?.city} ${orderState.shipping?.address?.zipcode}`
                    }`
              }
              editFunction={returnOnShipmentStep}
            />
          </Row>
          <Row className="full-space-row">
            <DataDisplayWithEdit
              name={
                orderState.sm_in_store_collection
                  ? "Data di ritiro"
                  : "Data di consegna"
              }
              value={capitalizeDate(
                moment(orderState.delivering_date).format("dddd DD MMMM YYYY")
              )}
              editFunction={returnOnShipmentStep}
            />
          </Row>
          {orderState?.pm_in_store_payment !== undefined && (
            <Row className="full-space-row">
              <DataDisplayWithEdit
                name="Modalità pagamento"
                value={
                  orderState.sm_delivered
                    ? `${
                        orderState?.pm_is_stripe_payment
                          ? "Pagamento con carta"
                          : "Pagamento online con PayPal"
                      }`
                    : `${
                        orderState.pm_in_store_payment
                          ? "Pagamento in negozio"
                          : `${
                              orderState?.pm_is_stripe_payment
                                ? "Pagamento con carta"
                                : "Pagamento online con PayPal"
                            }`
                      }`
                }
                editFunction={
                  orderState.sm_in_store_collection
                    ? () => window.scrollTo({ top: 0, behavior: "smooth" })
                    : undefined
                }
              />
            </Row>
          )}
        </>
      )}
      <Row className="full-space-between-row">
        <Typography.Text>Subtotale</Typography.Text>
        <Typography.Text strong>
          {`€ ${parseFloat(orderState?.subtotal?.toString()).toFixed(2)}`}
        </Typography.Text>
      </Row>
      <Row className="full-space-between-row">
        <Typography.Text>Consegna</Typography.Text>
        <Typography.Text strong>
          {orderState?.shipping_cost
            ? `€ ${parseFloat(
                (orderState?.shipping_cost || 0).toString()
              ).toFixed(2)}`
            : "--"}
        </Typography.Text>
      </Row>
      <Divider />
      <Row className="full-space-between-row">
        <Typography.Text style={{ fontSize: "1.5em" }} strong>
          Totale
        </Typography.Text>
        <Typography.Text style={{ fontSize: "1.5em" }} strong>
          {`€ ${parseFloat(
            (orderState?.subtotal + (orderState?.shipping_cost || 0)).toString()
          ).toFixed(2)}`}
        </Typography.Text>
      </Row>
      <Row className="full-space-between-row" style={{ marginTop: "16px" }}>
        <Button
          type="primary"
          form={form && form}
          htmlType={form ? "submit" : undefined}
          onClick={form ? undefined : onClickNextStep}
          disabled={isButtonDisabled}
          loading={isButtonLoaded}
          block
        >
          {buttonTitle === undefined
            ? `${"Procedi con l'ordine".toLocaleUpperCase()}`
            : buttonTitle}
        </Button>
      </Row>
    </>
  );
};

export default CheckoutPriceComponent;
