import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { CartProduct } from "../../types/cartProduct.type";
import { localStorageKeys, requestStatus } from "../../utils";
import {
  generateCartKey,
  getCartArrayToMap,
  getCartMapToArray,
} from "../../utils/cartUtils";
import { logAnalyticsEventAction } from "../Website/website.actions";
import {
  changeUpdateCartStatus,
  deleteCurrentCartAction,
  updateCartArray,
  updateCartItemsCounter,
  updateCartMap,
  updateCartSubTotal,
} from "./cart.actions";
import cartTypes from "./cart.types";

interface ActionWithPayload {
  type: string;
  payload?: any;
}

const itemCounter = (cartArray: CartProduct[]) =>
  cartArray.reduce((total, item) => total + item?.quantity, 0);

const calculateCartSubTotal = (cartArray: CartProduct[]) =>
  cartArray.reduce((total, item) => total + item?.quantity * item?.price, 0);

export function* finalCartOperations(
  cartMap: Map<string | undefined, CartProduct>
) {
  const cartArray: CartProduct[] = getCartMapToArray(cartMap);

  // SAVE CART MAP
  yield put(updateCartMap(cartMap));
  // SAVE CART ARRAY
  yield put(updateCartArray(cartArray));
  // RE-COUNT ELEMENTS
  yield put(updateCartItemsCounter(itemCounter(cartArray)));
  // RE-COUNT TOTAL
  yield put(updateCartSubTotal(calculateCartSubTotal(cartArray)));

  yield localStorage.setItem(
    localStorageKeys.CART_ARRAY,
    JSON.stringify(cartArray)
  );
}

export function* updateCart({
  payload: product,
}: {
  type: string;
  payload: CartProduct;
}) {
  // STARTED
  yield put(changeUpdateCartStatus(requestStatus.STARTED));

  // RETRIEVE CURRENT CART MAP
  const cartMap: Map<string, any> = yield select((state) => state.cart.cartMap);
  const productCartKey: string = generateCartKey(product);
  if (cartMap.has(productCartKey)) {
    cartMap.get(productCartKey).quantity += product.quantity;
  } else {
    cartMap.set(productCartKey, product);
  }

  // FINAL CART OPERATIONS
  yield finalCartOperations(cartMap);
  // COMPLETED
  yield put(changeUpdateCartStatus(requestStatus.COMPLETED));
}

export function* updateItemOnCart({ payload: data }: ActionWithPayload) {
  // STARTED
  yield put(changeUpdateCartStatus(requestStatus.STARTED));

  // RETRIEVE CURRENT CART MAP
  const cartMap: Map<string, any> = yield select((state) => state.cart.cartMap);

  const productCartKey = data.productCartKey;
  const quantity = data.quantity;

  cartMap.get(productCartKey).quantity = quantity;

  const updatedProduct = cartMap.get(productCartKey);

  yield put(
    logAnalyticsEventAction({
      key: "add_to_cart",
      value: JSON.parse(JSON.stringify(updatedProduct)),
    })
  );

  // FINAL OPERATIONS
  yield finalCartOperations(cartMap);

  // COMPLETED
  yield put(changeUpdateCartStatus(requestStatus.COMPLETED));
}

export function* deleteItemOnCart({
  payload: productCartKey,
}: ActionWithPayload) {
  // STARTED
  yield put(changeUpdateCartStatus(requestStatus.STARTED));

  // RETRIEVE CURRENT CART MAP
  const cartMap: Map<string, any> = yield select((state) => state.cart.cartMap);

  const deletedProduct = cartMap.get(productCartKey);

  yield put(
    logAnalyticsEventAction({
      key: "remove_from_cart",
      value: JSON.parse(JSON.stringify(deletedProduct)),
    })
  );

  cartMap.delete(productCartKey);

  // FINAL OPERATIONS
  yield finalCartOperations(cartMap);

  // COMPLETED
  yield put(changeUpdateCartStatus(requestStatus.COMPLETED));
}

export function* handleRetrievedCartArray({
  payload: cartArray,
}: {
  type: string;
  payload: CartProduct[];
}) {
  const cartMap: Map<string | undefined, CartProduct> = getCartArrayToMap(
    cartArray
  );

  // SAVE CART MAP
  yield put(updateCartMap(cartMap));

  // FINAL CART OPERATIONS
  yield finalCartOperations(cartMap);
}

export function* deleteCurrentCartSaga() {
  yield put(deleteCurrentCartAction());
  yield localStorage.removeItem(localStorageKeys.CART_ARRAY);
  return;
}

/**
 * Export sagas
 */
export default function* cartSagas() {
  yield all([
    takeEvery(cartTypes.UPDATE_CART, updateCart),
    takeEvery(cartTypes.UPDATE_ITEM_ON_CART, updateItemOnCart),
    takeLatest(cartTypes.DELETE_ITEM_ON_CART, deleteItemOnCart),
    takeLatest(cartTypes.SET_RETRIEVED_CART_ARRAY, handleRetrievedCartArray),
    takeLatest(cartTypes.DELETE_CURRENT_CART, deleteCurrentCartSaga),
  ]);
}
