import React from "react";
import ReactPlayer from "react-player/lazy";

interface Props {
  url?: string;
}

const CarouselVideo = ({ url }: Props) => {
  return (
    <>
      <div className="carousel-video">
        <ReactPlayer
          url={url}
          playing
          loop
          muted
          width="100%"
          height="100%"
          stopOnUnmount
        />
      </div>
      <div className="carousel-video-overlay" />
    </>
  );
};

export default CarouselVideo;
