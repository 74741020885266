export const shopTypes = {
  TOGGLE_FILTER_DRAWER: "TOGGLE_FILTER_DRAWER",
  CHANGE_FILTERS: "CHANGE_FILTERS",
  GET_SHOP_PRODUCTS: "GET_SHOP_PRODUCTS",
  SET_SHOP_PRODUCTS: "SET_SHOP_PRODUCTS",
  CHANGE_GET_SHOP_PRODUCTS_STATUS: "CHANGE_GET_SHOP_PRODUCTS_STATUS",
  REMOVE_ALL_SHOP_FILTERS_PRODUCTS: "REMOVE_ALL_SHOP_FILTERS_PRODUCTS",
  GET_SHOP_PRODUCT_DETAILS: "GET_SHOP_PRODUCT_DETAILS",
  SET_SHOP_PRODUCT_DETAILS: "SET_SHOP_PRODUCT_DETAILS",
};
