import { Col, Popover, Radio, Row } from "antd";
import React from "react";
import { BsArrowUpDown } from "react-icons/bs";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setShopProducts } from "../../../redux/Shop/shop.actions";

interface Props {}

const ShopSorter = (props: Props) => {
  const dispatch = useDispatch();
  const { queriedProducts } = useSelector(
    (state: RootStateOrAny) => state.shop
  );
  const onSortingChange = (e) => {
    e.preventDefault();
    let sortedProducts;
    switch (e?.target?.value) {
      case "asc_price":
        sortedProducts = queriedProducts.sort(
          (a, b) => a?.price - b?.price
        );
        dispatch(setShopProducts(sortedProducts));
        break;
      case "desc_price":
        sortedProducts = queriedProducts.sort(
          (a, b) => b?.price - a?.price
        );
        dispatch(setShopProducts(sortedProducts));
        break;
      case "name":
        sortedProducts = queriedProducts.sort((a, b) =>
          a?.name?.localeCompare(b?.name)
        );
        dispatch(setShopProducts(sortedProducts));
        break;
      case "popularity":
        sortedProducts = queriedProducts.sort(
          (a, b) => a?.popularity - b?.popularity
        );
        dispatch(setShopProducts(sortedProducts));
        break;
    }
  };

  return (
    <Popover
      placement="bottomRight"
      title={"Ordina per"}
      content={
        <Radio.Group
          style={{
            maxWidth: "min-content",
            minWidth: "min-content",
          }}
          defaultValue={"asc_price"}
          onChange={onSortingChange}
        >
          <Row>
            {/* <Col span={24} style={{ display: "flex" }}>
              <Radio
                value={`popularity`}
                style={{ display: "block", marginLeft: 0 }}
              >
                {"popolarità".toLocaleUpperCase()}
              </Radio>
            </Col> */}
            <Col span={24} style={{ display: "flex" }}>
              <Radio
                value={`asc_price`}
                style={{ display: "block", marginLeft: 0 }}
              >
                {"prezzo 🔼".toLocaleUpperCase()}
              </Radio>
            </Col>
            <Col span={24} style={{ display: "flex" }}>
              <Radio
                value={`desc_price`}
                style={{ display: "block", marginLeft: 0 }}
              >
                {"prezzo 🔽".toLocaleUpperCase()}
              </Radio>
            </Col>
            <Col span={24} style={{ display: "flex" }}>
              <Radio value={`name`} style={{ display: "block", marginLeft: 0 }}>
                {"nome".toLocaleUpperCase()}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      }
      trigger="click"
    >
      <BsArrowUpDown
        style={{ cursor: "pointer", marginRight: "18px" }}
        size={22}
      />
    </Popover>
  );
};

export default ShopSorter;
