import { Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
import { RiDirectionLine, RiPhoneLine } from "react-icons/ri";
import { isAPhone } from "../../../utils";
interface Props {
  img: {
    src: string;
    optionalStyle?: object;
  };
  hours: any[];
}

const InfoCard = ({ img, hours }: Props) => {
  const spanCol = isAPhone() ? 24 : 11;
  const textColor = "#f5f5f5";
  return (
    <div>
      <img
        style={{
          ...img.optionalStyle,
        }}
        src={img.src}
        className="img-fluid card-image"
        alt=""
      />
      <Card
        style={{
          width: "92%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          msTransform: "translate(-50%, -50%)",
          fontSize: "1em",
          position: "absolute",
          color: textColor,
          backgroundColor: "rgba(0,0,0,0.4)",
          borderColor: "transparent",
        }}
        className="home-card"
        bodyStyle={{ padding: "8px" }}
      >
        <Row style={{ display: "flex" }}>
          <Col span={spanCol}>
            {hours.map((item, index) => (
              <Row
                key={index}
                style={{ justifyContent: "space-between", flexWrap: "nowrap" }}
              >
                <Col style={{ marginRight: "8px" }}>
                  {item.day.toLocaleUpperCase()}
                </Col>
                <Col>{item.hours}</Col>
              </Row>
            ))}
          </Col>
          {isAPhone() ? (
            <Divider style={{ borderColor: textColor }} />
          ) : (
            <Col span={1}>
              <Divider
                type="vertical"
                style={{ height: "100%", borderColor: textColor }}
              />
            </Col>
          )}
          <Col span={spanCol}>
            <Row
              style={{
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                rel="noopener noreferrer"
                href="https://goo.gl/maps/jy7uj5u62NitbSoN9"
                target="_blank"
              >
                <RiDirectionLine style={{ fontSize: "3em", margin: "8px" }} />
              </a>
              <Typography.Paragraph
                style={{
                  color: textColor,
                  wordWrap: "break-word",
                  margin: "0px",
                  fontSize: "1.2em",
                }}
              >
                Via Porto 3, 35030 Galzignano Terme PD
              </Typography.Paragraph>
            </Row>
            <Divider style={{ borderColor: textColor }} />
            <Row
              style={{
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                rel="noopener noreferrer"
                href="tel:+393468726904"
                target="_blank"
              >
                <RiPhoneLine style={{ fontSize: "3em", margin: "8px" }} />
              </a>
              <Typography.Paragraph
                style={{
                  color: textColor,
                  wordWrap: "break-word",
                  margin: "0px",
                  fontSize: "1.2em",
                  textAlign: "center",
                }}
              >
                346 872 6904
              </Typography.Paragraph>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default InfoCard;
