const userTypes = {
  EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  SIGN_OUT_USER_START: "SIGN_OUT_USER_START",
  SIGN_OUT_USER_SUCCESS: "SIGN_OUT_USER_SUCCESS",
  SIGN_UP_USER_START: "SIGN_UP_USER_START",
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  GOOGLE_SIGN_IN_START: "GOOGLE_SIGN_IN_START",
  USER_ERROR: "USER_ERROR",
  RESET_USER_STATE: "RESET_USER_STATE",
  SIGN_IN_PL_USER_START: "SIGN_IN_PL_USER_START",
  SIGN_IN_PL_EMAIL_SENT: "SIGN_IN_PL_EMAIL_SENT",
  SIGN_IN_PL_FINISH_LOGIN: "SIGN_IN_PL_FINISH_LOGIN",
  EMAIL_SIGN_IN_ERROR: "EMAIL_SIGN_IN_ERROR",
  EMAIL_SIGN_IN_REMOVE_ERROR: "EMAIL_SIGN_IN_REMOVE_ERROR",
  CHANGE_LOGIN_STATUS: "CHANGE_LOGIN_STATUS",
  SIGN_UP_PL_FINISH_LOGIN: "SIGN_UP_PL_FINISH_LOGIN",
  CHANGE_PL_SIGN_IN_STATUS: "CHANGE_PL_SIGN_IN_STATUS",
  RESET_PL_SIGN_IN_STATUS: "RESET_PL_SIGN_IN_STATUS",
  GET_USER_INFO: "GET_USER_INFO",
  SET_GET_USER_INFO_STATUS: "SET_GET_USER_INFO_STATUS",
  SET_GET_USER_INFO: "SET_GET_USER_INFO",
  SET_GET_USER_INFO_ERROR: "SET_GET_USER_INFO_ERROR",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  UPDATE_USER_INFO_CHANGE_STATUS: "UPDATE_USER_INFO_CHANGE_STATUS",
  UPDATE_USER_INFO_SET_ERROR: "UPDATE_USER_INFO_SET_ERROR",
  SIGN_OUT: "SIGN_OUT",
  ERASE_USER_DATA: "ERASE_USER_DATA",
  GET_USER_ORDER_ACTION: "GET_USER_ORDER_ACTION",
  SET_USER_ORDER_ACTION: "SET_USER_ORDER_ACTION",
  SET_USER_ORDER_FETCHING_ACTION: "SET_USER_ORDER_FETCHING_ACTION",
  SET_USER_ORDER_ERROR_ACTION: "SET_USER_ORDER_ERROR_ACTION",
  SET_USER_LAST_SNAPSHOT_ACTION: "SET_USER_LAST_SNAPSHOT_ACTION",
};

export default userTypes;
