import { takeLatest, all, put, select } from "redux-saga/effects";
import { analytics, firebaseStorage, firestore } from "./../../firebase/utils";
import websiteTypes from "./website.types";
import axios from "axios";
import config from "../../config";
import { capitalize, requestStatus } from "../../utils";
import {
  setAdminOrders,
  setAvailableCities,
  setNextAvailableMoment,
  setOpeningInfo,
  setPages,
  setScheduledOccasions,
  setSearchCities,
  setUploadImages,
  updateSetAvailableCitiesStatus,
} from "./website.actions";
import { collection } from "../../utils/constants";
import moment from "antd/node_modules/moment";
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";
import { FirestoreCompleteOrder } from "../../types/firestore/completeOrder.firestore.type";
import { FirestorePages } from "../../types/firestore/pages.firestore.type";
import firebase from "firebase";
import { FirestoreScheduledOccasions } from "../../types/firestore/scheduledOccasions.firestore.type";

interface ISignInPL {
  type: string;
  payload?: any;
}

/**
 * This function is used to retrieve cities infos from external api
 * @param searchParam Search parameter
 */
export function* getCitiesFromApi({ payload: searchParam }: ISignInPL) {
  try {
    const axiosConfig = {
      headers: {
        "X-Parse-Application-Id": config.zipcodes.appKey,
        "X-Parse-REST-API-Key": config.zipcodes.apiKey,
      },
    };
    const whereFilter = {
      Place_Name: {
        $regex: capitalize(searchParam),
      },
    };
    const whereFilterString = JSON.stringify(whereFilter);
    const citiesArray = yield axios
      .get(
        `https://parseapi.back4app.com/classes/Italyzipcode_Italy_Postal_Code?limit=50&where=${whereFilterString}`,
        axiosConfig
      )
      .then((res) => res.data?.results)
      .catch((error) => {
        console.error(error);
        return [];
      });
    console.debug(citiesArray);
    yield put(setSearchCities(citiesArray));
  } catch (error) {
    console.debug(error);
  }
}

/**
 * This function is used to get all available cities to ship to
 */
export function* getFirestoreAvailableCities() {
  try {
    const snapshot = yield firestore
      .collection("generic_infos")
      .doc("available_cities")
      .get();

    const { available_cities_list } = snapshot.data();
    yield put(setAvailableCities(available_cities_list));
  } catch (error) {
    console.error(error);
  }
}

/**
 * This function update available cities on firestore database
 * @param availableCities new Available cities
 */
export function* updateFirestoreAvailableCities({
  payload: availableCities,
}: ISignInPL) {
  yield put(updateSetAvailableCitiesStatus(requestStatus.STARTED));
  try {
    yield firestore
      .collection("generic_infos")
      .doc("available_cities")
      .set({ available_cities_list: availableCities });
    yield put(updateSetAvailableCitiesStatus(requestStatus.COMPLETED));
    yield put(setAvailableCities(availableCities));
  } catch (error) {
    console.error(error);
    yield put(updateSetAvailableCitiesStatus(requestStatus.ERROR));
  }
}

/**
 * This functions retrieve pages information
 */
export function* getFirestorePages() {
  yield put(setPages({ fetching: true }));
  try {
    const snapshot = yield firestore
      .collection(collection.generic_infos)
      .doc(collection.pages)
      .get();

    let pages: FirestorePages = snapshot.data();

    pages.home_page.carousel = pages.home_page.carousel.sort(
      (a, b) => a.order - b.order
    );

    yield put(
      setPages({
        fetching: false,
        pages: pages,
        status: requestStatus.COMPLETED,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      setPages({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

/**
 * This function update firestore pages
 * @param newPages new pages
 */
export function* updateFirestorePages({ payload: newPages }: ISignInPL) {
  yield put(setPages({ fetching: true }));
  try {
    const new_pages = JSON.parse(JSON.stringify(newPages));
    yield firestore
      .collection(collection.generic_infos)
      .doc(collection.pages)
      .update({
        ...new_pages,
        updated_at: firebaseStorage.firestore.FieldValue.serverTimestamp(),
      });
    yield put(
      setPages({
        fetching: false,
        pages: new_pages,
        status: requestStatus.COMPLETED,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      setPages({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

/**
 * This function retrieve firestore opening info
 */
export function* getFirestoreOpeningInfo() {
  yield put(setOpeningInfo({ fetching: true }));
  try {
    const snapshot = yield firestore
      .collection(collection.generic_infos)
      .doc(collection.opening_info)
      .get();

    const opening_info = snapshot.data();
    yield put(setOpeningInfo(opening_info));
    yield put(
      setOpeningInfo({ fetching: false, status: requestStatus.COMPLETED })
    );
  } catch (error) {
    console.error(error);
    yield put(setOpeningInfo({ error: error, status: requestStatus.ERROR }));
  }
}

/**
 * This function update firestore opening info
 * @param data opening info
 */
export function* updateFirestoreOpeningInfo({ payload: data }: ISignInPL) {
  yield put(setOpeningInfo({ fetching: true }));
  try {
    let { openingInfo } = yield select((state) => state.website);
    const newOpeningInfo = { ...openingInfo, ...data };
    console.debug(newOpeningInfo);
    const new_opening_info = JSON.parse(JSON.stringify(newOpeningInfo));
    yield firestore
      .collection(collection.generic_infos)
      .doc(collection.opening_info)
      .update({
        ...new_opening_info,
        updated_at: firebaseStorage.firestore.FieldValue.serverTimestamp(),
      });
    yield put(setOpeningInfo(new_opening_info));
    yield put(
      setOpeningInfo({ fetching: false, status: requestStatus.COMPLETED })
    );
  } catch (error) {
    console.error(error);
    yield put(
      setOpeningInfo({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

/**
 * This function retrieve nextAvailableMoment
 */
export function* getFirestoreNextAvailableMoment() {
  yield put(setNextAvailableMoment({ fetching: true }));
  try {
    const nextAvailableMomentMillis = yield axios
      .get(`${config.firebaseEndpoint}/v1/opening-info/next-available-moment`)
      .then((res) => res.data?.nextAvailableMoment)
      .catch((error) => {
        console.error(error);
        return [];
      });
    const nextAvailableMoment = moment(nextAvailableMomentMillis);
    yield put(
      setNextAvailableMoment({
        fetching: false,
        status: requestStatus.COMPLETED,
        nextAvailableMoment: nextAvailableMoment,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      setNextAvailableMoment({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

const compressorOptions = {
  maxSizeMB: 0.1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

function* uploadSingleImage(image: File) {
  const uuid = uuidv4();
  const compressedImage: File = yield imageCompression(
    image,
    compressorOptions
  );

  const storageRef: firebase.storage.Reference = yield firebaseStorage
    .storage()
    .ref();

  const imageRef: firebase.storage.Reference = yield storageRef.child(
    `images/${uuid}.jpg`
  );

  const imageURL = yield imageRef
    .put(compressedImage)
    .then((snapshot) => snapshot.ref.getDownloadURL());

  return imageURL;
}

export function* uploadImagesSaga({ payload: fileList }: ISignInPL) {
  yield put(setUploadImages({ fetching: true }));
  try {
    console.debug("START UPLOAD");
    const imageURLs: string[] = [];
    for (let index = 0; index < fileList.length; index++) {
      const imageURL = yield uploadSingleImage(fileList[index]);
      imageURLs.push(imageURL);
    }

    console.debug("FINISH UPLOAD");
    console.debug("START SAVING");

    const docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = yield firestore
      .collection(collection.generic_infos)
      .doc(collection.images);

    const snapshot = yield docRef.get();

    const { images } = yield snapshot.data();

    images.push(...imageURLs);

    yield docRef.update({ images: images });
    console.debug("FINISH SAVING");

    yield put(
      setUploadImages({
        images: images,
        fetching: false,
        status: requestStatus.COMPLETED,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      setUploadImages({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

export function* getUploadImagesSaga() {
  yield put(setUploadImages({ fetching: true }));
  try {
    const snapshot = yield firestore
      .collection(collection.generic_infos)
      .doc(collection.images)
      .get();

    const { images } = yield snapshot.data();

    yield put(
      setUploadImages({
        images: images,
        fetching: false,
        //status: requestStatus.COMPLETED,
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      setUploadImages({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

function* saveAdminOrders(results: FirestoreCompleteOrder[]) {
  const today = new Date();

  const inStoreOrders = yield results.filter(
    (order: FirestoreCompleteOrder) => order.sm_in_store_collection === true
  );

  const deliveringOrders = yield results.filter(
    (order: FirestoreCompleteOrder) => order.sm_delivered === true
  );

  const todayOrders = yield results.filter((order: FirestoreCompleteOrder) => {
    const deliveringDate = new firebase.firestore.Timestamp(
      order.delivering_date?.seconds,
      order.delivering_date?.nanoseconds
    ).toDate();
    return (
      deliveringDate.getDate() === today.getDate() &&
      deliveringDate.getMonth() === today.getMonth() &&
      deliveringDate.getFullYear() === today.getFullYear()
    );
  });

  yield put(
    setAdminOrders({
      adminOrders: results,
      inStoreOrders: inStoreOrders,
      deliveringOrders: deliveringOrders,
      todayOrders: todayOrders,
      fetching: false,
      status: requestStatus.COMPLETED,
    })
  );
}

export function* getAdminOrdersSaga() {
  yield put(setAdminOrders({ fetching: true }));
  try {
    const last_snapshot = yield select(
      (state) => state.website.adminOrders.last_snapshot
    );
    const orders = yield select(
      (state) => state.website.adminOrders.adminOrders
    );

    let snapshots: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = yield firestore.collectionGroup(
      collection.orders
    );

    switch (config.env) {
      case "LOCAL":
        query = query.where("is_a_local_test", "==", true);
        break;
      case "TEST":
        query = query.where("is_a_test", "==", true);
        break;
      default:
        query = query.where("is_live", "==", true);
        break;
    }

    let today = new Date();
    today.setDate(today.getDate() - 1);

    query = query
      .where("delivering_date", ">=", today)
      .orderBy("delivering_date", "asc");

    if (last_snapshot) {
      query = yield query.startAfter(last_snapshot.data().delivering_date);
    }

    snapshots = yield query.get();
    let results: any[] = [];

    const orderIDs = orders.map((order) => order.orderID);

    results = [...orders];

    snapshots.forEach((snap) => {
      if (!orderIDs.includes(snap.data().orderID)) {
        results.push(snap.data());
      }
    });

    yield saveAdminOrders(results);
  } catch (error) {
    console.error(error);
    yield put(
      setAdminOrders({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

export function* updateOrderStatusSaga({ payload }: ISignInPL) {
  yield put(setAdminOrders({ fetching: true }));
  try {
    const order: FirestoreCompleteOrder = payload;

    const docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = yield firestore
      .collection(collection.users)
      .doc(order.userID)
      .collection(collection.orders)
      .doc(order.orderID);

    const snapshot = yield docRef.get();

    let oldOrder: FirestoreCompleteOrder = yield snapshot.data();

    oldOrder.status = order.status;

    yield docRef.update(oldOrder);

    let oldOrders = yield select(
      (state) => state.website.adminOrders.adminOrders
    );

    oldOrders = oldOrders.map((old: FirestoreCompleteOrder) => {
      if (old.orderID === order.orderID) {
        return order;
      } else {
        return old;
      }
    });

    yield saveAdminOrders(oldOrders);
  } catch (error) {
    console.error(error);
    yield put(
      setUploadImages({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

export function* finishUpdateScheduledOccasionsSaga(
  scheduled_occasions: FirestoreScheduledOccasions
) {
  scheduled_occasions.scheduled_occasions = scheduled_occasions.scheduled_occasions.map(
    (occasion) => {
      const newDates: moment.Moment[] = [];
      occasion.range_date.forEach((date: any) => {
        newDates.push(moment(date.toDate()));
      });
      occasion.range_date = newDates;
      return occasion;
    }
  );

  const todayMoment = moment();

  const active_occasions = scheduled_occasions.scheduled_occasions.filter(
    (occasion) =>
      moment(todayMoment).isBetween(
        occasion.range_date[0],
        occasion.range_date[1]
      )
  );

  yield put(
    setScheduledOccasions({
      fetching: false,
      scheduled_occasions: scheduled_occasions,
      active_occasions: active_occasions,
      status: requestStatus.COMPLETED,
    })
  );
}

export function* getScheduledOccasionsSaga() {
  yield put(setScheduledOccasions({ fetching: true }));
  try {
    const snapshot = yield firestore
      .collection(collection.generic_infos)
      .doc(collection.scheduled_occasions)
      .get();

    let scheduled_occasions: FirestoreScheduledOccasions = snapshot.data();

    yield finishUpdateScheduledOccasionsSaga(scheduled_occasions);
  } catch (error) {
    console.error(error);
    yield put(
      setScheduledOccasions({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

/**
 * This function updates scheduled_occasions
 * @param scheduled_occasions
 */
export function* updateScheduledOccasionsSaga({
  payload: scheduled_occasions,
}: ISignInPL) {
  yield put(setScheduledOccasions({ fetching: true }));
  try {
    scheduled_occasions.scheduled_occasions = scheduled_occasions.scheduled_occasions.map(
      (occasion) => {
        const newDates: Date[] = [];
        occasion.range_date.forEach((date: moment.Moment) => {
          newDates.push(date.toDate());
        });
        occasion.range_date = newDates;
        return occasion;
      }
    );

    yield firestore
      .collection(collection.generic_infos)
      .doc(collection.scheduled_occasions)
      .update({
        ...scheduled_occasions,
        updated_at: firebaseStorage.firestore.FieldValue.serverTimestamp(),
      });

    yield finishUpdateScheduledOccasionsSaga(scheduled_occasions);
  } catch (error) {
    console.error(error);
    yield put(
      setScheduledOccasions({
        fetching: false,
        error: error,
        status: requestStatus.ERROR,
      })
    );
  }
}

/**
 * This function updates scheduled_occasions
 * @param scheduled_occasions
 */
export function* logAnalyticsEventSaga({ payload: event }: ISignInPL) {
  const { key, value } = event;
  yield analytics.logEvent(key, value);
}

// EXPORT
export default function* userSagas() {
  yield all([
    takeLatest(websiteTypes.SEARCH_CITIES, getCitiesFromApi),
    takeLatest(
      websiteTypes.UPDATE_AVAILABLE_CITIES,
      updateFirestoreAvailableCities
    ),
    takeLatest(websiteTypes.GET_AVAILABLE_CITIES, getFirestoreAvailableCities),
    takeLatest(websiteTypes.GET_PAGES, getFirestorePages),
    takeLatest(websiteTypes.UPDATE_PAGES, updateFirestorePages),
    takeLatest(websiteTypes.GET_OPENING_INFO, getFirestoreOpeningInfo),
    takeLatest(websiteTypes.UPDATE_OPENING_INFO, updateFirestoreOpeningInfo),
    takeLatest(
      websiteTypes.GET_NEXT_AVAILABLE_MOMENT,
      getFirestoreNextAvailableMoment
    ),
    takeLatest(websiteTypes.UPLOAD_IMAGES, uploadImagesSaga),
    takeLatest(websiteTypes.GET_UPLOAD_IMAGES, getUploadImagesSaga),
    takeLatest(websiteTypes.GET_ADMIN_ORDERS, getAdminOrdersSaga),
    takeLatest(websiteTypes.UPDATE_ORDER_STATUS, updateOrderStatusSaga),
    takeLatest(websiteTypes.GET_SCHEDULED_OCCASIONS, getScheduledOccasionsSaga),
    takeLatest(
      websiteTypes.UPDATE_SCHEDULED_OCCASIONS,
      updateScheduledOccasionsSaga
    ),
    takeLatest(websiteTypes.LOG_ANALYTICS_EVENT, logAnalyticsEventSaga),
  ]);
}
