import React from "react";
import { NavLink } from "react-router-dom";
import { Card, Typography } from "antd";

const { Paragraph, Title } = Typography;

interface Props {
  title?: string;
  description?: string;
  buttonTitle?: string;
  navigateTo?: string;
  isButtonVisible?: boolean;
}

const CarouselCard = ({
  title,
  description,
  buttonTitle,
  navigateTo,
  isButtonVisible,
}: Props) => {
  const textColor = "#f5f5f5";
  return (
    <Card
      bordered={false}
      style={{
        backgroundColor: "rgba(0,0,0,0.4)",
        //backgroundColor: "transparent",
        borderRadius: "0px",
        textAlign: "center",
      }}
    >
      <div style={{ marginRight: "0.1em" }}>
        <Title
          level={2}
          style={{
            fontWeight: 600,
            color: textColor,
            letterSpacing: "0.1em",
          }}
        >
          {title?.toUpperCase()}
        </Title>
      </div>
      <Paragraph
        style={{
          color: textColor,
          fontWeight: 500,
          textAlign: "center",
          textJustify: "inter-word",
        }}
      >
        {description}
      </Paragraph>
      {navigateTo && isButtonVisible && (
        <NavLink
          to={navigateTo}
          className="button-light"
          style={{ alignSelf: "end" }}
        >
          {buttonTitle === undefined
            ? `MAGGIORI INFORMAZIONI`
            : buttonTitle.toUpperCase()}
        </NavLink>
      )}
    </Card>
  );
};

export default CarouselCard;
