import React from "react";

interface Props {
  quantity: number;
  clickHandler: (number) => void;
  step?: number;
}

const QuantityButton = ({ quantity, clickHandler, step = 1 }: Props) => {
  return (
    <div className="quantity-input">
      <button
        className="quantity-input__modifier quantity-input__modifier--left"
        onClick={() => clickHandler(-step)}
      >
        -
      </button>
      <input
        className="quantity-input__screen"
        type="text"
        value={quantity}
        readOnly
        onChange={() => {
          return null;
        }}
      />
      <button
        className="quantity-input__modifier quantity-input__modifier--right"
        onClick={() => clickHandler(step)}
      >
        +
      </button>
    </div>
  );
};

export default QuantityButton;
