import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, List } from "antd";
import React from "react";
import {
  FirestoreAddress,
  FirestoreUser,
} from "../../../types/firestore/user.firestore.type";

interface Props {
  userInfo: FirestoreUser | undefined;
  handleAddFavouriteAddressButton: () => void;
  removeFavoriteAddressByUid: (uid: string | undefined) => void;
}

const MyInfoFavouriteAddresses = ({
  userInfo,
  handleAddFavouriteAddressButton,
  removeFavoriteAddressByUid,
}: Props) => {
  return (
    <Card
      headStyle={{ fontWeight: "bold", padding: 0 }}
      bordered={false}
      className="main-content-card"
      title="Indirizzi preferiti"
      extra={
        <Button type="primary" onClick={handleAddFavouriteAddressButton}>
          + Aggiungi
        </Button>
      }
      bodyStyle={{ display: "flex" }}
    >
      <List
        style={{ width: "100%" }}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
          xxl: 2,
        }}
        dataSource={userInfo?.shipping_addresses}
        renderItem={(address: FirestoreAddress, index: number) => (
          <List.Item>
            <Card
              style={{
                width: "100%",
                textAlign: "left",
                boxShadow:
                  "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
              }}
              bordered={false}
              title={`${address?.first_name} ${address?.last_name}`}
              extra={
                <Button
                  onClick={() => removeFavoriteAddressByUid(address?.uid)}
                >
                  <CloseOutlined />
                </Button>
              }
            >
              <>
                {address?.street_name}, {address?.street_number}
                <br />
                {address?.city}, {address?.zipcode}
                <br />
                {address?.province} {address?.state}
              </>
            </Card>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default MyInfoFavouriteAddresses;
