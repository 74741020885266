import websiteTypes from "./website.types";

const INITIAL_STATE = {
  isDrawerOpen: false,
  isCartDrawerOpen: false,
  adminConsole: {
    selectedMenu: 1,
  },
  products: {
    types: [
      { label: "BOUQUET", value: "bouquet" },
      { label: "PIANTA", value: "plant" },
      { label: "FIORI SINGOLI", value: "single_flower" },
      { label: "ALTRO", value: "other" },
    ],
    occasions: [
      "san valentino",
      "natale",
      "amore",
      "festa della mamma",
      "anniversario",
      "nascita",
      "festa della donna",
      "laurea",
      "congratulazioni",
      "ringraziamenti",
      "condoglianze",
    ],
  },
  openingInfo: {
    shopHours: [],
    vacationDays: [],
    fetching: false,
    error: null,
    status: undefined,
  },
  nextAvailableMoment: {
    nextAvailableMoment: undefined,
    fetching: false,
    error: undefined,
    status: undefined,
  },
  availableCities: {
    data: [],
    status: undefined,
  },
  searchedCities: [],
  pages: {
    pages: {
      home_page: {
        carousel: [],
      },
      is_live_under_maintenance: true,
    },
    fetching: false,
    error: undefined,
    status: undefined,
  },
  scheduled_occasions: {
    scheduled_occasions: [],
    active_occasions: [],
    fetching: false,
    error: undefined,
    status: undefined,
  },
  uploadImages: {
    images: [],
    fetching: false,
    error: undefined,
    status: undefined,
  },
  adminOrders: {
    adminOrders: [],
    inStoreOrders: [],
    deliveringOrders: [],
    fetching: false,
    error: undefined,
    status: undefined,
  },
};

const websiteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case websiteTypes.TOGGLE_DRAWER:
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen,
      };
    case websiteTypes.SET_ADMIN_CONSOLE_MENU:
      return {
        ...state,
        adminConsole: {
          ...state.adminConsole,
          selectedMenu: action.payload.toString(),
        },
      };
    case websiteTypes.SET_WEBSITE_PRODUCT_DETAILS:
      return {
        ...state,
        products: action.payload,
      };
    case websiteTypes.TOGGLE_CART_DRAWER:
      return {
        ...state,
        isCartDrawerOpen: !state.isCartDrawerOpen,
      };
    case websiteTypes.SET_AVAILABLE_CITIES:
      return {
        ...state,
        availableCities: {
          ...state.availableCities,
          data: action.payload,
        },
      };
    case websiteTypes.UPDATE_SET_AVAILABLE_CITIES_STATUS:
      return {
        ...state,
        availableCities: {
          ...state.availableCities,
          status: action.payload,
        },
      };
    case websiteTypes.SET_SEARCHED_CITIES:
      return {
        ...state,
        searchedCities: action.payload,
      };
    case websiteTypes.SET_NEXT_AVAILABLE_MOMENT:
      return {
        ...state,
        nextAvailableMoment: {
          ...state.nextAvailableMoment,
          ...action.payload,
        },
      };
    case websiteTypes.SET_PAGES:
      return {
        ...state,
        pages: {
          ...state.pages,
          ...action.payload,
        },
      };
    case websiteTypes.SET_OPENING_INFO:
      return {
        ...state,
        openingInfo: {
          ...state.openingInfo,
          ...action.payload,
        },
      };
    case websiteTypes.SET_UPLOAD_IMAGES:
      return {
        ...state,
        uploadImages: {
          ...state.uploadImages,
          ...action.payload,
        },
      };
    case websiteTypes.SET_ADMIN_ORDERS:
      return {
        ...state,
        adminOrders: {
          ...state.adminOrders,
          ...action.payload,
        },
      };
    case websiteTypes.SET_SCHEDULED_OCCASIONS:
      return {
        ...state,
        scheduled_occasions: {
          ...state.scheduled_occasions,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default websiteReducer;
