import { Card, Col, Layout, PageHeader, Row } from "antd";
import moment from "antd/node_modules/moment";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { updateCart } from "../../../redux/Cart/cart.actions";
import {
  getExistingProduct,
  resetPlainProductData,
} from "../../../redux/Products/products.actions";
import { CartProduct } from "../../../types/cartProduct.type";
import { FirestoreProduct } from "../../../types/firestore/product.firestore.type";
import { requestStatus, retrieveIdOnPage } from "../../../utils";
import ProductDetailCard from "./ProductDetailCard";
import ProductDetailCardDetails from "./ProductDetailCardDetails";
import ProductGallery from "./ProductGallery";
import { Helmet } from "react-helmet";
import {
  logAnalyticsEventAction,
  toggleCartDrawer,
} from "../../../redux/Website/website.actions";

const { Content } = Layout;

interface Props {}

const Product = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const product: FirestoreProduct = useSelector(
    (state: RootStateOrAny) => state.products.plainProductData
  );
  const { productGetStatus } = useSelector(
    (state: RootStateOrAny) => state.products
  );
  const { isInitializing: firebaseIsInitializing } = useSelector(
    (state: RootStateOrAny) => state.firebase
  );

  const [typeSelected, setTypeSelected] = useState("essential");
  const [selectedProduct, setSelectedProduct] = useState<CartProduct>({
    productID: undefined,
    configuration: undefined,
    image: undefined,
    name: undefined,
    quantity: 1,
    price: 0,
    currency: undefined,
    available: false,
  });
  const [isProductLoading, setIsProductLoading] = useState(true);

  const productID = retrieveIdOnPage(query);
  useEffect(() => {
    if (productID) {
      dispatch(getExistingProduct(productID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productID]);

  const [todayMoment, setTodayMoment] = useState<moment.Moment>();
  useEffect(() => {
    setTodayMoment(moment());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetPlainProductData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (productGetStatus) {
      case requestStatus.STARTED:
        setIsProductLoading(true);
        break;
      case requestStatus.COMPLETED:
        setSelectedProduct({
          productID: productID,
          name: product?.name,
          image: product?.images?.filter(
            (image) => image?.is_main_image === true
          )[0],
          configuration:
            product?.prices?.length === 0 ? undefined : product?.prices[0],
          quantity: 1,
          price: product?.min_price,
          currency: product?.currency,
          available: product?.available,
        });
        setTypeSelected(product?.prices[0]?.type);
        setIsProductLoading(false);
        break;
      case requestStatus.ERROR:
        setIsProductLoading(false);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGetStatus]);

  useEffect(() => {
    if (product && !firebaseIsInitializing) {
      dispatch(
        logAnalyticsEventAction({
          key: "select_item",
          value: JSON.parse(JSON.stringify(product)),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, firebaseIsInitializing]);

  const handleClickOnAddToCart = () => {
    const product = Object.assign({}, selectedProduct);
    setSelectedProduct({
      ...selectedProduct,
      quantity: 1,
    });
    if (product && !firebaseIsInitializing) {
      dispatch(
        logAnalyticsEventAction({
          key: "add_to_cart",
          value: JSON.parse(JSON.stringify(product)),
        })
      );
    }
    dispatch(updateCart(product));
    dispatch(toggleCartDrawer());
  };

  return (
    <div>
      {/* {analytics.logEvent("select_item", product)} */}
      <Helmet>
        <title>{`${product?.name} | Le Clematis Fioreria`}</title>
        <meta name="description" content={product?.details?.description} />
        <meta name="keywords" content={product?.occasions?.join(",")} />
      </Helmet>
      <Content style={{ display: "flex", justifyContent: "center" }}>
        <Card style={{ width: "992px", maxWidth: "100%", marginTop: "16px" }}>
          <PageHeader
            className="site-page-header"
            onBack={() => history.goBack()}
            title=""
            subTitle="Ritorna ai prodotti"
          />
          <Content style={{ display: "flex", justifyContent: "center" }}>
            <Row
              style={{ width: "992px", maxWidth: "100%" }}
              justify="space-between"
              gutter={[{ lg: 24, sm: 0, xs: 0 }, 0]}
            >
              <Col lg={14} sm={24} xs={24}>
                <ProductGallery />
              </Col>
              <Col lg={10} sm={24} xs={24}>
                <ProductDetailCard
                  isProductLoading={isProductLoading}
                  product={product}
                  typeSelected={typeSelected}
                  setTypeSelected={setTypeSelected}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  handleClickOnAddToCart={handleClickOnAddToCart}
                  todayMoment={todayMoment}
                />
              </Col>
            </Row>
          </Content>
          <Content
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "24px",
            }}
          >
            <Row style={{ width: "992px", maxWidth: "100%" }}>
              <Col lg={14} sm={24} xs={24}>
                <ProductDetailCardDetails
                  isProductLoading={isProductLoading}
                  product={product}
                />
              </Col>
              <Col lg={10} sm={24} xs={24}></Col>
            </Row>
          </Content>
        </Card>
      </Content>
    </div>
  );
};

export default Product;
