/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CheckCircleFilled,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import CustomInputUrl from "../../../components/CustomInputUrl";
import config from "../../../config";
import { updatePages } from "../../../redux/Website/website.actions";
import { requestStatus } from "../../../utils";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

interface Props {}

const HomePage = (props: Props) => {
  const dispatch = useDispatch();
  const [homePageCarouselForm] = useForm();

  const { pages, fetching, error, status } = useSelector(
    (state: RootStateOrAny) => state.website?.pages
  );

  //console.debug(pages);

  const { images } = useSelector(
    (state: RootStateOrAny) => state.website.uploadImages
  );

  const { home_page } = pages;

  homePageCarouselForm.setFieldsValue({ carousel: home_page.carousel });

  const onFinishHomePageCarouselForm = (values) => {
    //console.debug(values);
    pages.home_page.carousel = values.carousel;
    dispatch(updatePages(pages));
  };

  return (
    <div className="home-page-panel">
      <Row>
        <Typography.Title>Carousel cards</Typography.Title>
      </Row>
      <Form
        {...layout}
        style={{ maxWidth: "800px" }}
        name="home-page-carousel-form"
        layout="horizontal"
        form={homePageCarouselForm}
        onFinish={onFinishHomePageCarouselForm}
        size="small"
      >
        <Form.List name="carousel">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key}>
                  <Row style={{ width: "100%", textAlign: "left" }}>
                    <DeleteOutlined onClick={() => remove(field.name)} />
                  </Row>
                  <Form.Item
                    {...field}
                    name={[field.name, "image_url"]}
                    label="Immagine"
                    rules={[
                      {
                        required: true,
                        message: "Seleziona l'immagine",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Seleziona l'immagine"
                    >
                      {images.map((imageURL) => (
                        <Option key={imageURL} value={imageURL}>
                          <img
                            style={{ maxWidth: "200px" }}
                            src={imageURL}
                            alt=""
                          />
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "visible"]}
                    label="Visibile"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "video_visible"]}
                    label="Video visibile"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "video_url"]}
                    label="Video URL"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "order"]}
                    label="Ordine"
                    rules={[
                      {
                        required: true,
                        message: "Ordine della card mancante",
                        type: "number",
                        min: 0,
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Divider orientation="left">Card</Divider>
                  <Form.Item
                    {...field}
                    name={[field.name, "card", "visible"]}
                    label="Visibile"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "card", "title"]}
                    label="Titolo"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "card", "text"]}
                    label="Testo"
                  >
                    <Input.TextArea />
                  </Form.Item>
                  <Divider orientation="left">Button</Divider>
                  <Form.Item
                    {...field}
                    name={[field.name, "card", "button", "visible"]}
                    label="Visibile"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "card", "button", "title"]}
                    label="Titolo"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "card", "button", "to_path"]}
                    label="URL"
                  >
                    <CustomInputUrl />
                  </Form.Item>
                  <Divider
                    style={{
                      marginBottom: "30px",
                      borderTop: "2px solid rgb(0 0 0)",
                    }}
                  />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Aggiungi card
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Row style={{ marginTop: "12px" }} align="middle">
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={fetching}
                form="home-page-carousel-form"
              >
                Salva
              </Button>
              {status === requestStatus.COMPLETED && (
                <CheckCircleFilled style={{ color: "#07b07a" }} />
              )}
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default HomePage;
