import { Descriptions, Table, Typography } from "antd";
import React from "react";
import { FirestoreCompleteOrder } from "../../../types/firestore/completeOrder.firestore.type";
import { OrderProduct } from "../../../types/firestore/order.firestore.type";

interface Props {
  record: FirestoreCompleteOrder;
}

const OrdersExpandedRow = ({ record }: Props) => {
  const productColumns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (name, record: OrderProduct) => (
        <a href={`/product?id=${record.productID}`}>{name}</a>
      ),
    },
    {
      title: "Configurazione",
      dataIndex: ["configuration", "type"],
      key: "configuration.type",
    },
    { title: "Qta", dataIndex: "quantity", key: "quantity" },
    {
      title: "Prezzo",
      dataIndex: "price",
      key: "price",
      render: (price) => `€ ${price}`,
    },
  ];
  return (
    <div>
      <Descriptions
        style={{ marginBottom: "12px" }}
        title="Info ordine"
        bordered
      >
        <Descriptions.Item label="E' un regalo 🎁">
          {record?.is_a_gift ? "SI" : "NO"}
        </Descriptions.Item>
        <Descriptions.Item label="Dedica">
          {record?.dedication}
        </Descriptions.Item>
        <Descriptions.Item label="Note">{record?.note}</Descriptions.Item>
      </Descriptions>
      {record?.sm_delivered && !record?.shipping?.same_as_billing_address && (
        <Descriptions title="Spedizione" bordered>
          <Descriptions.Item label="Indirizzo">
            <div>
              {`${record?.shipping?.address?.first_name} ${record?.shipping?.address?.last_name}`}
              <br />
              {`${record?.shipping?.address?.street_name} ${record?.shipping?.address?.street_number}, ${record?.shipping?.address?.city} ${record?.shipping?.address?.zipcode} ${record?.shipping?.address?.province} `}
            </div>
          </Descriptions.Item>
        </Descriptions>
      )}
      {record?.sm_delivered && record?.shipping?.same_as_billing_address && (
        <Descriptions title="Spedizione" bordered>
          <Descriptions.Item label="Indirizzo">
            <div>
              {`${record?.billing?.address?.first_name} ${record?.billing?.address?.last_name}`}
              <br />
              {`${record?.billing?.address?.street_name} ${record?.billing?.address?.street_number}, ${record?.billing?.address?.city} ${record?.billing?.address?.zipcode} ${record?.billing?.address?.province} `}
            </div>
          </Descriptions.Item>
        </Descriptions>
      )}
      <Descriptions title="Fatturazione" bordered>
        <Descriptions.Item label="Nome">{`${record?.billing?.info?.first_name} ${record?.billing?.info?.last_name}`}</Descriptions.Item>
        <Descriptions.Item label="Telefono">
          {record?.billing?.info?.phone}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {record?.billing?.info?.email}
        </Descriptions.Item>
        <Descriptions.Item label="Indirizzo">
          <div>
            {`${record?.billing?.address?.first_name} ${record?.billing?.address?.last_name}`}
            <br />
            {`${record?.billing?.address?.street_name} ${record?.billing?.address?.street_number}, ${record?.billing?.address?.city} ${record?.billing?.address?.zipcode} ${record?.billing?.address?.province} `}
          </div>
        </Descriptions.Item>
      </Descriptions>
      <Table
        title={() => <Typography.Title level={5}>Prodotti</Typography.Title>}
        rowKey="productConcatenatedID"
        columns={productColumns}
        dataSource={record?.products}
        pagination={false}
      />
    </div>
  );
};

export default OrdersExpandedRow;
