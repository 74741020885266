/* eslint-disable jsx-a11y/accessible-emoji */
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Result,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FaRegCreditCard } from "react-icons/fa";
import { FirestoreOrder } from "../../../types/firestore/order.firestore.type";
import CheckoutPriceComponent from "./CheckoutPriceComponent";
import { checkoutConstant } from "../../../utils/checkoutUtils";
import { GiTakeMyMoney } from "react-icons/gi";
import SelectableCard from "../../../components/SelectableCard";
import TextArea from "antd/lib/input/TextArea";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { getStripeKey } from "../../../utils";
import axios, { AxiosResponse } from "axios";
import Path from "../../../utils/pathConstants";
import { FirestoreUser } from "../../../types/firestore/user.firestore.type";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { logAnalyticsEventAction } from "../../../redux/Website/website.actions";

const confirmCheckoutFormName = "confirmCheckoutForm";
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const handleCompleteCheckoutClick = async (
  order: FirestoreOrder,
  history,
  dispatchAnalyticsEvent: (key, value) => void
) => {
  await axios
    .post(
      "https://us-central1-fioreria-le-clematis.cloudfunctions.net/api/v1/order/create-order",
      order
    )
    .then(async (response: AxiosResponse<any>) => {
      if (order.pm_in_store_payment) {
        const orderID = await response.data.orderID;
        dispatchAnalyticsEvent("purchase", JSON.parse(JSON.stringify(order)));
        history.push(
          `${Path.CHECKOUT}/one-page-result?success=true&orderID=${orderID}`
        );
      } else if (order.pm_is_stripe_payment) {
        dispatchAnalyticsEvent("purchase", JSON.parse(JSON.stringify(order)));
        const stripePromise = await loadStripe(getStripeKey());
        const stripe = await stripePromise;
        const sessionId = await response.data.stripe_checkout_session.id;
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe?.redirectToCheckout({
          sessionId: sessionId,
        });
        if (result?.error) {
          history.push(`${Path.CHECKOUT}/one-page-result?canceled=true`);
        }
      } else {
        history.push(`${Path.CHECKOUT}/one-page-result?canceled=true`);
      }
    })
    .catch((error) => {
      console.error(error);
      history.push(`${Path.CHECKOUT}/one-page-result?canceled=true`);
    });
  return;
};

const checkConfirmStep = (orderState: FirestoreOrder): boolean => {
  let response = false;
  if (
    orderState.pm_in_store_payment !== undefined &&
    orderState.pm_in_store_payment
  ) {
    response = true;
  } else if (
    orderState.pm_is_online_payment !== undefined &&
    orderState.pm_is_online_payment
  ) {
    response = true;
  }
  return response;
};

interface Props {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  orderState: FirestoreOrder;
  setOrderState: Dispatch<SetStateAction<FirestoreOrder>>;
  userInfo: FirestoreUser;
  firebaseIsInitializing?: boolean;
}

const ConfirmStep = ({
  currentStep,
  setCurrentStep,
  orderState,
  setOrderState,
  userInfo,
  firebaseIsInitializing,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isNextStepButtonDisable, setIsNextStepButtonDisable] = useState(true);
  const [isNextButtonLoaded, setIsNextButtonLoaded] = useState(false);

  const dispatchAnalyticsEvent = (key, value) => {
    dispatch(
      logAnalyticsEventAction({
        key,
        value,
      })
    );
  };

  useEffect(() => {
    setOrderState({
      ...orderState,
      stripe_customer_id: userInfo?.stripe_customer_id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickNextStep = () => {
    setIsNextButtonLoaded(true);
    handleCompleteCheckoutClick(orderState, history, dispatchAnalyticsEvent);
  };

  const [confirmForm] = Form.useForm();

  const onClickPaymentMethodCard = (method) => {
    switch (method) {
      case checkoutConstant.PM_IN_STORE_PAYMENT:
        setOrderState({
          ...orderState,
          pm_in_store_payment: true,
          pm_is_online_payment: false,
          pm_is_stripe_payment: false,
          pm_is_paypal_payment: false,
        });
        break;
      case checkoutConstant.PM_IS_STRIPE_PAYMENT:
        setOrderState({
          ...orderState,
          pm_in_store_payment: false,
          pm_is_online_payment: true,
          pm_is_stripe_payment: true,
          pm_is_paypal_payment: false,
        });
        break;
      default:
        break;
    }
  };

  const onChangeGiftCheckbox = (e: CheckboxChangeEvent) => {
    setOrderState({ ...orderState, is_a_gift: e.target.checked });
  };

  const onChangeDedicationTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOrderState({
      ...orderState,
      dedication: e.currentTarget.value.toString(),
    });
  };

  useEffect(() => {
    setIsNextStepButtonDisable(!checkConfirmStep(orderState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState]);

  useEffect(() => {
    if (!orderState.sm_in_store_collection) {
      setOrderState({
        ...orderState,
        pm_in_store_payment: false,
        pm_is_online_payment: true,
        pm_is_stripe_payment: true,
        pm_is_paypal_payment: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState.sm_in_store_collection]);

  useEffect(() => {
    if (orderState.is_a_gift !== undefined) {
      confirmForm.setFieldsValue({
        ...orderState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState.is_a_gift, orderState.dedication]);

  useEffect(() => {
    if (
      !firebaseIsInitializing &&
      orderState &&
      (orderState?.pm_in_store_payment || orderState?.pm_is_online_payment)
    ) {
      dispatch(
        logAnalyticsEventAction({
          key: "add_payment_info",
          value: JSON.parse(JSON.stringify(orderState)),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderState, firebaseIsInitializing]);

  if (isNextButtonLoaded) {
    return (
      <div>
        <Row className="full-space-row centered" style={{ marginTop: "50px" }}>
          <Card style={{ width: "100%" }} bordered={false}>
            <Result
              icon={<Spin indicator={antIcon} />}
              title="Stiamo prendendo in carico la tua richiesta!"
            />
          </Card>
        </Row>
      </div>
    );
  }

  return (
    <Row style={{ display: "flex", justifyContent: "space-around" }}>
      <Col md={12} xs={24}>
        <Row className="full-space-between-row">
          <Form
            style={{ width: "100%", textAlign: "start" }}
            name={confirmCheckoutFormName}
            layout="vertical"
            form={confirmForm}
            requiredMark={false}
          >
            {orderState?.sm_in_store_collection && (
              <Form.Item
                name="payment_method"
                label={
                  <Typography.Text strong>
                    Modalità di pagamento
                  </Typography.Text>
                }
                rules={[
                  {
                    required: false,
                    message: "Seleziona una modalità di pagamento",
                  },
                ]}
              >
                <Row className="full-space-between-row">
                  <Col span={11}>
                    <SelectableCard
                      icon={<GiTakeMyMoney style={{ fontSize: "2.5rem" }} />}
                      title="Pagamento in negozio"
                      selected={orderState.pm_in_store_payment}
                      onClickCardFunction={() =>
                        onClickPaymentMethodCard(
                          checkoutConstant.PM_IN_STORE_PAYMENT
                        )
                      }
                      hoverable
                    />
                  </Col>
                  <Col span={11}>
                    <SelectableCard
                      icon={<FaRegCreditCard style={{ fontSize: "2.5rem" }} />}
                      title={`Pagamento con carta`}
                      selected={
                        orderState.pm_is_online_payment &&
                        orderState.pm_is_stripe_payment
                      }
                      onClickCardFunction={() =>
                        onClickPaymentMethodCard(
                          checkoutConstant.PM_IS_STRIPE_PAYMENT
                        )
                      }
                      hoverable
                    />
                  </Col>
                </Row>
              </Form.Item>
            )}
            <Form.Item name="is_a_gift" valuePropName="checked">
              <Checkbox onChange={onChangeGiftCheckbox}>
                Questo ordine è un regalo 🎁
              </Checkbox>
            </Form.Item>
            {orderState?.is_a_gift && (
              <Form.Item
                name="dedication"
                label={<Typography.Text strong>Dedica</Typography.Text>}
              >
                <TextArea
                  showCount
                  allowClear
                  maxLength={200}
                  onChange={onChangeDedicationTextArea}
                />
              </Form.Item>
            )}
          </Form>
        </Row>
      </Col>
      <Col md={9} xs={24}>
        <CheckoutPriceComponent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          orderState={orderState}
          onClickNextStep={onClickNextStep}
          buttonTitle={
            orderState.pm_is_online_payment && orderState.pm_is_stripe_payment
              ? "PAGA CON CARTA"
              : "COMPLETA L'ORDINE"
          }
          isButtonDisabled={isNextStepButtonDisable}
          isButtonLoaded={isNextButtonLoaded}
        />
        {orderState.pm_is_online_payment && orderState.pm_is_stripe_payment && (
          <Row
            className="full-space-row"
            style={{
              fontSize: "0.7em",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            powered by{" "}
            <span>
              <Button
                style={{ padding: 0, fontSize: "0.9em" }}
                type="link"
                href="https://stripe.com/it"
                target="_blank"
                rel="noopener"
              >
                Stripe
              </Button>
            </span>
          </Row>
        )}
        <Row
          className="full-space-row"
          style={{
            marginTop: "8px",
            fontSize: "0.7em",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          Confermando l'ordine accetti i
          <span>
            <Button
              style={{ padding: 0, fontSize: "0.9em" }}
              type="link"
              onClick={() => history.push("/terms-and-conditions")}
            >
              Termini e Condizioni
            </Button>
          </span>
          di vendita del sito.
        </Row>
      </Col>
    </Row>
  );
};

export default ConfirmStep;
