import React from "react";
import "../App.less";
import { Switch, Route, useHistory } from "react-router-dom";

import { Button, Result } from "antd";

import { Home, Shop } from "../user/pages";
import Login from "../user/pages/Login";
import NotFound from "../user/pages/NotFound/NotFound";

import Path from "../utils/pathConstants";
import PublicRoute from "../authentication/PublicRoute";
import PrivateRoute from "../authentication/PrivateRoute";
import AdminRoute from "../authentication/AdminRoute";
import AdminConsole from "../admin";
import Product from "../user/pages/Product/Product";
import FinishSignUp from "../user/pages/Login/FinishSignUp";
import MyAccount from "../user/pages/MyAccount";
import Checkout from "../user/pages/Checkout";
import CheckoutOnePageResult from "../user/pages/Checkout/CheckoutOnePageResult";
import PrivacyECookiePolicy from "../user/pages/LegalDocuments/PrivacyECookiePolicy";
import TerminiECondizioni from "../user/pages/LegalDocuments/TerminiECondizioni";

const Router = () => {
  const history = useHistory();

  return (
    <Switch>
      <PublicRoute restricted={false} exact path="/" component={Home} />
      <PublicRoute
        restricted={false}
        exact
        path={`${Path.FLORAL_SERVICES}/:service`}
        component={NotFound}
      />
      <PrivateRoute exact path={`${Path.MY_ACCOUNT}`}>
        <MyAccount />
      </PrivateRoute>
      <PrivateRoute exact path={`${Path.CHECKOUT}`}>
        <Checkout />
      </PrivateRoute>
      <PrivateRoute exact path={`${Path.CHECKOUT}/one-page-result`}>
        <CheckoutOnePageResult />
      </PrivateRoute>
      <PublicRoute restricted={true} exact path={`/login`} component={Login} />
      <PublicRoute
        restricted={true}
        exact
        path={`/finishSignUp`}
        component={FinishSignUp}
      />
      <PublicRoute
        restricted={false}
        exact
        path={`${Path.SHOP}`}
        component={Shop}
      />
      <PublicRoute
        restricted={false}
        exact
        path={`${Path.CART}`}
        component={NotFound}
      />
      <PublicRoute
        restricted={false}
        exact
        path={`${Path.PRODUCT}`}
        component={Product}
      />
      <PublicRoute
        restricted={false}
        exact
        path={`${Path.PRIVACY_AND_COOKIE_POLICY}`}
        component={PrivacyECookiePolicy}
      />
      <PublicRoute
        restricted={false}
        exact
        path={`${Path.TERMS_AND_CONDITIONS}`}
        component={TerminiECondizioni}
      />
      <AdminRoute path={`${Path.ADMIN}`}>
        <AdminConsole />
      </AdminRoute>
      <Route
        children={() => (
          <Result
            status="404"
            title="404"
            subTitle="Scusami, la pagina che hai visitato non esiste"
            style={{ fontWeight: 700 }}
            extra={
              <Button type="primary" onClick={() => history.push("/")}>
                Torna alla Home
              </Button>
            }
          />
        )}
      />
    </Switch>
  );
};

export default Router;
