import { Checkbox, Col, Radio, Row, Slider, Typography } from "antd";
import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { changeFilters } from "../../../redux/Shop/shop.actions";

interface Props {
  filters: any;
  priceRange: number[];
}

const ShopFilter = ({ filters, priceRange }: Props) => {
  const dispatch = useDispatch();

  const { types, occasions } = useSelector(
    (state: RootStateOrAny) => state.website.products
  );

  const onSelectedPriceChange = (values) => {
    dispatch(changeFilters({ ...filters, price: values, is_by_string: false }));
  };

  const onTypesChange = (radioValue) => {
    dispatch(
      changeFilters({
        ...filters,
        types: radioValue?.target?.value,
        is_by_string: false,
      })
    );
  };

  const onOccasionsChange = (checkedValues) => {
    dispatch(
      changeFilters({
        ...filters,
        occasions: checkedValues,
        is_by_string: false,
      })
    );
  };

  return (
    <>
      <Row style={{ marginBottom: 24 }}>
        <Typography.Text strong>
          {"tipologia prodotto".toUpperCase()}
        </Typography.Text>
        <Radio.Group
          style={{ width: "100%" }}
          value={filters?.types}
          onChange={onTypesChange}
        >
          <Row>
            <Col span={24} style={{ display: "flex" }}>
              <Radio value={`all`} style={{ display: "block", marginLeft: 0 }}>
                {"Tutti i prodotti".toUpperCase()}
              </Radio>
            </Col>
            {types.map((type, index) => {
              if (type.value !== "other") {
                return (
                  <Col span={24} style={{ display: "flex" }} key={index}>
                    <Radio
                      value={type.value}
                      style={{ display: "block", marginLeft: 0 }}
                    >
                      {type.label.toUpperCase()}
                    </Radio>
                  </Col>
                );
              } else {
                return "";
              }
            })}
          </Row>
        </Radio.Group>
      </Row>
      <Row style={{ marginBottom: 12 }}>
        <Row style={{ width: "100%" }}>
          <Typography.Text strong>{"prezzo".toUpperCase()}</Typography.Text>
        </Row>
        <Row style={{ width: "100%" }}>
          <Typography.Text>
            {!filters?.price
              ? `€ ${priceRange[0]} - ${priceRange[1]}`
              : `€ ${filters?.price[0]} - ${filters?.price[1]}`}
          </Typography.Text>
        </Row>
        <Col span={24}>
          <Slider
            style={{ maxWidth: "250px" }}
            range
            min={priceRange[0]}
            max={priceRange[1]}
            onChange={onSelectedPriceChange}
            value={
              !filters?.price ||
              filters?.price[0] === undefined ||
              isNaN(filters?.price[0])
                ? priceRange
                : filters?.price
            }
            tipFormatter={(value) => `${value}€`}
          />
        </Col>
      </Row>
      <Row>
        <Row style={{ width: "100%" }}>
          <Typography.Text strong>{"occasione".toUpperCase()}</Typography.Text>
        </Row>
        <Checkbox.Group
          style={{ width: "100%" }}
          value={filters?.occasions}
          onChange={onOccasionsChange}
        >
          <Row>
            {occasions.map((occasion: string, index) => (
              <Col span={24} style={{ display: "flex" }} key={index}>
                <Checkbox
                  value={occasion.split(" ").join("_")}
                  style={{ display: "block", marginLeft: 0 }}
                >
                  {occasion.toUpperCase()}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Row>
    </>
  );
};

export default ShopFilter;
