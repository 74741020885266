import {
  Button,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import {
  CheckCircleFilled,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ColorPicker from "../../../components/ColorPicker";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { requestStatus } from "../../../utils";
import { updateScheduledOccasions } from "../../../redux/Website/website.actions";

const { RangePicker } = DatePicker;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};

const rangeConfig = {
  rules: [
    { type: "array" as const, required: true, message: "Seleziona un periodo" },
  ],
};

interface Props {}

const ScheduledOccasions = (props: Props) => {
  const dispatch = useDispatch();
  const { scheduled_occasions, fetching, status } = useSelector(
    (state: RootStateOrAny) => state.website.scheduled_occasions
  );
  const { occasions } = useSelector(
    (state: RootStateOrAny) => state.website.products
  );
  const [scheduledOccasionsForm] = useForm();

  useEffect(() => {
    scheduledOccasionsForm.setFieldsValue({
      scheduled_occasions: scheduled_occasions.scheduled_occasions,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduled_occasions]);

  const onFinishScheduledOccasionsForm = (values) => {
    //console.debug(values);
    dispatch(updateScheduledOccasions(values));
  };

  return (
    <div>
      <Row>
        <Typography.Title>Schedulazione occasioni</Typography.Title>
      </Row>
      <Form
        {...layout}
        style={{ maxWidth: "800px" }}
        name="scheduled-occasions-form"
        layout="horizontal"
        form={scheduledOccasionsForm}
        onFinish={onFinishScheduledOccasionsForm}
        size="small"
      >
        <Form.List name="scheduled_occasions">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key}>
                  <Row style={{ width: "100%", textAlign: "left" }}>
                    <DeleteOutlined onClick={() => remove(field.name)} />
                  </Row>
                  <Form.Item
                    {...field}
                    name={[field.name, "occasion"]}
                    label="Occasione"
                    rules={[
                      {
                        required: true,
                        message: "Seleziona l'occasione",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Seleziona l'occasione"
                    >
                      {occasions.map((occasion, index) => (
                        <Select.Option
                          key={`option_${index}`}
                          value={occasion.split(" ").join("_")}
                        >
                          {occasion}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "enabled"]}
                    label="Abilitato"
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Switch />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "range_date"]}
                    label="Periodo"
                    {...rangeConfig}
                  >
                    <RangePicker />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "color"]}
                    label="Colore"
                    rules={[
                      {
                        required: true,
                        message: "Seleziona il colore",
                      },
                    ]}
                  >
                    <ColorPicker />
                  </Form.Item>
                  <Divider
                    style={{
                      marginBottom: "30px",
                      borderTop: "2px solid rgb(0 0 0)",
                    }}
                  />
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Aggiungi card
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Row style={{ marginTop: "12px" }} align="middle">
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={fetching}
                form="scheduled-occasions-form"
              >
                Salva
              </Button>
              {status === requestStatus.COMPLETED && (
                <CheckCircleFilled style={{ color: "#07b07a" }} />
              )}
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ScheduledOccasions;
