import checkoutTypes from "./checkout.types";

const INITIAL_STATE = {
  order: undefined,
  createOrder: {},
};

const checkoutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case checkoutTypes.UPDATE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case checkoutTypes.UPDATE_CREATE_ORDER_STATUS:
      return {
        ...state,
        createOrder: {
          ...state.createOrder,
          status: action.payload,
        },
      };
    case checkoutTypes.SET_CREATE_ORDER_RESPONSE:
      return {
        ...state,
        createOrder: {
          ...state.createOrder,
          response: action.payload,
        },
      };
    default:
      return state;
  }
};

export default checkoutReducer;
