import { Button, Col, Drawer, Row } from "antd";
import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  removeAllShopFiltersProducts,
  toggleFilterDrawer,
} from "../../../redux/Shop/shop.actions";
import ShopFilter from "./ShopFilter";

interface Props {
  isLoading: boolean;
  filters: any;
  priceRange: number[];
}

const FilterDrawer = ({ isLoading, filters, priceRange }: Props) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(
    (state: RootStateOrAny) => state.shop.isFilterDrawerOpen
  );

  const onCloseDrawer = () => {
    dispatch(toggleFilterDrawer());
  };

  return (
    <Drawer
      placement="left"
      closable={true}
      onClose={onCloseDrawer}
      visible={isOpen}
      width="100%"
      bodyStyle={{ padding: "50px" }}
      footer={
        <Row justify="space-around">
          <Col>
            <Button
              style={{ color: "#8F8F8F" }}
              type="dashed"
              size="large"
              onClick={() => dispatch(removeAllShopFiltersProducts())}
            >
              Cancella filtri
            </Button>
          </Col>
          <Col>
            <Button
              style={{ fontSize: "1.3em", fontWeight: 500 }}
              type="primary"
              size="large"
              loading={isLoading}
              onClick={onCloseDrawer}
            >
              Mostra risultati
            </Button>
          </Col>
        </Row>
      }
    >
      <ShopFilter filters={filters} priceRange={priceRange} />
    </Drawer>
  );
};

export default FilterDrawer;
