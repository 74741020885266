import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import createSagaMiddle from "redux-saga";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { rrfProps } from "../firebase/utils";
import config from "../config";

const sagaMiddleware = createSagaMiddle();

let middlewares = [thunk, sagaMiddleware];

if (config.env !== "PROD") {
  middlewares = [...middlewares, logger];
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

export const rrfMainProps = {
  ...rrfProps,
  dispatch: store.dispatch,
};

sagaMiddleware.run(rootSaga);

export default store;
