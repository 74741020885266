import { Card, Rate, Row, Typography } from "antd";
import React from "react";
import { FirestoreProduct } from "../../../types/firestore/product.firestore.type";
import { GiSpotedFlower } from "react-icons/gi";

interface Props {
  isProductLoading: boolean;
  product: FirestoreProduct;
}

const ProductDetailCardBouquetDetails = ({
  isProductLoading,
  product,
}: Props) => {
  return (
    <Card
      className="details-bouquet-card card-fixed-shadow"
      loading={isProductLoading}
    >
      <Row className="row-bouquet" justify="space-between">
        <div>
          <Typography.Text strong>
            {`durabilità`.toLocaleUpperCase()}
          </Typography.Text>
        </div>
        <div className="paragraph-container">
          <div className="paragraph">
            <Rate
              disabled
              defaultValue={product?.details?.durability}
              character={<GiSpotedFlower />}
            />
          </div>
        </div>
      </Row>
      <Row className="row-bouquet" justify="space-between">
        <div>
          <Typography.Text strong>
            {`fragranza`.toLocaleUpperCase()}
          </Typography.Text>
        </div>
        <div className="paragraph-container">
          <div className="paragraph">
            <Rate
              disabled
              defaultValue={product?.details?.fragrance}
              character={<GiSpotedFlower />}
            />
          </div>
        </div>
      </Row>
      <Row className="row-bouquet" justify="space-between">
        <div>
          <Typography.Text strong>
            {`resistenza`.toLocaleUpperCase()}
          </Typography.Text>
        </div>
        <div className="paragraph-container">
          <div className="paragraph">
            <Rate
              disabled
              defaultValue={product?.details?.strength}
              character={<GiSpotedFlower />}
            />
          </div>
        </div>
      </Row>
    </Card>
  );
};

export default ProductDetailCardBouquetDetails;
