import { Button, Col, Collapse, Form, Input, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { FirestoreUser } from "../../../types/firestore/user.firestore.type";

const { Panel } = Collapse;

interface Props {
  userInfo: FirestoreUser | undefined;
  visible: boolean;
  handleOk: (any) => void;
  handleCancel: () => void;
  isUserInfoLoading: boolean;
}

const MyInfoModal = ({
  visible,
  userInfo,
  handleOk,
  handleCancel,
  isUserInfoLoading,
}: Props) => {
  const [mainForm] = Form.useForm();
  const [addressForm] = Form.useForm();

  mainForm.setFieldsValue(userInfo);
  addressForm.setFieldsValue(userInfo?.billing_address);

  const onFinish = () => {
    mainForm
      .validateFields()
      .then((mainFormValues) => {
        addressForm
          .validateFields()
          .then((addressFormValues) => {
            mainForm.resetFields();
            addressForm.resetFields();
            handleOk({
              ...mainFormValues,
              billing_address: {
                first_name: mainFormValues?.first_name,
                last_name: mainFormValues?.last_name,
                ...addressFormValues,
              },
            });
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  return (
    <Modal
      className="my-info-modal"
      title="Aggiorna i tuoi dati"
      visible={visible}
      closable={false}
      maskClosable={false}
      width={700}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={onFinish}
          loading={isUserInfoLoading}
        >
          Salva
        </Button>,
      ]}
    >
      <Form
        form={mainForm}
        size="small"
        scrollToFirstError={true}
        labelCol={{ span: 5 }}
      >
        <Form.Item
          name="first_name"
          label="Nome"
          rules={[{ required: true, message: "Inserisci il tuo nome" }]}
        >
          <Input placeholder="Nome" />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Cognome"
          rules={[{ required: true, message: "Inserisci il tuo cognome" }]}
        >
          <Input placeholder="Cognome" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Telefono"
          rules={[
            { required: true, message: "Inserisci il tuo numero di telefono" },
          ]}
        >
          <Input placeholder="Numero di telefono" />
        </Form.Item>
      </Form>
      <Collapse ghost>
        <Panel header="Indirizzo di fatturazione" key="1" forceRender={true}>
          <Form
            form={addressForm}
            size="small"
            scrollToFirstError={true}
            labelCol={{ span: 5 }}
          >
            <Form.Item
              label="Via"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Row justify="space-between">
                <Col xs={16}>
                  <Form.Item name="street_name">
                    <Input placeholder="Via/Viale/Piazza" />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    name="street_number"
                    rules={[
                      {
                        required: false,
                        message: "Inserisci il numero civico",
                        min: 1,
                      },
                    ]}
                  >
                    <Input placeholder="N. Civico" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              label="Città"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Row justify="space-between">
                <Col xs={14}>
                  <Form.Item name="city">
                    <Input placeholder="Città" />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item name="zipcode">
                    <Input placeholder="CAP" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              label="Provincia"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Row justify="space-between">
                <Col xs={6}>
                  <Form.Item
                    name="province"
                    rules={[
                      {
                        required: false,
                        message: "Inserisci la provincia",
                        len: 2,
                      },
                    ]}
                  >
                    <Input placeholder="PD" />
                  </Form.Item>
                </Col>
                <Col xs={14}>
                  <Form.Item name="state">
                    <Input placeholder="Stato" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </Modal>
  );
};

export default MyInfoModal;
