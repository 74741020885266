import { Card, Typography } from "antd";
import React from "react";
import { Helmet } from "react-helmet";

interface Props {}

const PrivacyECookiePolicy = (props: Props) => {
  return (
    <>
      <Helmet>
        <title>Privacy and Cookie Policy | Le Clematis Fioreria</title>
        <meta name="description" content="Privacy and Cookie Policy" />
      </Helmet>
      <Card style={{ width: "100%" }} bodyStyle={{ textAlign: "start" }}>
        <div>
          <Typography.Title>PRIVACY AND COOKIE POLICY</Typography.Title>
          <br />
          La nostra Cookie Policy ha l'intento di descrivere le tipologie di
          cookie utilizzati dalla nostra applicazione www.fiorerialeclematis.it
          (di seguito "Applicazione"), le finalità, la durata e le modalità con
          cui l'Utente può gestire i cookie presenti. Il titolare del
          trattamento dei dati personali è Antonella Menato Via Giarre 23,
          Galzignano Terme 35030 (di seguito il "Titolare").
          <br />
          1. COSA SONO I COOKIE I cookie sono stringhe di testo di piccole
          dimensioni che vengono memorizzate sul dispositivo dell'Utente quando
          naviga in rete. I cookie hanno lo scopo di raccogliere e memorizzare
          informazioni relative all'Utente al fine di rendere l'Applicazione più
          semplice, più veloce e più aderente alle sue richieste. Con il termine
          cookie si vuol far riferimento sia agli stessi cookie sia a tutte le
          tecnologie similari. L'Utente può bloccare i cookie, eliminarli o
          disattivarli nelle impostazioni del dispositivo o del browser come di
          seguito indicato nel paragrafo relativo a "Gestione dei cookie".
          <br />
          2. TIPOLOGIE DI COOKIE USATI DA QUESTA APPLICAZIONE Questa
          Applicazione usa i seguenti cookie: o Cookie tecnici di prima parte:
          sono cookie che appartengono al Titolare dell'Applicazione e che
          raccolgono dati dell'Utente accessibili solo al Titolare.
          L'Applicazione usa questi cookie per svolgere attività strettamente
          necessarie al funzionamento dell'Applicazione e all'erogazione del
          servizio o per raccogliere informazioni, in forma aggregata e anonima,
          per finalità statistiche. Questi cookie non profilano l'Utente. Per
          l'uso di questi cookie non è richiesto il consenso dell'Utente. o Non
          viene fatto uso di cookie di terza parte.
          <br />
          3. DURATA DEI COOKIE I cookie hanno una durata dettata dalla data di
          scadenza o da un'azione specifica, come la chiusura del browser
          impostata al momento dell'installazione. I cookie possono essere: o
          temporanei o di sessione: sono utilizzati per archiviare informazioni
          temporanee, consentono di collegare le azioni eseguite durante una
          sessione specifica e vengono rimossi dal dispositivo dell'Utente alla
          chiusura del browser; o persistenti: sono utilizzati per archiviare
          informazioni, ad esempio il nome e la password di accesso, in modo da
          evitare che l'Utente debba digitarli nuovamente ogni volta che visita
          un sito specifico. Questi rimangono memorizzati nel dispositivo
          dell'Utente anche dopo aver chiuso il browser.
          <br />
          4. GESTIONE DEI COOKIE L'Utente può gestire le preferenze relative ai
          cookie direttamente all'interno del proprio browser ed impedire che
          terze parti possano installare i cookies. Tramite le preferenze del
          browser è inoltre possibile eliminare i cookie installati in passato,
          incluso il cookie in cui è stato eventualmente salvato il consenso.
          Disabilitando tutti i cookie, il funzionamento di questa Applicazione
          potrebbe risultare compromesso. L'Utente può cancellare tutti i cookie
          presenti sul suo dispositivo semplicemente selezionando, sul proprio
          browser, le impostazioni che consentono di rifiutarli. Ogni browser ha
          specifiche istruzioni di configurazione che possono essere trovati sul
          sito internet del fornitore del browser ai seguenti link:
          <ul>
            <li>
              Apple Safari:
              <a
                rel="noopener noreferrer"
                href="https://support.apple.com/it-it/guide/safari/nnanage-cookies-and-website-data-sfri11471/"
                target="_blank"
              >
                https://support.apple.com/it-it/guide/safari/nnanage-cookies-and-website-data-sfri11471/
              </a>
            </li>
            <li>
              Google Chrome:
              <a
                rel="noopener noreferrer"
                href="https://supiaort.google.conn/chrome/answer/95647"
                target="_blank"
              >
                https://supiaort.google.conn/chrome/answer/95647
              </a>
            </li>
            <li>
              Mozilla Firefox:
              <a
                rel="noopener noreferrer"
                href="https://support.mozilla.org/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
              >
                https://support.mozilla.org/kb/enable-and-disable-cookies-website-preferences
              </a>
            </li>
            <li>
              Internet Explorer:
              <a
                rel="noopener noreferrer"
                href="http://windows.microsoft.com/Internet-explorer/delete-manage-cookies"
                target="_blank"
              >
                http://windows.microsoft.com/Internet-explorer/delete-manage-cookies
              </a>
            </li>
            <li>
              Microsoft Edge
              <a
                rel="noopener noreferrer"
                href="https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies"
                target="_blank"
              >
                https://support.microsoft.com/it-it/help/4027947/microsoft-edge-delete-cookies
              </a>
            </li>
            <li>
              Opera:
              <a
                rel="noopener noreferrer"
                href="https://www.opera.com/helpitutorials/security/privacy/"
                target="_blank"
              >
                https://www.opera.com/helpitutorials/security/privacy/
              </a>
            </li>
            <li>
              Inoltre si può procedere alla cancellazione dei cookie di terze
              parti chiedendo l'opt-out direttamente alle terze parti o tramite
              il sito http://www.youronlinechoices.com, dove è possibile gestire
              le preferenze di tracciamento della maggior parte degli strumenti
              pubblicitari. Per modificare le impostazioni relative ai cookie
              Flash si può cliccare sul seguente link .
              <a
                rel="noopener noreferrer"
                href="https://www.macromedia.com/support/documentation/englashplayer/help/settingsmanager07.html"
                target="_blank"
              >
                https://www.macromedia.com/support/documentation/englashplayer/help/settingsmanager07.html
              </a>
            </li>
          </ul>
          <br />
          5. DIRITTI DELL'UTENTE Gli Utenti possono esercitare determinati
          diritti con riferimento ai dati personali trattati dal Titolare. In
          particolare, l'Utente ha il diritto di:
          <ul>
            <li>revocare il consenso in ogni momento;</li>
            <li>opporsi al trattamento dei propri dati personali;</li>
            <li> accedere ai propri dati personali;</li>
            <li> verificare e chiedere la rettificazione;</li>
            <li> ottenere la limitazione del trattamento;</li>
            <li>
              ottenere la cancellazione o rimozione dei propri dati personali;
            </li>
            <li>
              ricevere i propri dati personali o farli trasferire ad altro
              titolare;
            </li>
            <li>
              proporre reclamo all'autorità di controllo della protezione dei
              dati personali e/o agire in sede giudiziale.
            </li>
          </ul>
          Per esercitare tali diritti, scrivere al Titolare: Le richieste sono
          effettuate a titolo gratuito ed evase dal Titolare nel più breve tempo
          possibile, in ogni caso entro 30 giorni. Le ulteriori informazioni
          riguardo al trattamento dei dati personali si possono trovare
          nell'informativa privacy al seguente link www.fiorerialeclematis.it.
          <br />
          Ultimo aggiornamento: 21 /02/2021
        </div>
      </Card>
    </>
  );
};

export default PrivacyECookiePolicy;
