import { all, put, takeLatest } from "redux-saga/effects";
import { push } from "react-router-redux";
import { loadStripe } from "@stripe/stripe-js";
import { getStripeKey, requestStatus } from "../../utils";
import {
  setCreateOrderResponse,
  updateCreateOrderStatus,
} from "./checkout.actions";
import checkoutTypes from "./checkout.types";
import Path from "../../utils/pathConstants";

interface ActionWithPayload {
  type: string;
  payload?: any;
}

export function* createOrderSaga({ payload: order }: ActionWithPayload) {
  yield put(updateCreateOrderStatus(requestStatus.STARTED));
  try {
    const response = yield;

    if (response.status !== 200) {
      yield put(setCreateOrderResponse(response.data));
      if (order.pm_is_stripe_payment) {
        const stripePromise = yield loadStripe(getStripeKey());
        const stripe = yield stripePromise;
        const result = yield stripe.redirectToCheckout({
          sessionId: response.id,
        });
        if (result.error) {
          yield put(updateCreateOrderStatus(requestStatus.ERROR));
          yield put(push(`${Path.CHECKOUT}/one-page-result?canceled=true`));
        }
      } else {
        yield put(push(`${Path.CHECKOUT}/one-page-result?success=true`));
      }
      yield put(updateCreateOrderStatus(requestStatus.COMPLETED));
    } else {
      yield put(updateCreateOrderStatus(requestStatus.ERROR));
      yield put(push(`${Path.CHECKOUT}/one-page-result?canceled=true`));
    }

    //console.debug(response);
  } catch (error) {
    yield put(updateCreateOrderStatus(requestStatus.ERROR));
  }
}

/**
 * Export sagas
 */
export default function* checkoutSagas() {
  yield all([takeLatest(checkoutTypes.CREATE_ORDER, createOrderSaga)]);
}
