const Path = {
  FLORAL_SERVICES: "/floral-services",
  SHOP: "/shop",
  PRODUCT: "/product",
  MY_ACCOUNT: "/my-account",
  ADMIN: "/admin",
  CART: "/cart",
  CHECKOUT: "/checkout",
  PRIVACY_AND_COOKIE_POLICY: "/privacy-and-cookie-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
};

export default Path;
