import { FirestoreProduct } from "../../types/firestore/product.firestore.type";
import { Product } from "../../types/product.type";
import productsTypes from "./products.types";

export const storeProductImage = (compressedImage) => ({
  type: productsTypes.STORE_PRODUCT_IMAGE,
  payload: compressedImage,
});

export const uploadedProductImage = (data) => ({
  type: productsTypes.ADDED_IMAGE_PRODUCT,
  payload: data,
});

/**
 * This function is used to upload the product in the firestore cloud database
 * @param productInfo Product to upload in firestore
 */
export const startUploadingProduct = (productInfo: {
  imageBlobList: Array<Blob>;
  product: FirestoreProduct;
}) => ({
  type: productsTypes.START_UPLOAD_PRODUCT,
  payload: productInfo,
});

export const startUpdateUploadingProduct = (productInfo) => ({
  type: productsTypes.START_UPDATE_UPLOAD_PRODUCT,
  payload: productInfo,
});

export const updateImageFormProductData = (images) => ({
  type: productsTypes.UPDATE_IMAGE_FORM_PRODUCT_DATA,
  payload: images,
});

export const changeProductUploadStatus = (status: string) => ({
  type: productsTypes.CHANGE_UPLOAD_PRODUCT_STATUS,
  payload: status,
});

export const getProductsList = () => ({
  type: productsTypes.GET_PRODUCTS_LIST,
});

export const changeProductsListStatus = (status: boolean) => ({
  type: productsTypes.CHANGE_PRODUCTS_LIST_STATUS,
  payload: status,
});

export const setProductsList = (productsList: Array<Product>) => ({
  type: productsTypes.SET_PRODUCTS_LIST,
  payload: productsList,
});

export const deleteProduct = (productID: string) => ({
  type: productsTypes.DELETE_PRODUCT,
  payload: productID,
});

export const changeProductDeletionStatus = (status: string) => ({
  type: productsTypes.CHANGE_DELETION_PRODUCT_STATUS,
  payload: status,
});

export const updateProduct = (productInfo: {
  productID: string;
  product: any;
}) => ({
  type: productsTypes.UPDATE_PRODUCT,
  payload: productInfo,
});

export const changeProductUpdatingStatus = (status: string) => ({
  type: productsTypes.CHANGE_UPDATING_PRODUCT_STATUS,
  payload: status,
});

export const getExistingProduct = (productID: string) => ({
  type: productsTypes.GET_PRODUCT,
  payload: productID,
});

export const changeGetProductStatus = (status: string) => ({
  type: productsTypes.CHANGE_GET_PRODUCT_STATUS,
  payload: status,
});

export const addFormProductData = (data: Product) => ({
  type: productsTypes.ADD_FORM_PRODUCT_DATA,
  payload: data,
});

export const resetFormProductData = () => ({
  type: productsTypes.RESET_FORM_PRODUCT_DATA,
});

export const getPlainProductData = (data: FirestoreProduct) => ({
  type: productsTypes.GET_PLAIN_PRODUCT_DATA,
  payload: data,
});

export const resetPlainProductData = () => ({
  type: productsTypes.RESET_PLAIN_PRODUCT_DATA,
});
