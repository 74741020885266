import { v4 as uuidv4 } from "uuid";
import config from "../config";

/* eslint-disable no-control-regex */
export { default } from "./useWindowSize";

export const requestStatus = {
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
};

export const productTypesConstants = {
  BOUQUET: "bouquet",
  PLANT: "plant",
  SINGLE_FLOWER: "single_flower",
  OTHER: "other",
};

const prefix = "FioreriaLeClematis:";
export const sessionStorageKeys = {
  PRODUCT_LIST: prefix + "PRODUCT_LIST",
};

export const localStorageKeys = {
  CART_ARRAY: prefix + "CART_ARRAY",
  CART_MAP: prefix + "CART_MAP",
  IS_COOKIES_READ: prefix + "is_cookies_read",
  PASSWORDLESS_EMAIL: prefix + "passwordless_email",
};

export const commonRegEx = {
  EMAIL: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
};

const mapConversionDate = {
  january: "gennaio",
  february: "febbraio",
  march: "marzo",
  april: "aprile",
  may: "maggio",
  june: "giugno",
  july: "luglio",
  august: "agosto",
  september: "settembre",
  october: "ottobre",
  november: "novembre",
  december: "dicembre",
  sunday: "domenica",
  monday: "lunedì",
  tuesday: "martedì",
  wednesday: "mercoledì",
  thursday: "giovedì",
  friday: "venerdì",
  saturday: "sabato",
};

export const retrieveFiltersOnShopPage = (
  query: URLSearchParams
): {
  types: Array<string> | undefined;
  price: Array<number> | undefined;
  occasions: Array<string> | undefined;
} => {
  let response = {} as {
    types: Array<string> | undefined;
    price: Array<number> | undefined;
    occasions: Array<string> | undefined;
  };
  const typesString = query.get("types");
  const priceString = query.get("price");
  const occasionsString = query.get("occasions");

  response.types = typesString?.split(",");
  response.price = priceString?.split(",").map((value) => parseInt(value));
  response.occasions = occasionsString?.split(",");

  console.info(response);

  return response;
};

export const retrieveIdOnPage = (query: URLSearchParams) => query.get("id");

export const isAPhone = () => {
  const { innerWidth } = window;
  return innerWidth >= 990 ? false : true;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeDate = (s) => {
  if (typeof s !== "string") return "";
  return s
    .split(" ")
    .map((ss) =>
      mapConversionDate[ss.toLowerCase()]
        ? mapConversionDate[ss.toLowerCase()].charAt(0).toUpperCase() +
          mapConversionDate[ss.toLowerCase()].slice(1)
        : ss.charAt(0).toUpperCase() + ss.slice(1)
    )
    .join(" ");
};

export const getUUIDv4 = (): string => uuidv4();

export const isALocalTest = (): boolean => config.env === "LOCAL";

export const isATest = (): boolean => config.env === "TEST";

export const isLive = (): boolean => config.env === "PROD";

export const getStripeKey = (): string => config.stripe.public_key;
