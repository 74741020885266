import {
  MinusCircleOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  InputNumber,
  Rate,
  Select,
  Space,
  Tooltip,
} from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";

interface Props {
  form: FormInstance<any>;
  formFieldsValues: any;
  productType: any;
}
const bouquetDetails = [
  {
    name: "durability",
    label: "Durabilita'",
    tooltip: "Durata del bouquet",
  },
  {
    name: "strength",
    label: "Resistenza",
    tooltip: "Resistenza del fiore",
  },
  {
    name: "fragrance",
    label: "Fragranza",
    tooltip: "Intensita' dell'aroma",
  },
];

const BouquetDetails = ({ form, formFieldsValues, productType }: Props) => {
  let configurations = ["basic", "essential", "deluxe", "supreme"];
  if (productType === "is_a_bouquet") {
    configurations.shift();
  }
  form.setFieldsValue({
    prices: [{ type: configurations[0] }],
    ...formFieldsValues,
  });

  return (
    <>
      <Divider>
        <span>
          prezzi&nbsp;
          <Tooltip
            title={`Le configurazioni descrivono il prezzo dei bouquet.`}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </span>
      </Divider>
      <Form.List name="prices">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map((field) => (
                <Space key={field.key} align="start">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.prices !== curValues.prices
                    }
                  >
                    {() => (
                      <Form.Item
                        {...field}
                        label="Tipo"
                        name={[field.name, "type"]}
                        fieldKey={[field.fieldKey, "type"]}
                        rules={[{ required: true, message: "Nome mancante" }]}
                      >
                        <Select style={{ width: 120, marginRight: "40px" }}>
                          {configurations.map((item) => (
                            <Select.Option
                              key={item.toUpperCase()}
                              value={item}
                            >
                              {item.toUpperCase()}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="N. steli"
                    name={[field.name, "stems_number"]}
                    fieldKey={[field.fieldKey, "stems_number"]}
                    rules={[{ required: true, message: "N. steli mancante" }]}
                  >
                    <InputNumber min={0} max={10000} placeholder="n. steli" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label="Prezzo"
                    name={[field.name, "price"]}
                    fieldKey={[field.fieldKey, "price"]}
                    rules={[{ required: true, message: "Prezzo mancante" }]}
                  >
                    <InputNumber
                      min={0}
                      max={10000}
                      placeholder="prezzo"
                      formatter={(value) =>
                        `€${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      // eslint-disable-next-line no-useless-escape
                      parser={(value: any) => value.replace("€", "")}
                    />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  block
                >
                  <PlusOutlined /> Aggiungi prezzo
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>

      <Divider>dettagli</Divider>

      {bouquetDetails.map((detail) => (
        <Form.Item
          name={detail.name}
          label={
            <span>
              {detail.label}&nbsp;
              <Tooltip title={detail.tooltip}>
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <Rate />
        </Form.Item>
      ))}
    </>
  );
};

export default BouquetDetails;
