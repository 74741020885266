import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteItemOnCartAction,
  updateItemOnCartAction,
} from "../../redux/Cart/cart.actions";
import { CartProduct } from "../../types/cartProduct.type";
import QuantityButton from "../QuantityButton";

interface Props {
  item: CartProduct;
}

const CartElement = ({ item }: Props) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(item.quantity);
  const quantityHandler = (step: number) => {
    if (quantity + step >= 1 && quantity + step < 100) {
      setQuantity(quantity + step);
    }
  };
  useEffect(() => {
    dispatch(
      updateItemOnCartAction({
        productCartKey: item.productCartKey,
        quantity: quantity,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);
  
  return (
    <Row
      style={{ flexWrap: "nowrap", marginBottom: "16px" }}
      justify="space-between"
    >
      <Col style={{ marginRight: "12px" }} flex="100px">
        <img
          alt={item?.image?.uid}
          style={{ width: "100px", height: "100px", objectFit: "cover" }}
          src={item?.image?.url}
        />
      </Col>
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        flex="auto"
      >
        <Row style={{ flexWrap: "nowrap" }} justify="space-between">
          <span>
            <Typography.Paragraph
              style={{
                margin: 0,
                paddingRight: "12px",
                lineHeight: "initial",
                textAlign: "left",
                color: "#A1A1A1",
                marginBottom: "4px",
              }}
              ellipsis={{
                rows: 2,
              }}
            >
              {item?.name}
            </Typography.Paragraph>
          </span>
          <span>
            <Typography.Paragraph
              style={{
                fontWeight: 600,
                marginBottom: "4px",
                flexWrap: "nowrap",
              }}
            >
              {`€ ${parseFloat((item.price * item.quantity).toString()).toFixed(
                2
              )}`}
            </Typography.Paragraph>
          </span>
        </Row>
        <Row
          style={{ flexWrap: "nowrap", marginBottom: "12px" }}
          justify="space-between"
        >
          <span>
            {item?.configuration?.type !== undefined ? (
              <Typography.Paragraph
                style={{
                  margin: "0 12px 0 0 ",
                  lineHeight: "initial",
                  textAlign: "left",
                  color: "#A1A1A1",
                }}
                ellipsis={{
                  rows: 2,
                }}
              >
                {item?.configuration?.type?.toLocaleUpperCase()}
              </Typography.Paragraph>
            ) : (
              ""
            )}
          </span>
          <span>
            <Typography.Text style={{ color: "#676767" }}>
              {`${item.quantity} x € ${parseFloat(
                item.price.toString()
              ).toFixed(2)}`}
            </Typography.Text>
          </span>
        </Row>
        <Row justify="space-between">
          <span>
            <QuantityButton
              quantity={item.quantity}
              clickHandler={quantityHandler}
            />
          </span>
          <span>
            <Button
              style={{
                height: "100%",
                backgroundColor: "none",
                border: "none",
                color: "#000000",
              }}
              icon={<DeleteOutlined />}
              onClick={() =>
                dispatch(deleteItemOnCartAction(item.productCartKey))
              }
            />
          </span>
        </Row>
      </Col>
    </Row>
  );
};

export default CartElement;
