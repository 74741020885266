import React from "react";
import ImageGallery from "react-image-gallery";
import { RootStateOrAny, useSelector } from "react-redux";
import { FirestoreProduct } from "../../../types/firestore/product.firestore.type";

interface Props {}

const ProductGallery = (props: Props) => {
  const product: FirestoreProduct = useSelector(
    (state: RootStateOrAny) => state.products.plainProductData
  );

  let productImages = [] as Array<{ original: any; thumbnail: any }>;
  product?.images?.forEach((image) => {
    productImages.push({ original: image?.url, thumbnail: image?.url });
  });

  return (
    <ImageGallery
      items={productImages && productImages}
      showNav={false}
      showFullscreenButton={false}
      showPlayButton={false}
      showBullets={false}
      slideDuration={0}
      renderItem={(item) => (
        <div className="image-product-gallery-container">
          <img
            className="image-product-gallery"
            src={item?.original}
            alt={item?.original}
          />
        </div>
      )}
      renderThumbInner={(item) => (
        <div>
          <img
            className="thumbnail-image-product-gallery"
            src={item?.original}
            alt={item?.original}
          />
        </div>
      )}
    />
  );
};

export default ProductGallery;
