import { Card, Row, Tabs, Typography } from "antd";
import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUploadImages } from "../../../redux/Website/website.actions";
import HomePage from "./HomePage";
import ImagesHandler from "./ImagesHandler";
import OpeningHours from "./OpeningHours";
import ScheduledOccasions from "./ScheduledOccasions";
import Zipcodes from "./Zipcodes";

const { TabPane } = Tabs;

interface Props {}

const Configurations = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUploadImages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const panels: any[] = [
    {
      key: "zipcodes",
      header_title: "Citta' di consegna",
      component: <Zipcodes />,
    },
    {
      key: "openingHours",
      header_title: "Orari di apertura",
      component: <OpeningHours />,
    },
    {
      key: "home_page",
      header_title: "Home Page",
      component: <HomePage />,
    },
    {
      key: "images_handler",
      header_title: "Immagini",
      component: <ImagesHandler />,
    },
    {
      key: "scheduled_occasions",
      header_title: "Occasioni",
      component: <ScheduledOccasions />,
    },
  ];
  return (
    <div>
      <Row>
        <Typography.Title level={3}>Configurazioni</Typography.Title>
      </Row>
      <Card style={{ width: "100%" }} bodyStyle={{ minHeight: "80vh" }}>
        <Tabs
          tabPosition="left"
          style={{ width: "100%", height: "100%" }}
          tabBarStyle={{ height: "100%" }}
        >
          {panels.map((panel) => (
            <TabPane
              tab={
                <Typography.Text strong>{panel.header_title}</Typography.Text>
              }
              key={panel.key}
            >
              {panel.component}
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </div>
  );
};

export default Configurations;
