import { Button, Divider, Drawer, Row, Typography } from "antd";
import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleCartDrawer } from "../../redux/Website/website.actions";
import useWindowSize from "../../utils";
import Path from "../../utils/pathConstants";
import CartElement from "./CartElement";

interface Props {}

const CartDrawer = (props: Props) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();

  const { isCartDrawerOpen } = useSelector(
    (state: RootStateOrAny) => state.website
  );
  const { cartArray, itemsCounter, cartSubTotal } = useSelector(
    (state: RootStateOrAny) => state.cart
  );

  let drawerWidth = width < 768 ? "100%" : "35%";
  const closeCartDrawer = () => dispatch(toggleCartDrawer());

  return (
    <Drawer
      width={drawerWidth}
      placement={"right"}
      closable={true}
      onClose={closeCartDrawer}
      visible={isCartDrawerOpen}
      key={"left"}
      footer={
        itemsCounter > 0 && (
          <div style={{ padding: "25px" }}>
            <Row style={{ marginBottom: "25px" }} justify="space-between">
              <span>
                <Typography.Text style={{ fontSize: "1.3em", fontWeight: 700 }}>
                  Totale carrello
                </Typography.Text>
              </span>
              <span>
                <Typography.Text
                  style={{ fontSize: "1.3em", fontWeight: 700 }}
                >{`${parseFloat(cartSubTotal.toString()).toFixed(
                  2
                )}€`}</Typography.Text>
              </span>
            </Row>
            <Row>
              <Button
                type="primary"
                onClick={() => {
                  history.push(Path.CHECKOUT);
                  closeCartDrawer();
                }}
                block
              >
                VAI AL CHECKOUT
              </Button>
            </Row>
          </div>
        )
      }
    >
      <Row style={{ marginTop: "20px" }}>
        <Typography.Title level={2}>Il mio carrello</Typography.Title>
      </Row>
      <Divider />
      {itemsCounter > 0 ? (
        cartArray.map((item) => (
          <CartElement key={item.productCartKey} item={item} />
        ))
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography.Text style={{ marginBottom: "12px" }}>
            Il tuo carrello e' ancora vuoto
          </Typography.Text>
          <Row>
            <Button
              block
              onClick={() => {
                history.push(Path.SHOP);
                dispatch(toggleCartDrawer());
              }}
            >
              INIZIA LO SHOPPING
            </Button>
          </Row>
        </div>
      )}
      {}
    </Drawer>
  );
};

export default CartDrawer;
