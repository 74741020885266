import { shopTypes } from "./shop.types";

export const toggleFilterDrawer = () => ({
  type: shopTypes.TOGGLE_FILTER_DRAWER,
});

export const changeFilters = (filters) => ({
  type: shopTypes.CHANGE_FILTERS,
  payload: filters,
});

export const getShopProducts = (filters) => ({
  type: shopTypes.GET_SHOP_PRODUCTS,
  payload: filters,
});

export const setShopProducts = (queriedProducts) => ({
  type: shopTypes.SET_SHOP_PRODUCTS,
  payload: queriedProducts,
});

export const removeAllShopFiltersProducts = () => ({
  type: shopTypes.REMOVE_ALL_SHOP_FILTERS_PRODUCTS,
});

export const changeGetShopProductsStatus = (status) => ({
  type: shopTypes.CHANGE_GET_SHOP_PRODUCTS_STATUS,
  payload: status,
});

export const getShopProductDetails = () => ({
  type: shopTypes.GET_SHOP_PRODUCT_DETAILS,
});

export const setShopProductDetails = (productDetails) => ({
  type: shopTypes.SET_SHOP_PRODUCT_DETAILS,
  payload: productDetails,
});


