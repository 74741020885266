import { all, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import productsTypes from "./products.types";
import { firebaseStorage, firestore } from "../../firebase/utils";
import {
  setProductsList,
  addFormProductData,
  changeProductsListStatus,
  changeGetProductStatus,
  changeProductDeletionStatus,
  changeProductUpdatingStatus,
  changeProductUploadStatus,
  getExistingProduct,
  getPlainProductData,
} from "./products.actions";
import { v4 as uuidv4 } from "uuid";

import { Product } from "../../types/product.type";
import {
  fromFirestoreProductToShortProduct,
  fromFirestoreToForm,
} from "../../utils/productUtils";
import { FirestoreProduct } from "../../types/firestore/product.firestore.type";
import { sessionStorageKeys } from "../../utils";
import { FirestoreShortProduct } from "../../types/firestore/shortProduct.firestore.type";
import { setShopProductDetails } from "../Shop/shop.actions";

interface ActionWithPayload {
  type: string;
  payload?: any;
}

export function* calculatePriceRanges(
  queriedProducts: FirestoreShortProduct[]
) {
  let value =
    queriedProducts !== undefined && queriedProducts[0]?.price
      ? queriedProducts[0]?.price
      : 0;
  let min = value;
  let max = value;

  queriedProducts.forEach((prod) => {
    if (prod && prod.price && min > prod.price) {
      min = prod.price;
    }
    if (prod && prod.price && max < prod.price) {
      max = prod.price;
    }
  });
  yield put(setShopProductDetails([min, max]));
}

const storeInSessionStorageProductsList = function* (productsList) {
  yield sessionStorage.setItem(
    sessionStorageKeys.PRODUCT_LIST,
    JSON.stringify(productsList)
  );
  yield calculatePriceRanges(productsList);
  yield put(setProductsList(productsList));
};

/**
 * *Product firestore uploading saga
 */
const imageURLList = function* (imageBlobList: Array<Blob>) {
  const imageList = [] as Array<{
    url: string;
    uid: string;
    is_main_image: boolean;
  }>;
  const storageRef = yield firebaseStorage.storage().ref();
  try {
    for (const imageBlob of imageBlobList) {
      const uuid = uuidv4();
      const imageRef = storageRef.child(`images/${uuid}.jpg`);
      const imageURL = yield imageRef
        .put(imageBlob)
        .then((snapshot) => snapshot.ref.getDownloadURL());
      imageList.push({ url: imageURL, uid: uuid, is_main_image: false });
    }
  } catch (error) {
    yield put(changeProductUploadStatus("ERROR"));
  }
  return imageList;
};

/**
 * *Saga to upload product
 * @param param0 Product information
 */
export function* uploadProduct({ payload: productInfo }: ActionWithPayload) {
  //STARTED
  yield put(changeProductUploadStatus("STARTED"));
  try {
    //UPLOAD IMAGES
    let imageList: Array<{
      url: string;
      uid: string;
      is_main_image: boolean;
    }> = yield imageURLList(productInfo?.imageBlobList);
    imageList[0].is_main_image = true;
    const product: FirestoreProduct = {
      ...productInfo?.product,
      images: imageList,
    };
    //UPLOAD PRODUCT
    const productData = yield JSON.parse(JSON.stringify(product));
    const docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = yield firestore
      .collection(`products`)
      .add(productData);
    const doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = yield docRef.get();

    const productsList: any[] = yield select(
      (state) => state.products.productsList
    );

    const shortProduct = yield fromFirestoreProductToShortProduct({
      ...doc.data(),
      uid: doc.id,
    });
    productsList.push(shortProduct);
    yield storeInSessionStorageProductsList(productsList);

    //COMPLETED
    yield put(changeProductUploadStatus("COMPLETED"));
  } catch (error) {
    console.error(error);
    //ERROR
    yield put(changeProductUploadStatus("ERROR"));
  }
}

/**
 * *Get products
 */
export function* getProductsList() {
  yield put(changeProductsListStatus(true));
  let productList: any[] = [];
  const cachedProductListString: string = yield sessionStorage.getItem(
    sessionStorageKeys.PRODUCT_LIST
  );
  let cachedProductList: any[] | undefined;
  try {
    cachedProductList = JSON.parse(cachedProductListString) as any[];
  } catch (error) {
    console.debug(error);
    cachedProductList = undefined;
  }
  if (cachedProductList !== undefined && Array.isArray(cachedProductList)) {
    productList = cachedProductList;
  } else {
    try {
      const snapshot = yield firestore
        .collection("generic_infos")
        .doc("product_list")
        .get();

      const { product_list } = snapshot.data();
      productList = product_list;
    } catch (error) {
      console.error(error);
    }
  }
  yield storeInSessionStorageProductsList(productList);
  yield put(changeProductsListStatus(false));
}

/**
 * *Delete product
 */
export function* deleteProduct({ payload: productID }: ActionWithPayload) {
  //STARTED
  yield put(changeProductDeletionStatus("STARTED"));
  try {
    yield firestore.collection("products").doc(productID).delete();
    let productsList: FirestoreShortProduct[] = yield select(
      (state) => state.products.productsList
    );
    productsList = productsList.filter(
      (product: FirestoreShortProduct) => product.productID !== productID
    );
    yield storeInSessionStorageProductsList(productsList);
    //COMPLETED
    yield put(changeProductDeletionStatus("COMPLETED"));
  } catch (error) {
    //ERROR
    yield put(changeProductDeletionStatus("ERROR"));
  }
}

/**
 * *Update product
 */
export function* updateProduct({ payload: productInfo }: ActionWithPayload) {
  //STARTED
  yield put(changeProductUpdatingStatus("STARTED"));
  //REMOVE SESSION_STORAGE
  yield sessionStorage.removeItem(sessionStorageKeys.PRODUCT_LIST);
  try {
    yield firestore
      .collection("products")
      .doc(productInfo?.productID)
      .update({
        ...productInfo?.product,
        updated_at: firebaseStorage.firestore.FieldValue.serverTimestamp(),
      });
    //COMPLETED
    yield put(changeProductUpdatingStatus("COMPLETED"));
  } catch (error) {
    //ERROR
    yield put(changeProductUpdatingStatus("ERROR"));
  }
}

/**
 * *Get single product
 */
export function* getProduct({ payload: productID }: ActionWithPayload) {
  //STARTED
  yield put(changeGetProductStatus("STARTED"));
  try {
    const docRef = yield firestore.collection("products").doc(productID).get();
    const docData: FirestoreProduct = yield docRef.data();
    yield put(getPlainProductData(docData));
    const formData: Product = fromFirestoreToForm(docData);
    yield put(addFormProductData(formData));
    yield put(changeGetProductStatus("COMPLETED"));
  } catch (error) {
    //ERROR
    yield put(changeGetProductStatus("ERROR"));
  }
}

/**
 * *Start update uploading product
 * Used to update an existences product
 * @param param0
 */
export function* startUpdateUploadingProduct({
  payload: productInfo,
}: ActionWithPayload) {
  //STARTED
  yield put(changeProductUploadStatus("STARTED"));
  try {
    yield put(getExistingProduct(productInfo.uid));
    //COMPLETED
    yield put(changeProductUploadStatus("COMPLETED"));
  } catch (error) {
    console.error(error);
    //ERROR
    yield put(changeProductUploadStatus("ERROR"));
  }
}

/**
 * *Export sagas
 */
export default function* productsSagas() {
  yield all([
    takeLatest(productsTypes.START_UPLOAD_PRODUCT, uploadProduct),
    takeLatest(productsTypes.GET_PRODUCTS_LIST, getProductsList),
    takeLatest(productsTypes.DELETE_PRODUCT, deleteProduct),
    takeEvery(productsTypes.UPDATE_PRODUCT, updateProduct),
    takeLatest(productsTypes.GET_PRODUCT, getProduct),
    takeLatest(
      productsTypes.START_UPDATE_UPLOAD_PRODUCT,
      startUpdateUploadingProduct
    ),
  ]);
}
