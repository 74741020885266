import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import PageLoader from "../components/PageLoader/PageLoader";

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector((state: RootStateOrAny) => state.firebase.auth);  
  if (!isLoaded(auth))
    return (
      <PageLoader/>
    );
  return children;
};

export default AuthIsLoaded;
