import { FilterOutlined, FrownOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  Card,
  Col,
  message,
  Result,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SearchProductBar from "../../../components/SearchProductBar";
import {
  changeFilters,
  getShopProductDetails,
  getShopProducts,
  removeAllShopFiltersProducts,
  toggleFilterDrawer,
} from "../../../redux/Shop/shop.actions";
import useWindowSize, { capitalizeDate, requestStatus } from "../../../utils";
import FilterDrawer from "./FilterDrawer";
import QueriedProductShopCards from "./QueriedProductShopCards";
import ShopFilter from "./ShopFilter";
import ShopSorter from "./ShopSorter";
import SkeletonShopCards from "./SkeletonShopCards";
import _ from "lodash";
import { Helmet } from "react-helmet";

interface Props {}

const Shop = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowSize();
  const isAPhone = width >= 990 ? false : true;
  const [isGettingShopProducts, setIsGettingShopProducts] = useState(false);

  const {
    filters,
    getShopProductsStatus,
    queriedProducts,
    price,
  } = useSelector((state: RootStateOrAny) => state.shop);

  const { nextAvailableMoment } = useSelector(
    (state: RootStateOrAny) => state.website.nextAvailableMoment
  );

  const onOpenFilterDrawer = () => {
    dispatch(toggleFilterDrawer());
  };

  useEffect(() => {
    dispatch(getShopProductDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams: any = new URLSearchParams(window.location.search);
    let retrievedFilters: any = {};
    for (let [key, value] of searchParams.entries()) {
      if (value === undefined || value === "") {
        continue;
      }
      let splittedValue: string = value.split(",");
      value = splittedValue.length > 1 ? splittedValue : splittedValue[0];
      if (key === "occasions") {
        value = splittedValue;
      }
      retrievedFilters[key] = value;
    }
    if (_.isEmpty(retrievedFilters)) {
      retrievedFilters.types = "all";
      retrievedFilters.is_by_string = false;
    }
    dispatch(changeFilters(retrievedFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filters) {
      history.push({
        pathname: history.location.pathname,
        search: "?" + new URLSearchParams(filters).toString(),
      });
      dispatch(getShopProducts(filters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    switch (getShopProductsStatus) {
      case requestStatus.STARTED:
        setIsGettingShopProducts(true);
        break;
      case requestStatus.COMPLETED:
        setIsGettingShopProducts(false);
        break;
      case requestStatus.ERROR:
        message.error("Errore nella richiesta");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getShopProductsStatus]);

  return (
    <>
      <Helmet>
        <title>Shop | Le Clematis Fioreria</title>
        <meta
          name="description"
          content="Shop Fioreria Le Clematis Galzignano Terme"
        />
      </Helmet>
      <FilterDrawer
        isLoading={isGettingShopProducts}
        filters={filters}
        priceRange={price}
      />
      <div className="shop">
        <Row
          justify="center"
          style={{ backgroundColor: "#07B07A", padding: "6px 0" }}
        >
          <Typography.Text
            style={{ color: "white" }}
            strong
          >{`🚚 Ordina oggi e ricevi ${capitalizeDate(
            nextAvailableMoment?.format("dddd D MMMM")
          )}`}</Typography.Text>
        </Row>
        <Row justify="center" style={{ margin: "16px" }}>
          <SearchProductBar filters={filters} />
        </Row>

        {isAPhone ? (
          <Row
            justify="space-between"
            align="middle"
            style={{
              margin: "16px 0px 16px 0px",
              padding: "0px 16px 0px 16px",
            }}
          >
            <Button
              type="primary"
              icon={<FilterOutlined />}
              onClick={onOpenFilterDrawer}
            >
              Filtra
            </Button>
            <ShopSorter />
          </Row>
        ) : (
          ""
        )}

        <Row gutter={{ lg: 0, xs: 0 }} justify="center">
          <Col style={{ marginRight: "12px" }} xxl={5} xl={6} lg={7} xs={0}>
            <Affix>
              <Card>
                <ShopFilter filters={filters} priceRange={price} />
                <Row justify="space-around" style={{ marginTop: 24 }}>
                  <Col>
                    <Button
                      style={{ color: "#8F8F8F" }}
                      type="dashed"
                      size="large"
                      onClick={() => dispatch(removeAllShopFiltersProducts())}
                    >
                      Cancella filtri
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Affix>
          </Col>
          <Col lg={16} xs={24}>
            {isAPhone ? (
              ""
            ) : (
              <Row justify="end" align="middle">
                <ShopSorter />
              </Row>
            )}
            <Row justify="center" style={{ width: "100%" }}>
              {isGettingShopProducts && <SkeletonShopCards />}
              {queriedProducts.length === 0 && (
                <Col span={24}>
                  <Result
                    icon={<FrownOutlined />}
                    title="Mi dispiace ma non trovo nulla!"
                  />
                </Col>
              )}
              {<QueriedProductShopCards />}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Shop;
