/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Result, Row, Spin } from "antd";
import React, { ReactElement, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { deleteCurrentCart } from "../../../redux/Cart/cart.actions";
import { FirestoreOrder } from "../../../types/firestore/order.firestore.type";
import Path from "../../../utils/pathConstants";

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const BackToTheShopButton = ({ history }) => (
  <Button type="primary" key="shop" onClick={() => history.push(Path.SHOP)}>
    Torna allo shop
  </Button>
);

interface Props {}

const CheckoutOnePageResult = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation<{ order: FirestoreOrder }>();
  const history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();

  const success = query.get("success") === "true" ? true : false;
  const orderID = query.get("orderID");
  const canceled = query.get("canceled") === "true" ? true : false;

  useEffect(() => {
    if (success) {
      dispatch(deleteCurrentCart());
    }
  }, [success]);

  let resultCard: ReactElement = <></>;
  if (success) {
    resultCard = (
      <Result
        status="success"
        title="Ordine confermato !"
        subTitle={`Codice ordine: ${orderID}`}
        extra={[<BackToTheShopButton key={"back-to-the-shop-button"} history={history} />]}
      />
    );
  } else if (canceled) {
    resultCard = (
      <Result
        status="error"
        title="Ordine non andato a buon fine ..."
        subTitle="Riprova più tardi"
        extra={[<BackToTheShopButton key={"back-to-the-shop-button"} history={history} />]}
      />
    );
  } else {
    resultCard = (
      <Result
        icon={<Spin indicator={antIcon} />}
        title="Stiamo prendendo in carico la tua richiesta!"
      />
    );
  }

  return (
    <div>
      <Row className="full-space-row centered" style={{ marginTop: "50px" }}>
        <Card style={{ width: "100%" }}>{resultCard}</Card>
      </Row>
    </div>
  );
};

export default CheckoutOnePageResult;
