import { Badge, Card, Col, List, Row, Typography } from "antd";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FirestoreShortProduct } from "../../../types/firestore/shortProduct.firestore.type";
import Path from "../../../utils/pathConstants";

interface Props {}

const QueriedProductShopCards = (props: Props) => {
  const history = useHistory();
  const { queriedProducts } = useSelector(
    (state: RootStateOrAny) => state.shop
  );
  const { active_occasions } = useSelector(
    (state: RootStateOrAny) => state.website.scheduled_occasions
  );

  const ProductCard = ({ product }: { product: FirestoreShortProduct }) => (
    <Card
      hoverable
      bodyStyle={{ padding: 0, lineHeight: 4, borderRadius: "25px" }}
      onClick={() => history.push(`${Path.PRODUCT}?id=${product?.productID}`)}
    >
      <div style={{ height: "300px" }}>
        <img
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRight: "10px solid #fff",
            borderTop: "10px solid #fff",
            borderLeft: "10px solid #fff",
          }}
          src={product?.image?.url}
          alt={product?.image?.uid}
        />
      </div>
      <Row
        style={{
          padding: "12px 12px 12px 12px",
          flexWrap: "nowrap",
        }}
        justify="space-between"
        align="middle"
      >
        <Col>
          <Typography.Paragraph
            style={{
              margin: 0,
              lineHeight: "initial",
              textAlign: "left",
              color: "#A1A1A1",
            }}
            ellipsis={{
              rows: 2,
            }}
          >
            {product?.name?.toLocaleUpperCase()}
          </Typography.Paragraph>
        </Col>
        <Col
          style={{
            fontWeight: 600,
            fontSize: "1.2em",
            lineHeight: 2,
            whiteSpace: "nowrap",
          }}
        >
          {`€ ${parseFloat(product?.price?.toString() || "").toFixed(2)}`}
        </Col>
      </Row>
    </Card>
  );

  return (
    <List
      grid={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 2,
        xl: 3,
        xxl: 4,
      }}
      dataSource={queriedProducts}
      renderItem={(product: FirestoreShortProduct) => (
        <List.Item style={{ margin: "18px" }}>
          {product?.available ? (
            [
              active_occasions.length > 0 &&
              product?.occasions?.includes(active_occasions[0].occasion) ? (
                <Badge.Ribbon
                  style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  key={product?.productID}
                  text={active_occasions[0].occasion.split("_").join(" ")}
                  color={active_occasions[0].color.hex}
                >
                  <ProductCard product={product} />
                </Badge.Ribbon>
              ) : (
                <ProductCard product={product} key={product?.productID} />
              ),
            ]
          ) : (
            <Badge.Ribbon
              style={{ textTransform: "uppercase", fontWeight: "bold" }}
              key={product?.productID}
              text="NON DISPONIBILE"
              color="black"
            >
              <ProductCard product={product} key={product?.productID} />
            </Badge.Ribbon>
          )}
        </List.Item>
      )}
    />
  );
};

export default QueriedProductShopCards;
