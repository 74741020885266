import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";

import { firebaseConfig, rrfConfig } from "./config";
import { createFirestoreInstance } from "redux-firestore";
import store from "../redux/createStore";
import config from "../config";

firebase.initializeApp(firebaseConfig);
export const rrfProps = {
  firebase,
  config: rrfConfig,
  createFirestoreInstance, // <- needed if using firestore
};

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const authConstants = firebase.auth;
export const firebaseStorage = firebase;
export const analytics: firebase.analytics.Analytics = firebase.analytics();

const configEnv: any = config;

export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: configEnv.domain + "/finishSignUp",
  // This must be true.
  handleCodeInApp: true,
};

export const handleUserProfile = async ({ userAuth, additionalData }) => {
  //console.debug("isFirebaseInitialize");
  if (!userAuth) return;
  const { uid } = userAuth;
  analytics.setUserId(uid);

  const userRef = firestore.doc(`users/${uid}`);
  const snapshot = await userRef.get();

  // if user doesn't exists
  if (!snapshot.exists) {
    const { displayName, email } = userAuth;
    const timestamp = new Date();

    let firstName = "";
    let lastName = "";

    if (displayName !== null) {
      let fullName = displayName.match(/\w+/g);
      firstName = fullName.shift();
      lastName = fullName.join(" ");
    }

    try {
      await userRef.set({
        first_name: firstName,
        last_name: lastName,
        email,
        created_at: timestamp,
        ...additionalData,
      });
    } catch (err) {
      console.error(err);
    }
  }

  return userRef;
};

export const getCurrentUser = async () => {
  const memStore = store.getState();
  if (memStore.firebase.auth?.uid !== undefined) {
    analytics.setUserId(memStore.firebase.auth?.uid);
    return memStore.firebase.auth;
  }
  return undefined;
};

export const isAdmin = async () => {
  const memStore = store.getState();
  if (memStore.firebase.auth?.uid === undefined) {
    return false;
  } else {
    const rolesRef = await firestore.collection("roles").doc("admins").get();
    const docData: any = rolesRef.data();
    if (docData?.userIDs.includes(memStore.firebase.auth.uid)) {
      return true;
    }
  }
  return false;
};
