import cartTypes from "./cart.types";

const INITIAL_STATE = {
  cartMap: new Map<string, any>(),
  cartArray: [],
  currentCart: [],
  itemsCounter: undefined,
  updateStatus: undefined,
  cartSubTotal: 0,
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case cartTypes.UPDATE_CART_ITEMS_COUNTER:
      return {
        ...state,
        itemsCounter: action.payload,
      };
    case cartTypes.CHANGE_UPDATE_CART_STATUS:
      return {
        ...state,
        updateStatus: action.payload,
      };

    case cartTypes.UPDATE_CART_SUB_TOTAL:
      return {
        ...state,
        cartSubTotal: action.payload,
      };

    case cartTypes.UPDATE_CART_ARRAY:
      return {
        ...state,
        cartArray: action.payload,
      };

    case cartTypes.UPDATE_CART_MAP:
      return {
        ...state,
        cartMap: action.payload,
      };

    case cartTypes.DELETE_CURRENT_CART_ACTION:
      return {
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default cartReducer;
