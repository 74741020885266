import { QuestionCircleOutlined } from "@ant-design/icons";
import { Divider, Form, Input, InputNumber, Rate, Tooltip } from "antd";
import React from "react";
import { productTypesConstants } from "../../../../utils";

interface Props {
  formItemLayout: any;
  productType: string;
}

const PlantDetails = ({ formItemLayout, productType }: Props) => {
  return (
    <>
      <Form.Item label="Prezzo" required>
        <div style={{ display: "flex", alignItems: "start" }}>
          <Form.Item
            name="price"
            noStyle
            rules={[{ required: true, message: "Inserisci il prezzo" }]}
          >
            <InputNumber
              //defaultValue={0}
              min={0}
              max={10000}
              formatter={(value) =>
                `€${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              // eslint-disable-next-line no-useless-escape
              parser={(value: any) => value.replace("€", "")}
            />
          </Form.Item>
        </div>
      </Form.Item>

      {productType === productTypesConstants.PLANT ? (
        <>
          <Divider>misure</Divider>

          <Form.Item
            {...formItemLayout}
            name="height_with_jar"
            label="Altezza (con vaso)"
          >
            <InputNumber
              //defaultValue={0}
              min={0}
              max={10000}
              formatter={(value) => `${value}cm`}
              parser={(value: any) => value.replace(/[a-zA-Z]+/g, "")}
            />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="diameter_with_jar"
            label="Diametro (con vaso)"
          >
            <InputNumber
              //defaultValue={0}
              min={0}
              max={10000}
              formatter={(value) => `${value}cm`}
              parser={(value: any) => value.replace(/[a-zA-Z]+/g, "")}
            />
          </Form.Item>

          <Divider>dettagli</Divider>

          <Form.Item
            name="difficulty"
            label={
              <span>
                Difficolta'&nbsp;
                <Tooltip title="Livello di difficolta' di cura della pianta">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Rate />
          </Form.Item>

          <Form.Item
            name="watering"
            label={
              <span>
                Irrigazione&nbsp;
                <Tooltip title="esempio: Ogni quanto dare acqua alla pianta">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input.TextArea placeholder="" />
          </Form.Item>

          <Form.Item
            name="humidity"
            label={
              <span>
                Umidita'&nbsp;
                <Tooltip title="esempio: In che tipo di ambiente posizionare la pianta">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input.TextArea placeholder="" />
          </Form.Item>

          <Form.Item
            name="lighting"
            label={
              <span>
                Illuminazione&nbsp;
                <Tooltip title="esempio: Dove posizionare la pianta rispetto al sole">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
          >
            <Input.TextArea placeholder="" />
          </Form.Item>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default PlantDetails;
