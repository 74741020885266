import { Button, Card, List, Row, Table, Typography } from "antd";
import moment from "antd/node_modules/moment";
import React from "react";
import { FirestoreCompleteOrder } from "../../../types/firestore/completeOrder.firestore.type";
import { getTradStatus } from "../../../utils/userUtils";
interface Props {
  loading: boolean;
  orders: FirestoreCompleteOrder[];
  isAPhone: boolean;
  loadOthers: () => void;
}

const MyOrdersTable = ({ loading, orders, isAPhone, loadOthers }: Props) => {
  const columns = isAPhone
    ? [
        { title: "Codice", dataIndex: "orderID", key: "orderID" },
        {
          title: "Data di consegna",
          dataIndex: "delivering_date",
          key: "delivering_date",
          render: (delivering_date) =>
            moment(delivering_date?.toDate()).format("DD MMM YYYY"),
        },
      ]
    : [
        { title: "Codice", dataIndex: "orderID", key: "orderID" },
        {
          title: "Stato",
          dataIndex: "status",
          key: "status",
          render: (status) => getTradStatus(status),
        },
        {
          title: "Data di consegna",
          dataIndex: "delivering_date",
          key: "delivering_date",
          render: (delivering_date) =>
            moment(delivering_date?.toDate()).format("DD MMM YYYY"),
        },
        {
          title: "Consegna",
          dataIndex: "sm_in_store_collection",
          key: "sm_in_store_collection",
          render: (sm_in_store_collection) => {
            if (sm_in_store_collection) {
              return "🏪 NEGOZIO";
            } else {
              return "🏡 A DOMICILIO";
            }
          },
        },
        {
          title: "Totale",
          dataIndex: "subtotal",
          key: "subtotal",
          render: (subtotal, record) =>
            `€ ${parseFloat(
              subtotal + (record?.shipping_cost ? record?.shipping_cost : 0)
            ).toFixed(2)}`,
        },
        {
          title: "Data ordine",
          dataIndex: "created_at",
          key: "created_at",
          render: (created_at) =>
            moment(created_at?.toDate()).format("DD MMM YYYY"),
        },
      ];

  return (
    <div className="user-orders-table">
      <Table
        loading={loading}
        columns={columns}
        rowKey="orderID"
        pagination={false}
        dataSource={orders}
        expandable={{
          expandedRowRender: (record: FirestoreCompleteOrder) => (
            <div>
              {isAPhone && (
                <div style={{ marginBottom: "12px" }}>
                  <Typography.Title level={5}>Informazioni</Typography.Title>
                  <Row className="full-space-between-row">
                    <Card>
                      {`Data ordine: ${moment(
                        record.delivering_date?.toDate()
                      ).format("DD MMM YYYY")}`}
                      <br />
                      {`Consegna: ${
                        record.sm_in_store_collection
                          ? "NEGOZIO 🏪"
                          : "A DOMICILIO 🏡"
                      }`}
                      <br />
                      {`Totale: ${`€ ${parseFloat(
                        (
                          record.subtotal +
                          (record?.shipping_cost ? record?.shipping_cost : 0)
                        ).toString()
                      ).toFixed(2)}`}`}
                      <br />
                      {`Stato: ${getTradStatus(record.status)}`}
                    </Card>
                  </Row>
                </div>
              )}
              <Row className="full-space-row" style={{ marginBottom: "12px" }}>
                <Typography.Title level={5}>Prodotti</Typography.Title>
                <Row className="full-space-between-row">
                  <List
                    style={{ width: "100%" }}
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 4,
                      lg: 4,
                      xl: 6,
                      xxl: 3,
                    }}
                    dataSource={record.products}
                    renderItem={(product) => (
                      <List.Item>
                        <Card key={product.productConcatenatedID}>
                          <a href={`/product?id=${product.productID}`}>
                            {" "}
                            {`${product.name} ${
                              product?.configuration !== undefined
                                ? `| ${product.configuration.type?.toLocaleUpperCase()}`
                                : ""
                            }`}
                          </a>
                          <br />
                          {`x ${product.quantity}`}
                          <br />
                          {`€ ${parseFloat(
                            (product.quantity * product.price).toString()
                          ).toFixed(2)}`}
                        </Card>
                      </List.Item>
                    )}
                  />
                </Row>
              </Row>
              <Row className="full-space-between-row">
                <div>
                  <Typography.Title level={5}>
                    Indirizzo di fatturazione{" "}
                    {!record.sm_in_store_collection &&
                      record.shipping &&
                      record.shipping.same_as_billing_address &&
                      "e spedizione"}
                  </Typography.Title>
                  <Row className="full-space-between-row">
                    <Card>
                      {record?.billing?.address?.street_name},{" "}
                      {record?.billing?.address?.street_number}
                      <br />
                      {record?.billing?.address?.city},{" "}
                      {record?.billing?.address?.zipcode}
                      <br />
                      {record?.billing?.address?.province}{" "}
                      {record?.billing?.address?.state}
                    </Card>
                  </Row>
                </div>
                {!record.sm_in_store_collection &&
                  record.shipping &&
                  !record.shipping.same_as_billing_address && (
                    <div>
                      <Typography.Title level={5}>
                        Indirizzo di spedizione
                      </Typography.Title>
                      <Row className="full-space-between-row">
                        <Card>
                          {record?.shipping?.address?.street_name},{" "}
                          {record?.shipping?.address?.street_number}
                          <br />
                          {record?.shipping?.address?.city},{" "}
                          {record?.shipping?.address?.zipcode}
                          <br />
                          {record?.shipping?.address?.province}{" "}
                          {record?.shipping?.address?.state}
                        </Card>
                      </Row>
                    </div>
                  )}
              </Row>
            </div>
          ),
        }}
      />
      <Row className="full-space-row centered" style={{ marginTop: "20px" }}>
        <Button type="primary" onClick={loadOthers} loading={loading}>
          CARICA ALTRI
        </Button>
      </Row>
    </div>
  );
};

export default MyOrdersTable;
