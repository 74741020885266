import React, { useState } from "react";
import { NavLink } from "react-router-dom";

interface Props {
  button: {
    text: string;
  };
  img: {
    src: string;
    optionalStyle?: object;
  };
}

const HomeCard = ({ button, img }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bright, setBright] = useState("brightness(95%)");

  const onMouseLeaveButton = () => {
    setBright("brightness(95%)");
  };

  return (
    <div>
      <img
        style={{
          ...img.optionalStyle,
        }}
        src={img.src}
        className="img-fluid card-image"
        alt=""
      />
      <NavLink
        to="/shop"
        className="button-light position-absolute"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          msTransform: "translate(-50%, -50%)",
          fontSize: "3em",
          position: "absolute",
        }}
        onMouseLeave={onMouseLeaveButton}
      >
        {button.text}
      </NavLink>
    </div>
  );
};

export default HomeCard;
