const cartTypes = {
  UPDATE_CART: "UPDATE_CART",
  CHANGE_UPDATE_CART_STATUS: "CHANGE_UPDATE_CART_STATUS",
  UPDATE_CART_ITEMS_COUNTER: "UPDATE_CART_ITEMS_COUNTER",
  UPDATE_ITEM_ON_CART: "UPDATE_ITEM_ON_CART",
  DELETE_ITEM_ON_CART: "DELETE_ITEM_ON_CART",
  UPDATE_CART_SUB_TOTAL: "UPDATE_CART_SUB_TOTAL",
  UPDATE_CART_ARRAY: "UPDATE_CART_ARRAY",
  UPDATE_CART_MAP: "UPDATE_CART_MAP",
  SET_RETRIEVED_CART_ARRAY: "SET_RETRIEVED_CART_ARRAY",
  DELETE_CURRENT_CART: "DELETE_CURRENT_CART",
  DELETE_CURRENT_CART_ACTION: "DELETE_CURRENT_CART_ACTION",
};

export default cartTypes;
