import { FirestoreUser } from "../../types/firestore/user.firestore.type";
import userTypes from "./user.types";

export const checkUserSession = () => ({
  type: userTypes.CHECK_USER_SESSION,
});

export const signInSuccess = (user) => ({
  type: userTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const googleSignInStart = () => ({
  type: userTypes.GOOGLE_SIGN_IN_START,
});

export const signInPasswordLessAction = (email) => ({
  type: userTypes.SIGN_IN_PL_USER_START,
  payload: email,
});

export const signInPLEmailSent = (email) => ({
  type: userTypes.SIGN_IN_PL_EMAIL_SENT,
  payload: email,
});

export const finishSignUpPasswordLess = (href) => ({
  type: userTypes.SIGN_UP_PL_FINISH_LOGIN,
  payload: href,
});

export const emailSignInStart = (userCredentials) => ({
  type: userTypes.EMAIL_SIGN_IN_START,
  payload: userCredentials,
});

export const loginError = (err) => ({
  type: userTypes.EMAIL_SIGN_IN_ERROR,
  payload: err,
});

export const removeError = () => ({
  type: userTypes.EMAIL_SIGN_IN_REMOVE_ERROR,
});

export const changeLoginStatus = (status) => ({
  type: userTypes.CHANGE_LOGIN_STATUS,
  payload: status,
});

export const changePasswordLessSignInStatus = (status) => ({
  type: userTypes.CHANGE_PL_SIGN_IN_STATUS,
  payload: status,
});

export const resetPasswordLessSignInStatus = () => ({
  type: userTypes.RESET_PL_SIGN_IN_STATUS,
});

export const getUserInfo = () => ({
  type: userTypes.GET_USER_INFO,
});

export const setGetUserInfoStatus = (status) => ({
  type: userTypes.SET_GET_USER_INFO_STATUS,
  payload: status,
});

export const setGetUserInfo = (userInfo) => ({
  type: userTypes.SET_GET_USER_INFO,
  payload: userInfo,
});

export const setGetUserInfoError = (error) => ({
  type: userTypes.SET_GET_USER_INFO_ERROR,
  payload: error,
});

export const updateUserInfo = (userInfo: FirestoreUser) => ({
  type: userTypes.UPDATE_USER_INFO,
  payload: userInfo,
});

export const updateUserInfoChangeStatus = (status) => ({
  type: userTypes.UPDATE_USER_INFO_CHANGE_STATUS,
  payload: status,
});

export const updateUserInfoSetError = (error) => ({
  type: userTypes.UPDATE_USER_INFO_SET_ERROR,
  payload: error,
});

export const signOutAction = () => ({
  type: userTypes.SIGN_OUT,
});

export const eraseUserDataAction = () => ({
  type: userTypes.ERASE_USER_DATA,
});

export const getUserOrderAction = (limit: number = 5) => ({
  type: userTypes.GET_USER_ORDER_ACTION,
  payload: limit,
});

export const setUserOrderAction = (results) => ({
  type: userTypes.SET_USER_ORDER_ACTION,
  payload: results,
});

export const setUserOrderFetchingAction = (fetching) => ({
  type: userTypes.SET_USER_ORDER_FETCHING_ACTION,
  payload: fetching,
});

export const setUserOrderErrorAction = (error) => ({
  type: userTypes.SET_USER_ORDER_ERROR_ACTION,
  payload: error,
});

export const setUserOrderLastSnapshotAction = (lastSnapshot) => ({
  type: userTypes.SET_USER_LAST_SNAPSHOT_ACTION,
  payload: lastSnapshot,
});
