import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { isEmpty } from "react-redux-firebase";
import { Route, Redirect } from "react-router-dom";
import Path from "../utils/pathConstants";

interface Props {
  component: any;
  exact?: any;
  path: string;
  restricted: boolean;
}

const PublicRoute = ({ component: Component, restricted, ...rest }: Props) => {
  const auth = useSelector((state: RootStateOrAny) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        !isEmpty(auth) && restricted ? (
          <Redirect to={Path.MY_ACCOUNT} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
