import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  message,
  Result,
  Row,
  Spin,
  Typography,
} from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import GoogleSignIn from "../../../components/SignIn/GoogleSignIn";
import {
  googleSignInStart,
  removeError,
  resetPasswordLessSignInStatus,
  signInPasswordLessAction,
} from "../../../redux/User/user.actions";
import PasswordLessSignIn from "../../../components/SignIn/PasswordLessSignIn";
import { requestStatus } from "../../../utils";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";

const { Title } = Typography;

const loginInitialState = {
  disabled: false,
};

interface Props {}

const Login = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loginState, setLoginState] = useState(loginInitialState);

  const {
    loginStatus,
    userErr: loginError,
    passwordLessSignInStatus,
  } = useSelector((state: RootStateOrAny) => state.user);

  useEffect(() => {
    return () => {
      dispatch(removeError());
      dispatch(resetPasswordLessSignInStatus());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (loginStatus) {
      case requestStatus.STARTED:
        setLoginState({
          ...loginState,
          disabled: true,
        });
        break;
      case requestStatus.COMPLETED:
        setLoginState({ ...loginState, disabled: false });
        break;
      case requestStatus.ERROR:
        message.error(loginError.message);
        setLoginState({ ...loginState, disabled: false });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  return (
    <>
      <Helmet>
        <title>Login | Le Clematis Fioreria</title>
        <meta
          name="description"
          content="Login Fioreria Le Clematis Galzignano Terme"
        />
      </Helmet>
      <Row justify="center">
        {passwordLessSignInStatus.emailSent ? (
          <Card style={{ marginTop: "50px" }}>
            <Result
              status="success"
              title="Email inviata!"
              subTitle={
                <div>
                  <Typography.Paragraph>
                    Ricordati di aprire la tua email e cliccare sul link
                  </Typography.Paragraph>
                  <br />
                  <Typography.Paragraph strong>
                    Chiudi pure la pagina
                  </Typography.Paragraph>
                </div>
              }
            />
          </Card>
        ) : (
          <Col xs={20} xxl={5} xl={6} lg={8} sm={12}>
            <Title level={3} style={{ marginTop: "50px" }}>
              ACCEDI
            </Title>
            <Card style={{ marginBottom: "24px" }}>
              <PasswordLessSignIn
                handleOnFinish={(values) =>
                  dispatch(signInPasswordLessAction(values.email))
                }
                disabled={loginState.disabled}
              />
              <Divider>oppure</Divider>
              <GoogleSignIn
                handleGoogleSignIn={() => dispatch(googleSignInStart())}
                disabled={loginState.disabled}
              />
              <Row
                className="full-space-row"
                style={{
                  marginTop: "12px",
                  justifyContent: "center",
                  alignItems: "baseline",
                  fontSize: "0.8em",
                }}
              >
                Leggi l'
                <span>
                  <Button
                    style={{ padding: 0, fontSize: "0.9em" }}
                    type="link"
                    onClick={() => history.push("/privacy-and-cookie-policy")}
                  >
                    Informativa sulla Privacy
                  </Button>
                </span>
              </Row>
              {loginState.disabled && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Spin
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    size="large"
                  />
                </div>
              )}
            </Card>
            {loginError?.message && (
              <Alert
                message="ERRORE"
                description={loginError?.message}
                type="error"
              />
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Login;
