import { BarsOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Button, Card, Row, Steps, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { RiTruckLine } from "react-icons/ri";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateOrderAction } from "../../../redux/Checkout/checkout.actions";
import { updateUserInfo } from "../../../redux/User/user.actions";
import {
  getAvailableCities,
  logAnalyticsEventAction,
} from "../../../redux/Website/website.actions";
import { FirestoreOrder } from "../../../types/firestore/order.firestore.type";
import {
  FirestoreAddress,
  FirestoreUser,
} from "../../../types/firestore/user.firestore.type";
import { getUUIDv4, isALocalTest, isATest, isLive } from "../../../utils";
import { updateProductsOnOrder } from "../../../utils/checkoutUtils";
import Path from "../../../utils/pathConstants";
import ConfirmStep from "./ConfirmStep";
import OverviewStep from "./OverviewStep";
import ShipmentStep from "./ShipmentStep";
import UpdateAddressModal from "./UpdateAddressModal";
import { Helmet } from "react-helmet";

const STEP = {
  OVERVIEW: "OVERVIEW",
  SHIPMENT: "SHIPMENT",
  CONFIRM: "CONFIRM",
};

interface Props {}

const Checkout = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { cartArray, itemsCounter, cartSubTotal } = useSelector(
    (state: RootStateOrAny) => state.cart
  );
  const { getUserInfo } = useSelector((state: RootStateOrAny) => state.user);
  const { auth } = useSelector((state: RootStateOrAny) => state.firebase);
  const { order } = useSelector((state: RootStateOrAny) => state.checkout);
  const { availableCities } = useSelector(
    (state: RootStateOrAny) => state.website
  );
  const { isInitializing: firebaseIsInitializing } = useSelector(
    (state: RootStateOrAny) => state.firebase
  );

  const [orderState, setOrderState] = useState<FirestoreOrder>(order);

  const [currentStep, setCurrentStep] = useState(0);

  const { nextAvailableMoment } = useSelector(
    (state: RootStateOrAny) => state.website.nextAvailableMoment
  );

  useEffect(() => {
    dispatch(getAvailableCities());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //CREATE ORDER ELEMENT
    if (cartArray !== undefined && currentStep < 1) {
      let newOrder = updateProductsOnOrder(
        cartArray,
        cartSubTotal,
        itemsCounter
      );
      newOrder = {
        ...newOrder,
        is_a_test: isATest(),
        is_a_local_test: isALocalTest(),
        is_live: isLive(),
        userID: auth?.uid,
      };
      setOrderState(newOrder);
      if (!firebaseIsInitializing) {
        dispatch(
          logAnalyticsEventAction({
            key: "begin_checkout",
            value: JSON.parse(JSON.stringify(newOrder)),
          })
        );
      }
      dispatch(updateOrderAction(newOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartArray, firebaseIsInitializing]);

  const [
    isUpdateBillingAddressModalVisible,
    setIsUpdateBillingAddressModalVisible,
  ] = useState(false);

  const updatedBillingAddress = (billingAddress: FirestoreAddress | any) => {
    let newCurrentUserInfo: FirestoreUser = Object.assign(
      {},
      getUserInfo?.userInfo
    );
    newCurrentUserInfo = {
      ...newCurrentUserInfo,
      first_name: billingAddress.first_name,
      last_name: billingAddress.last_name,
      billing_address: billingAddress,
      phone: billingAddress.phone,
    };
    dispatch(updateUserInfo(newCurrentUserInfo));
    setIsUpdateBillingAddressModalVisible(!isUpdateBillingAddressModalVisible);
  };

  const [
    isVisibleAddFavoriteAddressModal,
    setIsVisibleAddFavoriteAddressModal,
  ] = useState(false);
  const toggleAddFavoriteAddress = () => {
    setIsVisibleAddFavoriteAddressModal(!isVisibleAddFavoriteAddressModal);
  };

  const updateFavoriteAddress = (favAddress: FirestoreAddress) => {
    let newCurrentUserInfo: FirestoreUser = Object.assign(
      {},
      getUserInfo?.userInfo
    );
    favAddress.uid = getUUIDv4();
    newCurrentUserInfo.shipping_addresses =
      newCurrentUserInfo.shipping_addresses === undefined
        ? []
        : newCurrentUserInfo.shipping_addresses;
    newCurrentUserInfo = {
      ...newCurrentUserInfo,
      shipping_addresses: [
        ...newCurrentUserInfo.shipping_addresses,
        favAddress,
      ],
    };
    dispatch(updateUserInfo(newCurrentUserInfo));
    toggleAddFavoriteAddress();
  };

  const stepList = [
    {
      title: "Riepilogo",
      key: STEP.OVERVIEW,
      icon: <BarsOutlined />,
      status: "process",
      component: (
        <OverviewStep
          currentStep={currentStep}
          orderState={orderState}
          setOrderState={setOrderState}
          setCurrentStep={setCurrentStep}
          cartArray={cartArray}
          itemsCounter={itemsCounter}
          cartSubTotal={cartSubTotal}
          availableCities={availableCities.data}
          nextAvailableMoment={nextAvailableMoment}
        />
      ),
      isDisabled: false,
    },
    {
      title: "Consegna",
      key: STEP.SHIPMENT,
      icon: <RiTruckLine />,
      status: "process",
      component: (
        <ShipmentStep
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          orderState={orderState}
          setOrderState={setOrderState}
          userInfo={getUserInfo?.userInfo}
          updateBillingAddress={() =>
            setIsUpdateBillingAddressModalVisible(
              !isUpdateBillingAddressModalVisible
            )
          }
          availableCities={availableCities.data}
          toggleAddFavoriteAddress={toggleAddFavoriteAddress}
          nextAvailableMoment={nextAvailableMoment}
          firebaseIsInitializing={firebaseIsInitializing}
        />
      ),
      isDisabled: true,
    },
    {
      title: "Conferma",
      key: STEP.CONFIRM,
      icon: <FileDoneOutlined />,
      component: (
        <ConfirmStep
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          orderState={orderState}
          setOrderState={setOrderState}
          userInfo={getUserInfo?.userInfo}
          firebaseIsInitializing={firebaseIsInitializing}
        />
      ),
      isDisabled: true,
    },
  ];

  const handleStepChange = (current) => {
    setCurrentStep(current);
  };

  return (
    <>
      <Helmet>
        <title>Checkout | Le Clematis Fioreria</title>
        <meta
          name="description"
          content="Checkout Fioreria Le Clematis Galzignano Terme"
        />
      </Helmet>
      <UpdateAddressModal
        visible={isUpdateBillingAddressModalVisible}
        address={getUserInfo?.userInfo?.billing_address}
        phone={getUserInfo?.userInfo?.phone ? getUserInfo?.userInfo?.phone : ""}
        handleOk={updatedBillingAddress}
        handleCancel={() =>
          setIsUpdateBillingAddressModalVisible(
            !isUpdateBillingAddressModalVisible
          )
        }
      />
      <UpdateAddressModal
        visible={isVisibleAddFavoriteAddressModal}
        handleOk={updateFavoriteAddress}
        handleCancel={toggleAddFavoriteAddress}
      />
      <div className="checkout-class">
        {itemsCounter > 0 ? (
          <Row className="centered-row">
            <Row className="step-titles-row">
              <Steps
                current={currentStep}
                onChange={handleStepChange}
                direction="horizontal"
                size="small"
              >
                {stepList.map((item) => (
                  <Steps.Step
                    key={item.title}
                    title={item.title}
                    icon={item.icon}
                    disabled={item.isDisabled}
                  />
                ))}
              </Steps>
            </Row>
            <Row className="centered-row">
              <Card className="step-content-card" bodyStyle={{ width: "100%" }}>
                <div>{stepList[currentStep].component}</div>
              </Card>
            </Row>
          </Row>
        ) : (
          <Row
            style={{
              marginTop: "50px",
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography.Text style={{ marginBottom: "12px" }}>
              Il tuo carrello e' ancora vuoto
            </Typography.Text>
            <Row>
              <Button
                block
                onClick={() => {
                  history.push(Path.SHOP);
                }}
              >
                INIZIA LO SHOPPING
              </Button>
            </Row>
          </Row>
        )}
      </div>
    </>
  );
};

export default Checkout;
