import { GoogleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

interface Props {
  handleGoogleSignIn: () => void;
  loading?: boolean;
  disabled: boolean;
}

const GoogleSignIn = ({ handleGoogleSignIn, loading, disabled }: Props) => {
  return (
    <Button
      type="default"
      className="login-form-button"
      onClick={handleGoogleSignIn}
      icon={<GoogleOutlined />}
      loading={loading}
      disabled={disabled}
    >
      Accedi con Google
    </Button>
  );
};

export default GoogleSignIn;
