export const firebaseConfig = {
  apiKey: "AIzaSyDpnr5WoJ5roetPbOAZsauhfFCS9eab2cM",
  authDomain: "fiorerialeclematis.it",
  databaseURL: "https://fioreria-le-clematis.firebaseio.com",
  projectId: "fioreria-le-clematis",
  storageBucket: "fioreria-le-clematis.appspot.com",
  messagingSenderId: "989042369573",
  appId: "1:989042369573:web:7bf210191e2c1269478bcc",
  measurementId: "G-0C9PLP5GNN",
};

export const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
}
