import userTypes from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  resetPasswordSuccess: false,
  userErr: [],
  loginStatus: undefined,
  passwordLessSignInStatus: {
    emailSent: false,
  },
  getUserInfo: {
    status: null,
    userInfo: {},
    error: null,
  },
  orders: {
    fetching: false,
    orders: [],
    error: null,
    total_orders: null,
    last_snapshot: undefined,
  },
  updateUserInfo: {
    status: null,
    error: null,
  },
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        userErr: [],
      };
    case userTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload,
      };
    case userTypes.USER_ERROR:
      return {
        ...state,
        userErr: action.payload,
      };
    case userTypes.RESET_USER_STATE:
    case userTypes.SIGN_OUT_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case userTypes.EMAIL_SIGN_IN_ERROR:
      return {
        ...state,
        userErr: action.payload,
      };
    case userTypes.EMAIL_SIGN_IN_REMOVE_ERROR:
      return {
        ...state,
        userErr: null,
      };
    case userTypes.CHANGE_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload,
      };
    case userTypes.CHANGE_PL_SIGN_IN_STATUS:
      return {
        ...state,
        passwordLessSignInStatus: {
          ...state.passwordLessSignInStatus,
          ...action.payload,
        },
      };
    case userTypes.RESET_PL_SIGN_IN_STATUS:
      return {
        ...state,
        passwordLessSignInStatus: INITIAL_STATE.passwordLessSignInStatus,
      };
    case userTypes.SET_GET_USER_INFO_STATUS:
      return {
        ...state,
        getUserInfo: { ...state.getUserInfo, status: action.payload },
      };
    case userTypes.SET_GET_USER_INFO:
      return {
        ...state,
        getUserInfo: {
          ...state.getUserInfo,
          userInfo: { ...state.getUserInfo.userInfo, ...action.payload },
        },
      };
    case userTypes.SET_GET_USER_INFO_ERROR:
      return {
        ...state,
        getUserInfo: { ...state.getUserInfo, error: action.payload },
      };
    case userTypes.UPDATE_USER_INFO_CHANGE_STATUS:
      return {
        ...state,
        updateUserInfo: { ...state.updateUserInfo, status: action.payload },
      };
    case userTypes.UPDATE_USER_INFO_SET_ERROR:
      return {
        ...state,
        updateUserInfo: { ...state.updateUserInfo, error: action.payload },
      };

    case userTypes.SET_USER_ORDER_ACTION:
      return {
        ...state,
        orders: {
          ...state.orders,
          orders: action.payload,
        },
      };
    case userTypes.SET_USER_ORDER_FETCHING_ACTION:
      return {
        ...state,
        orders: {
          ...state.orders,
          fetching: action.payload,
        },
      };
    case userTypes.SET_USER_ORDER_ERROR_ACTION:
      return {
        ...state,
        orders: {
          ...state.orders,
          error: action.payload,
        },
      };
    case userTypes.SET_USER_LAST_SNAPSHOT_ACTION:
      return {
        ...state,
        orders: {
          ...state.orders,
          last_snapshot: action.payload,
        },
      };
    case userTypes.ERASE_USER_DATA:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default userReducer;
