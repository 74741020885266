import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Switch,
  Button,
  Row,
  Typography,
  Input,
  message,
  Upload,
  Card,
  PageHeader,
  Checkbox,
  Image,
} from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import PlantDetails from "./PlantDetails";
import BouquetDetails from "./BouquetDetails";
import { UploadOutlined } from "@ant-design/icons";
import {
  getExistingProduct,
  startUpdateUploadingProduct,
  startUploadingProduct,
  storeProductImage,
  updateImageFormProductData,
} from "../../../../redux/Products/products.actions";
import Compressor from "compressorjs";
import { fromProductToFirestore } from "../../../../utils/productUtils";
import { useHistory, useLocation } from "react-router-dom";
import { FirestoreProduct } from "../../../../types/firestore/product.firestore.type";
import { productTypesConstants } from "../../../../utils";

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};

const { Title } = Typography;

interface Props {
  match?: any;
}

const buttonInitialState = {
  loading: false,
  disabled: false,
};

const status = {
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
};

const AddProduct = (props: Props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const productState = useSelector((state: RootStateOrAny) => state.products);
  const {
    productUploadStatus,
    imageList,
    formProductData,
    productGetStatus,
  } = productState;
  const [productType, setProductType] = useState("");
  const [addProductButton, setAddProductButton] = useState(buttonInitialState);
  const [addOrUpdate, setAddOrUpdate] = useState({
    mode: "CREATE",
    pageTitle: "Aggiungi prodotto",
    submitButtonTitle: "Aggiungi",
  });

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const uid = query.get("uid");

  const dispatch = useDispatch();
  let tmpImages: Array<any> = formProductData?.firestoreImages;
  useEffect(() => {
    switch (productUploadStatus) {
      case status.STARTED:
        setAddProductButton({ loading: true, disabled: true });
        break;
      case status.COMPLETED:
        setAddProductButton(buttonInitialState);
        if (uid) {
          message.success("Prodotto aggiornato");
        } else {
          message.success("Prodotto inserito");
        }
        form.resetFields();
        break;
      case status.ERROR:
        setAddProductButton(buttonInitialState);
        message.error("Errore nell'inserimento ");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productUploadStatus]);

  useEffect(() => {
    switch (productGetStatus) {
      case status.STARTED:
        break;
      case status.COMPLETED:
        form.setFieldsValue(formProductData);
        setProductType(formProductData?.type);
        break;
      case status.ERROR:
        break;
      default:
        if (uid !== undefined && uid != null) {
          dispatch(getExistingProduct(uid));
          setAddOrUpdate({
            mode: "UPDATE",
            pageTitle: "Aggiorna prodotto",
            submitButtonTitle: "Aggiorna",
          });
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productGetStatus]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = async (file) => {
    new Compressor(file, {
      maxWidth: 1200,
      success(result) {
        let quality = 1;
        quality =
          result.size > 100000 ? (quality * 100000) / result.size : quality;
        quality = parseFloat(quality.toFixed(1));
        new Compressor(result, {
          quality: quality,
          success(blob: Blob) {
            const compressedImage = { blob, uid: file?.uid };
            dispatch(storeProductImage(compressedImage));
          },
        });
      },
    });
    return "UPLOADED";
  };

  const onFinish = (values) => {
    const productInfo: {
      imageBlobList: Array<Blob>;
      product: FirestoreProduct;
    } = fromProductToFirestore(values, imageList);
    if (uid) {
      dispatch(startUpdateUploadingProduct({ ...productInfo, tmpImages, uid }));
    } else {
      dispatch(startUploadingProduct(productInfo));
    }
  };

  const products = useSelector(
    (state: RootStateOrAny) => state.website.products
  );

  const onChangePrimaryImage = (changedImage) => {
    tmpImages = tmpImages.map((image) => {
      if (changedImage.uid === image.uid) {
        return { ...image, is_main_image: !image.is_main_image };
      }
      return image;
    });
    dispatch(updateImageFormProductData({ firestoreImages: tmpImages }));
  };

  const onClickDeleteImage = (deletedImage) => {
    tmpImages = tmpImages.filter((image) => deletedImage.uid !== image.uid);
    dispatch(updateImageFormProductData({ firestoreImages: tmpImages }));
  };

  return (
    <div>
      <PageHeader
        style={{ padding: 0, fontSize: "medium" }}
        onBack={() => history.push("/admin/products")}
        title=""
        subTitle="prodotti"
      />
      <Row style={{ marginBottom: "20px" }}>
        <Title level={3}>{addOrUpdate.pageTitle}</Title>
      </Row>
      <Card style={{ width: "700px" }}>
        <Form
          name="validate_other"
          form={form}
          size="small"
          scrollToFirstError={true}
          //style={{ maxWidth: "1000px" }}
          {...formItemLayout}
          onFinish={onFinish}
          initialValues={{
            available: true,
          }}
        >
          {uid ? (
            <div style={{ display: "flex", marginBottom: "20px" }}>
              {tmpImages?.map((image) => {
                return (
                  <Card
                    key={image?.uid}
                    style={{ width: 150, marginRight: "12px" }}
                    cover={<Image alt={image?.uid} src={image?.url} />}
                  >
                    <div>
                      <Checkbox
                        defaultChecked={image?.is_main_image}
                        onChange={() => onChangePrimaryImage(image)}
                        style={{ marginBottom: "12px" }}
                      >
                        primaria
                      </Checkbox>
                      <Button onClick={() => onClickDeleteImage(image)}>
                        ELIMINA
                      </Button>
                    </div>
                  </Card>
                );
              })}
            </div>
          ) : (
            ""
          )}
          <Form.Item
            name="images"
            label="Immagini"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={
              uid
                ? undefined
                : [
                    {
                      required: true,
                      message: "Inserisci almeno una immagine",
                    },
                  ]
            }
          >
            <Upload name="logo" action={beforeUpload}  listType="picture">
              <Button icon={<UploadOutlined />}>Clicca per inserire</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="name"
            label="Nome"
            rules={[
              {
                required: true,
                message: "Inserisci nome prodotto",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Inserisci nome prodotto" />
          </Form.Item>

          <Form.Item
            name="type"
            label="Tipologia"
            rules={[{ required: true, message: "Seleziona la tipologia" }]}
            hasFeedback
          >
            <Select
              placeholder="Seleziona la tipologia"
              onChange={(value: any) => setProductType(value)}
            >
              {products.types.map((type) => (
                <Option value={type.value}>{type.label.toUpperCase()}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="occasions"
            label="Occasioni (multiplo)"
            rules={[
              {
                required: false,
                message: "Seleziona le varie occasioni",
                type: "array",
              },
            ]}
          >
            <Select mode="multiple" placeholder="Seleziona le varie occasioni">
              {products.occasions.map((occ) => {
                const value = occ.match(/\w+/g).join("_");
                return <Option value={value}>{occ.toUpperCase()}</Option>;
              })}
            </Select>
          </Form.Item>

          {uid ? (
            ""
          ) : (
            <Form.Item
              name="available"
              label="Disponibile"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          )}

          <Form.Item name="description" label="Descrizione">
            <Input.TextArea placeholder="Inserisci la descrizione del prodotto" />
          </Form.Item>

          {productType === productTypesConstants.PLANT || productType === productTypesConstants.OTHER ? (
            <PlantDetails
              formItemLayout={formItemLayout}
              productType={productType}
            />
          ) : (
            <BouquetDetails
              form={form}
              formFieldsValues={formProductData}
              productType={productType}
            />
          )}

          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={addProductButton.loading}
              disabled={addProductButton.disabled}
            >
              {addOrUpdate.submitButtonTitle.toUpperCase()}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AddProduct;
