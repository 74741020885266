import { Button, Card, Divider, Row, Tag, Typography } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import QuantityButton from "../../../components/QuantityButton/QuantityButton";
import { FirestoreProduct } from "../../../types/firestore/product.firestore.type";
import ProductDetailCardBouquet from "./ProductDetailCardBouquet";
import ProductDetailCardPlant from "./ProductDetailCardPlant";

interface Props {
  isProductLoading: boolean;
  product: FirestoreProduct;
  typeSelected: string;
  selectedProduct: any;
  setTypeSelected: Dispatch<SetStateAction<string>>;
  setSelectedProduct: Dispatch<SetStateAction<any>>;
  handleClickOnAddToCart: Dispatch<SetStateAction<any>>;
  todayMoment?: moment.Moment;
}

const ProductDetailCard = ({
  isProductLoading,
  product,
  typeSelected,
  setTypeSelected,
  setSelectedProduct,
  selectedProduct,
  handleClickOnAddToCart,
}: Props) => {
  const onChangeQuantity = (num: number) => {
    //console.debug(selectedProduct);
    if (
      selectedProduct?.quantity + num >= 1 &&
      selectedProduct?.quantity < 100
    ) {
      setSelectedProduct({
        ...selectedProduct,
        quantity: selectedProduct?.quantity + num,
      });
    }
  };

  return (
    <Card loading={isProductLoading} bordered={false}>
      <Row>
        <Typography.Title
          style={{
            fontWeight: 700,
            letterSpacing: "0.05em",
            textAlign: "start",
            marginBottom: "8px",
            width: "100%",
          }}
          level={2}
        >
          {product?.name?.toLocaleUpperCase()}
        </Typography.Title>
      </Row>
      <Row style={{ marginTop: "12px" }}>
        <Typography.Paragraph style={{ textAlign: "start" }}>
          {product?.details?.description}
        </Typography.Paragraph>
      </Row>
      {product?.is_a_plant && (
        <>
          <Divider style={{ margin: 0 }} />
          <ProductDetailCardPlant product={product} />
        </>
      )}
      {(product?.is_a_bouquet || product?.is_a_single_flower) && (
        <>
          <Divider style={{ margin: 0 }} />
          <ProductDetailCardBouquet
            product={product}
            typeSelected={typeSelected}
            setTypeSelected={setTypeSelected}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
          />
        </>
      )}
      <Divider style={{ margin: "12px 0 0 0" }} />
      <Row style={{ marginTop: "12px", width: "100%" }} justify="end">
        <Typography.Text style={{ color: "#676767" }}>
          {`${selectedProduct?.quantity} x € ${parseFloat(
            selectedProduct?.price?.toString()
          ).toFixed(2)}`}
        </Typography.Text>
      </Row>
      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <QuantityButton
          quantity={selectedProduct?.quantity}
          clickHandler={onChangeQuantity}
        />
        <div style={{ textAlign: "end" }}>
          <Typography.Text
            style={{ fontSize: "2.5em" }}
            strong
          >{`€ ${parseFloat(
            (selectedProduct?.price * selectedProduct?.quantity).toString()
          ).toFixed(2)}`}</Typography.Text>
        </div>
      </Row>
      <Row
        style={{ marginTop: "12px", width: "100%" }}
        justify="space-between"
        align="middle"
      >
        {selectedProduct.available ? (
          <Button
            className="button-dark-inverse"
            style={{ width: "100%", fontSize: "1.2em", height: "40px" }}
            onClick={handleClickOnAddToCart}
          >
            <span style={{ width: "100%" }}>AGGIUNGI AL CARRELLO</span>
          </Button>
        ) : (
          <Button
            className="button-dark-inverse"
            style={{ width: "100%", fontSize: "1.2em", height: "40px" }}
            disabled
          >
            <span style={{ width: "100%" }}>NON DISPONIBILE</span>
          </Button>
        )}
      </Row>
      <Row style={{ marginTop: "12px" }}>
        {product?.occasions?.map((occasion, index) => (
          <Tag
            style={{
              margin: "4px 4px 0 0",
              fontSize: "0.6em",
              borderRadius: "25px",
            }}
            key={index}
            color="#07b07a"
          >
            {occasion?.split("_").join(" ").toLocaleUpperCase()}
          </Tag>
        ))}
      </Row>
    </Card>
  );
};

export default ProductDetailCard;
