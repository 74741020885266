import { CartProduct } from "../types/cartProduct.type";
import {
  FirestoreOrder,
  OrderProduct,
} from "../types/firestore/order.firestore.type";
import objectMapper from "object-mapper";

export const checkoutConstant = {
  SM_DELIVERED: "SM_DELIVERED",
  SM_IN_STORE_COLLECTION: "SM_IN_STORE_COLLECTION",
  PM_IN_STORE_PAYMENT: "PM_IN_STORE_PAYMENT",
  PM_ONLINE_PAYMENT: "PM_ONLINE_PAYMENT",
  PM_IS_STRIPE_PAYMENT: "PM_IS_STRIPE_PAYMENT",
  PM_IS_PAYPAL_PAYMENT: "PM_IS_PAYPAL_PAYMENT",
};

const ORDER_CART_MAPPER = {
  productID: "productID",
  productCartKey: "productConcatenatedID",
  name: "name",
  price: "price",
  quantity: "quantity",
  currency: "currency",
  "image.uid": "image.uid",
  "image.url": "image.url",
  "configuration.price": "configuration.price",
  "configuration.stems_number": "configuration.stems_number",
  "configuration.type": "configuration.type",
};

/**
 * This method is used to map the cartProduct on orderProducts
 * @param cartArray
 * @param cartSubTotal
 * @param itemsCounter
 */
export const updateProductsOnOrder = (
  cartArray: CartProduct[],
  cartSubTotal: number,
  itemsCounter: number
): FirestoreOrder => {
  let firestoreOrder = {} as FirestoreOrder;
  const orderProducts = [] as OrderProduct[];
  cartArray.forEach((cartItem) => {
    let orderProduct = {} as OrderProduct;
    orderProduct = objectMapper(cartItem, ORDER_CART_MAPPER);
    orderProducts.push(orderProduct);
  });
  firestoreOrder.products = orderProducts;
  firestoreOrder.subtotal = cartSubTotal;
  firestoreOrder.items_counter = itemsCounter;
  return firestoreOrder;
};
