import productsTypes from "./products.types";

const INITIAL_STATE = {
  imageList: [],
  loadingProduct: false,
  productUploadStatus: "",
  productDeletionStatus: "",
  productUpdatingStatus: "",
  productGetStatus: "",
  hasData: true,
  productData: null,
  productsList: [],
  isLoadingProductsList: false,
  formProductData: {},
  plainProductData: undefined,
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case productsTypes.STORE_PRODUCT_IMAGE:
      const imageName = action.payload?.uid;
      const newImage = {
        uid: imageName,
        name: `${imageName}.jpg`,
        status: "stored",
        url: "",
        blob: action.payload?.blob,
      };
      return {
        ...state,
        imageList: [...state.imageList, newImage],
      };

    case productsTypes.CHANGE_UPLOAD_PRODUCT_STATUS:
      return {
        ...state,
        productUploadStatus: action.payload,
      };

    case productsTypes.CHANGE_DELETION_PRODUCT_STATUS:
      return {
        ...state,
        productDeletionStatus: action.payload,
      };

    case productsTypes.CHANGE_UPDATING_PRODUCT_STATUS:
      return {
        ...state,
        productUpdatingStatus: action.payload,
      };

    case productsTypes.CHANGE_PRODUCTS_LIST_STATUS:
      return {
        ...state,
        isLoadingProductsList: action.payload,
      };

    case productsTypes.CHANGE_GET_PRODUCT_STATUS:
      return {
        ...state,
        productGetStatus: action.payload,
      };

    case productsTypes.SET_PRODUCTS_LIST:
      return {
        ...state,
        hasData: true,
        productsList: action.payload,
      };

    case productsTypes.ADD_FORM_PRODUCT_DATA:
      return {
        ...state,
        formProductData: action.payload,
      };

    case productsTypes.RESET_FORM_PRODUCT_DATA:
      return {
        ...state,
        formProductData: null,
        productGetStatus: "",
      };

    case productsTypes.UPDATE_IMAGE_FORM_PRODUCT_DATA:
      return {
        ...state,
        formProductData: {
          ...state.formProductData,
          firestoreImages: action.payload?.firestoreImages,
        },
      };

    case productsTypes.GET_PLAIN_PRODUCT_DATA:
      return {
        ...state,
        plainProductData: action.payload,
      };

    case productsTypes.RESET_PLAIN_PRODUCT_DATA:
      return {
        ...state,
        plainProductData: undefined,
      };

    default:
      return state;
  }
};

export default productsReducer;
