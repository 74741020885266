import { CartProduct } from "../types/cartProduct.type";

export const generateCartKey = (product: CartProduct): string => {
  let response = "";
  response += product.productID;
  response += product?.configuration?.type
    ? `_${product?.configuration?.type}`
    : "";
  return response;
};

export const getCartMapToArray = (
  cartMap: Map<string | undefined, CartProduct>
): CartProduct[] => {
  const response: CartProduct[] = [];

  cartMap.forEach((value, key) => {
    let cartProduct = {} as CartProduct;
    cartProduct = value;
    cartProduct.productCartKey = key;
    response.push(cartProduct);
  });

  return response;
};

export const getCartArrayToMap = (
  cartArray: CartProduct[]
): Map<string | undefined, CartProduct> => {
  const response = new Map<string | undefined, CartProduct>();
  cartArray.forEach((item) => response.set(item.productCartKey, item));
  return response;
};
