import { Button, Space, Table, Tag, Typography } from "antd";
import moment from "antd/node_modules/moment";
import React from "react";
import { useDispatch } from "react-redux";
import { FirestoreCompleteOrder } from "../../../types/firestore/completeOrder.firestore.type";
import { getTradStatus, nextActions } from "../../../utils/userUtils";
import { updateOrderStatus } from "../../../redux/Website/website.actions";
import OrdersExpandedRow from "./OrdersExpandedRow";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

interface Props {
  orders: FirestoreCompleteOrder[];
  loading: boolean;
  tableTitle: string;
}

const OrdersTable = ({ tableTitle, orders, loading }: Props) => {
  const dispatch = useDispatch();
  const columns = [
    { title: "Codice", dataIndex: "orderID", key: "orderID" },
    {
      title: "Stato",
      dataIndex: "status",
      key: "status",
      render: (status) => getTradStatus(status),
    },
    {
      title: "Data di consegna",
      dataIndex: "delivering_date",
      key: "delivering_date",
      render: (delivering_date) =>
        moment(delivering_date?.toDate()).format("DD MMM YYYY"),
    },
    {
      title: "Totale",
      dataIndex: "subtotal",
      key: "subtotal",
      render: (subtotal, record) =>
        `€ ${parseFloat(
          subtotal + (record?.shipping_cost ? record?.shipping_cost : 0)
        ).toFixed(2)}`,
    },
    {
      title: "Data ordine",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) =>
        moment(created_at?.toDate()).format("DD MMM YYYY"),
    },
    {
      title: "Pagato",
      dataIndex: "is_paid",
      key: "is_paid",
      render: (is_paid, record: FirestoreCompleteOrder) =>
        record.pm_in_store_payment ? (
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            PAGAMENTO IN NEGOZIO
          </Tag>
        ) : is_paid ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            PAGATO
          </Tag>
        ) : (
          <Tag icon={<CloseCircleOutlined />} color="error">
            NON PAGATO
          </Tag>
        ),
    },
    {
      title: "Azioni",
      key: "action",
      render: (text, record: FirestoreCompleteOrder) => (
        <Space size="middle">
          {nextActions(
            record.status,
            record?.sm_in_store_collection,
            record.is_paid
          ).map((action, index) => (
            <Button
              key={index}
              type={action.isPrimary ? "primary" : "default"}
              danger={action?.isDanger}
              onClick={() =>
                dispatch(
                  updateOrderStatus({ ...record, status: action.status })
                )
              }
            >
              {getTradStatus(action.status)}
            </Button>
          ))}
        </Space>
      ),
    },
  ];
  return (
    <div>
      <Table
        title={() => (
          <Typography.Title level={5}>{tableTitle}</Typography.Title>
        )}
        rowKey="orderID"
        loading={loading}
        columns={columns}
        dataSource={orders}
        pagination={false}
        expandable={{
          expandedRowRender: (record: FirestoreCompleteOrder) => (
            <OrdersExpandedRow record={record} />
          ),
        }}
      />
    </div>
  );
};

export default OrdersTable;
