import { FirestoreOrder } from "../../types/firestore/order.firestore.type";
import checkoutTypes from "./checkout.types";

export const updateOrderAction = (order) => ({
  type: checkoutTypes.UPDATE_ORDER,
  payload: order,
});

export const createOrder = (order: FirestoreOrder) => ({
  type: checkoutTypes.CREATE_ORDER,
  payload: order,
});

export const updateCreateOrderStatus = (status: string) => ({
  type: checkoutTypes.UPDATE_CREATE_ORDER_STATUS,
  payload: status,
});

export const setCreateOrderResponse = (data) => ({
  type: checkoutTypes.SET_CREATE_ORDER_RESPONSE,
  payload: data,
});
