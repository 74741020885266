import {
  AppstoreAddOutlined,
  BarChartOutlined,
  DashboardOutlined,
  SettingOutlined,
  ShopOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import Path from "../utils/pathConstants";

const { Sider } = Layout;

const pathData = {
  "/admin": "admin_dashboard",
  "/admin/dashboard": "admin_dashboard",
  "/admin/orders": "admin_orders",
  "/admin/products": "admin_products",
  "/admin/products/add-product": "admin_products",
  "/admin/products/update-product": "admin_products",
  "/admin/accessories": "admin_accessories",
  "/admin/configurations": "configurations",
};

/**
 * Map the current location to the entry in the menu in order to lit it up
 * @param location the relative path of URL
 */
const mapLocationToKey = (pathname: string) => {
  let subPaths: string[] | null = pathname.split("/").filter((ss) => ss !== "");
  let found: boolean = false;
  let subPath = "/";
  while (subPaths && subPaths?.length > 0 && !found) {
    const concatPaths: string = "/" + subPaths?.join("/");
    if (pathData[concatPaths] !== undefined) {
      found = true;
      subPath = concatPaths;
    } else {
      subPaths?.pop();
    }
  }
  return [pathData[subPath]];
};

interface Props {}

const AdminDrawer = (props: Props) => {
  const history = useHistory();
  return (
    <Sider className="site-layout-background">
      <Menu
        mode="inline"
        defaultSelectedKeys={mapLocationToKey(history.location.pathname)}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item key="admin_dashboard" icon={<DashboardOutlined />} disabled>
          <NavLink to={`${Path.ADMIN}/dashboard`}>Dashboard</NavLink>
        </Menu.Item>
        <Menu.Item key="admin_orders" icon={<ShopOutlined />}>
          <NavLink to={`${Path.ADMIN}/orders`}>Ordini</NavLink>
        </Menu.Item>
        <Menu.Item key="admin_stats" icon={<BarChartOutlined />} disabled>
          <NavLink to={`${Path.ADMIN}/stats`}>Statistiche</NavLink>
        </Menu.Item>
        <Menu.Item key="admin_products" icon={<ShoppingOutlined />}>
          <NavLink to={`${Path.ADMIN}/products`}>Prodotti</NavLink>
        </Menu.Item>
        <Menu.Item
          key="admin_accessories"
          icon={<AppstoreAddOutlined />}
          disabled
        >
          <NavLink to={`${Path.ADMIN}/accessories`}>Accessori</NavLink>
        </Menu.Item>
        <Menu.Item key="configurations" icon={<SettingOutlined />}>
          <NavLink to={`${Path.ADMIN}/configurations`}>Configurazioni</NavLink>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default AdminDrawer;
