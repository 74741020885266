import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Badge } from "antd";
import { FiShoppingCart } from "react-icons/fi";
import { RiMenu4Fill } from "react-icons/ri";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  toggleCartDrawer,
  toggleDrawer,
} from "../../redux/Website/website.actions";

interface Props {}

const Navbar = (props: Props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { itemsCounter } = useSelector((state: RootStateOrAny) => state.cart);

  const itemColor = pathname === "/" ? "#ffffff" : "#303030";

  let navbarStyle = "navbar";

  if (pathname !== "/") {
    navbarStyle = "navbar-sticky";
  }

  const openDrawer = () => {
    dispatch(toggleDrawer());
  };

  const openCartDrawer = () => {
    dispatch(toggleCartDrawer());
  };

  return (
    <div className={navbarStyle}>
      <div className="navbar-left">
        <RiMenu4Fill
          className="button-icon"
          color={itemColor}
          size="1.5em"
          onClick={openDrawer}
        />
        <div>
          <NavLink to="/">
            <img
              src={
                pathname === "/"
                  ? "/assets/logo/logo-white.svg"
                  : "/assets/logo/logo.svg"
              }
              width="40%"
              alt=""
              loading="lazy"
            />
          </NavLink>
        </div>
      </div>

      <div className="navbar-right">
        {pathname === "/" ? (
          ""
        ) : (
          <Badge count={itemsCounter} overflowCount={99} offset={[-45, 10]}>
            <FiShoppingCart
              className="button-icon"
              color={itemColor}
              size="1.5em"
              onClick={openCartDrawer}
            />
          </Badge>
        )}
      </div>
    </div>
  );
};

export default Navbar;
