import { Button, Col, Form, Input, Modal, Row } from "antd";
import React from "react";
import { FirestoreAddress } from "../../../types/firestore/user.firestore.type";
import { getUUIDv4 } from "../../../utils";

interface Props {
  visible: boolean;
  handleOk: (favouriteAddresses: FirestoreAddress[] | undefined) => void;
  handleCancel: () => void;
  isUserInfoLoading: boolean;
  currentAddresses: FirestoreAddress[] | undefined;
}

const MyInfoAddFavouriteAddressModal = ({
  visible,
  handleOk,
  handleCancel,
  isUserInfoLoading,
  currentAddresses,
}: Props) => {
  const [addressForm] = Form.useForm();

  const onFinish = () => {
    addressForm
      .validateFields()
      .then((addressFormValues: any) => {
        if (currentAddresses === undefined) {
          currentAddresses = [];
        }
        let newFirestoreAddress: FirestoreAddress = Object.assign(
          {},
          addressFormValues
        );
        newFirestoreAddress.uid = getUUIDv4();
        currentAddresses?.push(newFirestoreAddress);
        addressForm.resetFields();
        handleOk(currentAddresses);
      })
      .catch((error) => {
        console.error("Validate Failed:", error);
      });
  };

  return (
    <Modal
      className="my-info-modal"
      title="Aggiungi indirizzo"
      visible={visible}
      closable={false}
      maskClosable={false}
      width={700}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Annulla
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onFinish}
          loading={isUserInfoLoading}
        >
          Salva
        </Button>,
      ]}
    >
      <Form
        form={addressForm}
        size="small"
        scrollToFirstError={true}
        labelCol={{ span: 5 }}
      >
        <Form.Item
          label="Destinatario"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Row justify="space-between">
            <Col xs={11}>
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: "Inserisci il nome",
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="Nome" />
              </Form.Item>
            </Col>
            <Col xs={11}>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: "Inserisci il cognome",
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="Cognome" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Indirizzo"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Row justify="space-between">
            <Col xs={16}>
              <Form.Item
                name="street_name"
                rules={[
                  {
                    required: true,
                    message: "Inserisci l'indirizzo",
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="Via/Viale/Piazza" />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                name="street_number"
                rules={[
                  {
                    required: true,
                    message: "Inserisci il numero civico",
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="N. Civico" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          label="Città"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Row justify="space-between">
            <Col xs={14}>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Inserisci la città",
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="Città" />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item
                name="zipcode"
                rules={[
                  {
                    required: true,
                    message: "Inserisci il CAP",
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="CAP" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          label="Provincia"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Row justify="space-between">
            <Col xs={6}>
              <Form.Item
                name="province"
                rules={[
                  {
                    required: false,
                    message: "Inserisci la provincia",
                    len: 2,
                  },
                ]}
              >
                <Input placeholder="PD" />
              </Form.Item>
            </Col>
            <Col xs={14}>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: false,
                    message: "Inserisci il paese",
                    min: 1,
                  },
                ]}
              >
                <Input placeholder="Paese" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default MyInfoAddFavouriteAddressModal;
