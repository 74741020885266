const websiteTypes = {
  TOGGLE_DRAWER: "TOGGLE_DRAWER",
  SET_ADMIN_CONSOLE_MENU: "SET_ADMIN_CONSOLE_MENU",
  SET_WEBSITE_PRODUCT_DETAILS: "SET_WEBSITE_PRODUCT_DETAILS",
  TOGGLE_CART_DRAWER: "TOGGLE_CART_DRAWER",
  GET_AVAILABLE_CITIES: "GET_AVAILABLE_CITIES",
  SET_AVAILABLE_CITIES: "SET_AVAILABLE_CITIES",
  UPDATE_SET_AVAILABLE_CITIES_STATUS: "UPDATE_SET_AVAILABLE_CITIES_STATUS",
  UPDATE_AVAILABLE_CITIES: "UPDATE_AVAILABLE_CITIES",
  SEARCH_CITIES: "SEARCH_CITIES",
  SET_SEARCHED_CITIES: "SET_SEARCHED_CITIES",
  SET_NEXT_AVAILABLE_MOMENT: "SET_NEXT_AVAILABLE_MOMENT",

  GET_PAGES: "GET_PAGES",
  SET_PAGES: "SET_PAGES",
  UPDATE_PAGES: "UPDATE_PAGES",

  GET_OPENING_INFO: "GET_OPENING_INFO",
  SET_OPENING_INFO: "SET_OPENING_INFO",
  UPDATE_OPENING_INFO: "UPDATE_OPENING_INFO",
  GET_NEXT_AVAILABLE_MOMENT: "GET_NEXT_AVAILABLE_MOMENT",
  GET_UPLOAD_IMAGES: "GET_UPLOAD_IMAGES",
  UPLOAD_IMAGES: "UPLOAD_IMAGES",
  SET_UPLOAD_IMAGES: "SET_UPLOAD_IMAGES",

  GET_HOME_PAGE: "GET_HOME_PAGE",
  SET_HOME_PAGE: "SET_HOME_PAGE",
  UPDATE_HOME_PAGE: "UPDATE_HOME_PAGE",

  GET_ADMIN_ORDERS: "GET_ADMIN_ORDERS",
  SET_ADMIN_ORDERS: "SET_ADMIN_ORDERS",

  UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
  SET_UPDATE_ORDER_STATUS: "SET_UPDATE_ORDER_STATUS",

  GET_SCHEDULED_OCCASIONS: "GET_SCHEDULED_OCCASIONS",
  SET_SCHEDULED_OCCASIONS: "SET_SCHEDULED_OCCASIONS",
  UPDATE_SCHEDULED_OCCASIONS: "UPDATE_SCHEDULED_OCCASIONS",

  LOG_ANALYTICS_EVENT: "LOG_ANALYTICS_EVENT",
};

export default websiteTypes;
