import { Card, List, Row, Skeleton } from "antd";
import React from "react";

const fooDataArray = Array.from(Array(20).keys());

interface Props {}

const SkeletonShopCards = (props: Props) => {
  return (
    <List
      grid={{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 2,
        xl: 3,
        xxl: 4,
      }}
      dataSource={fooDataArray}
      renderItem={(foo: any) => (
        <List.Item style={{ margin: "18px" }}>
          <Card
            bodyStyle={{ padding: 0, lineHeight: 0 }}
            key={`skeletonCardProduct-${foo.toString()}`}
          >
            <div
              style={{
                width: "100%",
                height: "300px",
                backgroundColor: "lightgrey",
                animation: "ant-skeleton-loading 1.4s ease infinite",
                background:
                  "linear-gradient(90deg, lightgrey 25%, #e6e6e6 37%, lightgrey 63%)",
                backgroundSize: "400% 100%",
              }}
            />
            <br />
            <Row justify="center" style={{ padding: "8px" }}>
              <Skeleton active paragraph={false} />
            </Row>
          </Card>
        </List.Item>
      )}
    />
  );
};

export default SkeletonShopCards;
