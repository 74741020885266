import { Card, CardProps, Row, Typography } from "antd";
import React, { ReactElement } from "react";

const styleCard = {
  width: "100%",
  marginBottom: "16px",
} as React.CSSProperties;

const selectedStyleCard = {
  ...styleCard,
  outline: "4px solid #07b07a",
  outlineOffset: "-4px",
  boxShadow:
    "0 1px 2px -2px rgba(0, 0, 0, 0.16),0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
} as React.CSSProperties;

const notClickableStyleCard = {
  ...styleCard,
  outline: "4px solid #DC143C",
  outlineOffset: "-4px",
} as React.CSSProperties;

const bodyStyleCard = {
  width: "100%",
  height: "150px",
  padding: "8px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
} as React.CSSProperties;

const notClickableBodyStyleCard = {
  ...bodyStyleCard,
  color: "#F0F2F5",
} as React.CSSProperties;

interface Props {
  headerTitle?: string;
  title: string | ReactElement;
  selected: boolean;
  onClickCardFunction: () => void;
  icon?: ReactElement;
  hoverable?: boolean;
  additionalSelectedStyle?: React.CSSProperties;
  additionalStyle?: React.CSSProperties;
  additionalTitleStyle?: React.CSSProperties;
  additionalBodyStyle?: React.CSSProperties;
  additionalNotClickableStyle?: React.CSSProperties;
  notClickableMessage?: string;
  extra?: CardProps["extra"];
}

const SelectableCard = ({
  headerTitle,
  title,
  selected,
  onClickCardFunction,
  icon,
  hoverable,
  additionalSelectedStyle,
  additionalStyle,
  additionalNotClickableStyle,
  additionalTitleStyle,
  additionalBodyStyle,
  notClickableMessage,
  extra,
}: Props) => {
  return (
    <>
      <Card
        extra={extra}
        style={
          hoverable
            ? selected
              ? { ...selectedStyleCard, ...additionalSelectedStyle }
              : {
                  ...styleCard,
                  ...additionalStyle,
                }
            : {
                ...notClickableStyleCard,
                ...additionalNotClickableStyle,
              }
        }
        title={headerTitle && headerTitle}
        bodyStyle={
          hoverable
            ? { ...bodyStyleCard, ...additionalBodyStyle }
            : { ...notClickableBodyStyleCard, ...additionalBodyStyle }
        }
        onClick={onClickCardFunction}
        hoverable={hoverable === undefined ? true : hoverable}
      >
        <Row
          className="full-space-between-row"
          style={{ justifyContent: "center" }}
        >
          {icon}
        </Row>
        <Row
          className="full-space-between-row"
          style={{ justifyContent: "center" }}
        >
          <Typography.Text
            style={{ fontSize: "0.8rem", ...additionalTitleStyle }}
            strong
          >
            {title}
          </Typography.Text>
        </Row>
      </Card>
      {!hoverable && (
        <div
          style={{
            backgroundColor: "#DC143C",
            width: "100%",
            textAlign: "center",
            //fontWeight: "bold",
            color: "#ffffff",
          }}
        >
          {notClickableMessage}
        </div>
      )}
    </>
  );
};

export default SelectableCard;
