import React, { useEffect, useState } from "react";
import "./App.less";

import { Layout } from "antd";

import { Navbar } from "./components";
import NavDrawer from "./components/Drawer";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "./redux/User/user.actions";
import CartDrawer from "./components/CartDrawer";
import { isLive, localStorageKeys } from "./utils";
import { setRetrievedCartArray } from "./redux/Cart/cart.actions";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { CartProduct } from "./types/cartProduct.type";
import { getProductsList } from "./redux/Products/products.actions";
import {
  getNextAvailableMoment,
  getOpeningInfo,
  getPages,
  getScheduledOccasions,
} from "./redux/Website/website.actions";
import PageLoader from "./components/PageLoader/PageLoader";
import UnderMaintenance from "./components/UnderMaintenance";
import Router from "./router/Router";
import CookieBanner from "./components/CookieBanner";

const { Content } = Layout;

const App = () => {
  const dispatch = useDispatch();

  const { fetching: fetchingPages, pages } = useSelector(
    (state: RootStateOrAny) => state.website.pages
  );

  const { auth } = useSelector((state: RootStateOrAny) => state.firebase);
  const { userInfo } = useSelector(
    (state: RootStateOrAny) => state.user.getUserInfo
  );

  const { itemsCounter } = useSelector((state: RootStateOrAny) => state.cart);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [itemsCartCounter, setItemsCartCounter] = useState(itemsCounter);

  useEffect(() => {
    dispatch(getPages());
    dispatch(getOpeningInfo());
    dispatch(getScheduledOccasions());
    const cartArrayStringified = localStorage.getItem(
      localStorageKeys.CART_ARRAY
    );
    if (cartArrayStringified !== undefined && cartArrayStringified !== null) {
      const cartArray: CartProduct[] = JSON.parse(cartArrayStringified);
      dispatch(setRetrievedCartArray(cartArray));
    }
    setItemsCartCounter(itemsCounter);
    dispatch(getProductsList());
    dispatch(getNextAvailableMoment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authCurrentStatus = isLoaded(auth) && !isEmpty(auth);
  useEffect(() => {
    if (authCurrentStatus && Object.keys(userInfo).length === 0) {
      dispatch(getUserInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCurrentStatus]);

  // /**
  //  * This useEffect hook is used to notify the user due a cart update
  //  */
  // useEffect(() => {
  //   if (
  //     (itemsCounter && itemsCounter > itemsCartCounter) ||
  //     (!itemsCounter && itemsCartCounter > 0)
  //   ) {
  //     message.success("Prodotto aggiunto");
  //   } else if (itemsCounter && itemsCounter < itemsCartCounter) {
  //     message.error("Prodotto rimosso");
  //   }

  //   setItemsCartCounter(itemsCounter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [itemsCounter]);

  if (fetchingPages) {
    return <PageLoader />;
  }
  if (fetchingPages || (isLive() && pages.is_live_under_maintenance)) {
    return <UnderMaintenance />;
  }

  return (
    <div className="App">
      <Layout>
        <NavDrawer />
        <Navbar />
        <CartDrawer />
        <Content>
          <Router />
        </Content>
        <CookieBanner />
      </Layout>
    </div>
  );
};

export default App;
