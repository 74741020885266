import { FirestoreShortProduct } from "../types/firestore/shortProduct.firestore.type";
import { ShopFilters } from "../types/shopFilters.type";

/**
 * This method is use to filter the total list of products of the ecommerce
 * and retrieves the queried list products due setting filters.
 *
 * @param productsList The list of product in short way
 * @param filters The filters to queries the products
 */
export const queryProductsList = (
  productsList: any[],
  filters: ShopFilters
): FirestoreShortProduct[] => {
  let queriedProductList: any[] = Object.assign([], productsList);
  if (!filters) {
    return queriedProductList;
  }
  if (filters.is_by_string && filters.search_string) {
    const trimmedString = filters.search_string
      .toLocaleLowerCase()
      .replace(/\s\s+/g, " ");
    queriedProductList = queriedProductList.filter(
      (product: FirestoreShortProduct) => {
        const trimmedProductName = product.name
          ?.toLocaleLowerCase()
          .replace(/\s\s+/g, " ")
          .split(" ");
        const trimmedOccasions = product.occasions?.map((occasion) =>
          occasion.split("_").join(" ")
        );
        const productArray = [
          ...(trimmedProductName || []),
          ...(trimmedOccasions || []),
        ];
        return (
          product.name === trimmedString ||
          trimmedString
            .split(" ")
            .some((subStr) =>
              productArray.some((prodSubStr) => subStr === prodSubStr)
            )
        );
      }
    );
  } else {
    if (filters.types && filters.types && filters.types !== "all") {
      queriedProductList = queriedProductList.filter(
        (product: FirestoreShortProduct) =>
          filters.types && filters.types === product?.type
      );
    }
    if (filters.price && filters.price[0] && filters.price[1]) {
      queriedProductList = queriedProductList.filter(
        (product: FirestoreShortProduct) =>
          filters.price &&
          product.price &&
          filters.price[0] <= product.price &&
          product.price <= filters.price[1]
      );
    }
    if (filters.occasions && filters.occasions[0] !== "") {
      queriedProductList = queriedProductList.filter(
        (product: FirestoreShortProduct) =>
          filters.occasions &&
          filters.occasions?.every(
            (occasion) => product.occasions?.indexOf(occasion) !== -1
          )
      );
    }
  }

  queriedProductList = queriedProductList.sort((a, b) => a?.price - b?.price);
  queriedProductList = queriedProductList.sort(
    (a, b) => b.available - a.available
  );
  return queriedProductList;
};
