import React, { useState } from "react";
import Search from "antd/lib/input/Search";
import { useDispatch } from "react-redux";
import { changeFilters } from "../../redux/Shop/shop.actions";

interface Props {
  filters: any;
}

const SearchProductBar = ({ filters }: Props) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(undefined);
  const handleSearch = (searchParam: string) => {
    //console.debug(searchParam);
    dispatch(
      changeFilters({
        ...filters,
        search_string: searchParam.toString(),
        is_by_string: true,
      })
    );
    setValue(undefined);
  };
  return (
    <Search
      style={{ width: "100%", textAlign: "left", maxWidth: "400px" }}
      onSearch={handleSearch}
      placeholder="Cerca prodotto"
      defaultValue={value}
      allowClear
      enterButton
    />
  );
};

export default SearchProductBar;
