import React from "react";
import { Divider, Drawer, Menu, Row } from "antd";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { toggleDrawer } from "../../redux/Website/website.actions";
import SubMenu from "antd/lib/menu/SubMenu";
import useWindowSize from "../../utils";
import { NavLink, useHistory } from "react-router-dom";
import Path from "../../utils/pathConstants";
import { RiFacebookFill, RiInstagramLine } from "react-icons/ri";
import moment from "antd/node_modules/moment";

const pathData = {
  "/": "home",
  "/shop": "shop",
  "/floral-services": "floral_services",
  "/floral-services/weddings": "floral_services_weddings",
  "/floral-services/graduation": "floral_services_graduation",
  "/floral-services/villa": "floral_services_villa",
  "/floral-services/events": "floral_services_events",
  "/floral-services/funerals": "floral_services_funerals",
  "/admin": "admin",
  "/my-account": "my-account",
  "/login": "my-account",
};

/**
 * Map the current location to the entry in the menu in order to liht it up
 * @param location the relative path of URL
 */
const mapLocationToKey = (pathname: string) => {
  let subPaths: string[] | null = pathname.split("/").filter((ss) => ss !== "");
  let found: boolean = false;
  let subPath = "/";
  while (subPaths && subPaths?.length > 0 && !found) {
    const concatPaths: string = "/" + subPaths?.join("/");
    if (pathData[concatPaths] !== undefined) {
      found = true;
      subPath = concatPaths;
    } else {
      subPaths?.pop();
    }
  }
  return [pathData[subPath]];
};

interface Props {}

const NavDrawer = (props: Props) => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const { is_admin } = useSelector(
    (state: RootStateOrAny) => state.user.getUserInfo?.userInfo
  );

  let drawerWidth = width < 768 ? "100%" : "35%";

  const isOpen = useSelector(
    (state: RootStateOrAny) => state.website.isDrawerOpen
  );

  const closeDrawer = () => {
    dispatch(toggleDrawer());
  };

  const treeMenu: {
    key: string;
    title: string;
    navigateTo: string;
    subItems?: any[];
  }[] = [
    {
      key: "home",
      title: "home",
      navigateTo: "/",
    },
    {
      key: "shop",
      title: "shop",
      navigateTo: "/shop",
    },
    // {
    //   key: "floral_services",
    //   title: "servizi floreali",
    //   subItems: [
    //     {
    //       key: "floral_services_weddings",
    //       title: "matrimoni",
    //       navigateTo: `${Path.FLORAL_SERVICES}/weddings`,
    //     },
    //     {
    //       key: "floral_services_graduation",
    //       title: "lauree",
    //       navigateTo: `${Path.FLORAL_SERVICES}/graduation`,
    //     },
    //     {
    //       key: "floral_services_villa",
    //       title: "allestimento ville",
    //       navigateTo: `${Path.FLORAL_SERVICES}/villa`,
    //     },
    //     {
    //       key: "floral_services_events",
    //       title: "eventi",
    //       navigateTo: `${Path.FLORAL_SERVICES}/events`,
    //     },
    //     {
    //       key: "floral_services_funerals",
    //       title: "funerali",
    //       navigateTo: `${Path.FLORAL_SERVICES}/funerals`,
    //     },
    //   ],
    // },
  ];

  return (
    <Drawer
      placement={"left"}
      closable={true}
      onClose={closeDrawer}
      visible={isOpen}
      key={"left"}
      width={drawerWidth}
      footer={
        <div style={{ fontWeight: 300, fontSize: "0.7em" }}>
          <Row>Copyright © {moment().format("YYYY")} Fioreria Le Clematis.</Row>
          <Row>Tutti i diritti riservati.</Row>
          <Row>
            <NavLink to={Path.PRIVACY_AND_COOKIE_POLICY} onClick={closeDrawer}>
              Privacy and Cookie Policy
            </NavLink>
          </Row>
          <Row>
            <NavLink to={Path.TERMS_AND_CONDITIONS} onClick={closeDrawer}>
              Terms and Conditions
            </NavLink>
          </Row>
        </div>
      }
    >
      <div style={{ marginTop: "20px" }}>
        <Menu
          mode="inline"
          defaultSelectedKeys={mapLocationToKey(history.location.pathname)}
          style={{ width: "100%", border: "none" }}
        >
          {treeMenu.map((item) => {
            if (item?.subItems === undefined) {
              return (
                <Menu.Item className="sub-menu-first-level" key={item.key}>
                  <NavLink to={item.navigateTo} onClick={closeDrawer}>
                    {item.title.toUpperCase()}
                  </NavLink>
                </Menu.Item>
              );
            } else {
              return (
                <SubMenu
                  className="sub-menu-first-level"
                  key={item.key}
                  title={item.title.toUpperCase()}
                >
                  {item?.subItems?.map((subItem) => (
                    <Menu.Item
                      className="sub-menu-second-level"
                      key={subItem.key}
                    >
                      <NavLink to={subItem.navigateTo} onClick={closeDrawer}>
                        {subItem.title.toUpperCase()}
                      </NavLink>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            }
          })}
          <Menu.Divider />
          <Menu.Item className="sub-menu-first-level" key="my-account">
            <NavLink
              to={Path.MY_ACCOUNT}
              onClick={closeDrawer}
              style={{ color: "#07b07a" }}
            >
              IL MIO ACCOUNT
            </NavLink>
          </Menu.Item>
          {is_admin ? (
            <Menu.Item className="sub-menu-first-level" key="admin">
              <NavLink
                to={Path.ADMIN + "/dashboard"}
                onClick={closeDrawer}
                style={{ color: "red" }}
              >
                CONSOLE ADMIN
              </NavLink>
            </Menu.Item>
          ) : (
            ""
          )}
        </Menu>
        <Divider></Divider>
        <Row style={{ paddingLeft: "24px" }}>
          <a
            rel="noopener noreferrer"
            href="https://www.facebook.com/leclematis"
            target="_blank"
          >
            <RiFacebookFill
              style={{ fontSize: "2em", margin: "8px", color: "#242425" }}
            />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.instagram.com/fioreria_le_clematis"
            target="_blank"
          >
            <RiInstagramLine
              style={{ fontSize: "2em", margin: "8px", color: "#242425" }}
            />
          </a>
        </Row>
      </div>
    </Drawer>
  );
};

export default NavDrawer;
