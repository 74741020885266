import { EditFilled } from "@ant-design/icons";
import { Button, Divider, Row, Typography } from "antd";
import React from "react";

interface Props {
  name: string;
  value: string | number;
  editFunction?: () => void;
}

const DataDisplayWithEdit = ({ name, value, editFunction }: Props) => {
  return (
    <Row className="full-space-row" style={{ fontSize: "1em" }}>
      <Row className="full-space-row" justify="space-between" align="middle">
        <Typography.Text strong>{name}</Typography.Text>
        {editFunction && (
          <Button size="small" type="primary" onClick={editFunction}>
            <EditFilled />
          </Button>
        )}
      </Row>
      <Row style={{ textAlign: "start" }} className="full-space-row">
        <Typography.Text>{value}</Typography.Text>
      </Row>
      <Divider style={{ margin: "8px 0" }} />
    </Row>
  );
};

export default DataDisplayWithEdit;
