type ConfigType = {
  env: string;
  siteEndpoint: string;
  firebaseEndpoint: string;
  domain: string;
  stripe: { public_key: string };
  zipcodes: {
    apiKey: string;
    appKey: string;
  };
};

const local: ConfigType = {
  env: "LOCAL",
  siteEndpoint: "https://localhost:3000",
  firebaseEndpoint:
    "https://us-central1-fioreria-le-clematis.cloudfunctions.net/api",
  domain: "https://localhost:3000",
  stripe: {
    public_key:
      "pk_test_51HRk1nL2eef3ELFcMvjOwhdC4YBgIH9apLNcoIAhdT73sjd48EKbTOtDMYZYCLp3t7SY5z4hBLaOC2FkKRAqjCHN00JVgFnLUy",
  },
  zipcodes: {
    apiKey: "OhOd6RT1zE1Ramak5wpeI1xG7B4KibhQhU3vv1VD",
    appKey: "Apujynej9MdKLDWtMPokq48uwodKmARxA9yHpoLL",
  },
};

const test: ConfigType = {
  env: "TEST",
  siteEndpoint: "https://fioreria-le-clematis.web.app",
  firebaseEndpoint:
    "https://us-central1-fioreria-le-clematis.cloudfunctions.net/api",
  domain: "https://fioreria-le-clematis.web.app",
  stripe: {
    public_key:
      "pk_test_51HRk1nL2eef3ELFcMvjOwhdC4YBgIH9apLNcoIAhdT73sjd48EKbTOtDMYZYCLp3t7SY5z4hBLaOC2FkKRAqjCHN00JVgFnLUy",
  },
  zipcodes: {
    apiKey: "OhOd6RT1zE1Ramak5wpeI1xG7B4KibhQhU3vv1VD",
    appKey: "Apujynej9MdKLDWtMPokq48uwodKmARxA9yHpoLL",
  },
};

const prod: ConfigType = {
  env: "PROD",
  siteEndpoint: "https://www.fiorerialeclematis.it",
  firebaseEndpoint:
    "https://us-central1-fioreria-le-clematis.cloudfunctions.net/api",
  domain: "https://www.fiorerialeclematis.it",
  stripe: {
    public_key:
      "pk_live_51HRk1nL2eef3ELFcGE0STEHTTzyE6PdKIHqTo714ihO5JxIhBpGsTZTUUbdCGNpwKoYrb3JXXgkJ9Tz4hh6M0Ol800zuf31cTn",
  },
  zipcodes: {
    apiKey: "OhOd6RT1zE1Ramak5wpeI1xG7B4KibhQhU3vv1VD",
    appKey: "Apujynej9MdKLDWtMPokq48uwodKmARxA9yHpoLL",
  },
};

let config;
switch (window.location.hostname) {
  case "localhost":
    config = local;
    break;
  case "fioreria-le-clematis.web.app":
    config = test;
    break;
  default:
    config = prod;
    break;
}

export default {
  // Add common config values here
  ...config,
};
