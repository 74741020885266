import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store, { rrfMainProps } from "./redux/createStore";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import AuthIsLoaded from "./authentication/AuthIsLoaded";

const rootElement: HTMLElement | null = document.getElementById("root");

const Application = () => (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfMainProps}>
        <BrowserRouter>
          <AuthIsLoaded>
            <App />
          </AuthIsLoaded>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
);

if (rootElement?.hasChildNodes()) {
  hydrate(<Application />, rootElement);
} else {
  render(<Application />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
