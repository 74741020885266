import React, { useEffect, useState } from "react";

import {
  Table,
  Switch,
  Space,
  Typography,
  Popconfirm,
  message,
  Image,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getProductsList,
  updateProduct,
} from "../../../redux/Products/products.actions";
import { NavLink } from "react-router-dom";
import Path from "../../../utils/pathConstants";
import { productTypesConstants } from "../../../utils";

const showHeader = true;
const pagination = { position: "bottom" };
const status = {
  STARTED: "STARTED",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
};

interface Props {}

const ProductTable = (props: Props) => {
  const dispatch = useDispatch();
  const productState = useSelector((state: RootStateOrAny) => state.products);
  const {
    isLoadingProductsList,
    hasData,
    productsList,
    productDeletionStatus,
    productUpdatingStatus,
  } = productState;

  const [tableOptions, setTableOptions] = useState({
    bordered: false,
    loading: isLoadingProductsList,
    pagination,
    title: undefined,
    showHeader,
    scroll: undefined,
    hasData: hasData,
    tableLayout: undefined,
    ellipsis: true,
  });

  useEffect(() => {
    dispatch(getProductsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (productDeletionStatus) {
      case status.STARTED:
        setTableOptions({ ...tableOptions, loading: true });
        break;
      case status.COMPLETED:
        dispatch(getProductsList());
        setTableOptions({ ...tableOptions, loading: false });
        message.success("Prodotto cancellato");
        break;
      case status.ERROR:
        setTableOptions({ ...tableOptions, loading: false });
        message.error("Errore nella cancellazione ");
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDeletionStatus]);

  useEffect(() => {
    switch (productUpdatingStatus) {
      case status.STARTED:
        setTableOptions({ ...tableOptions, loading: true });
        break;
      case status.COMPLETED:
        dispatch(getProductsList());
        setTableOptions({ ...tableOptions, loading: false });
        message.success("Prodotto aggiornato");
        break;
      case status.ERROR:
        setTableOptions({ ...tableOptions, loading: false });
        message.error("Errore nell'aggiornamento ");
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productUpdatingStatus]);

  useEffect(() => {
    setTableOptions({ ...tableOptions, loading: isLoadingProductsList });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingProductsList]);

  const columns: any = [
    {
      title: "Foto",
      dataIndex: "image",
      key: "image",
      render: (imageURL) => (
        <Image
          alt={imageURL?.url}
          src={imageURL?.url}
          style={{ maxWidth: "50px" }}
        />
      ),
    },
    {
      title: "Nome",
      dataIndex: "name",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: "Disponibile",
      dataIndex: "available",
      render: (value, record) => {
        const onChange = (e) => {
          dispatch(
            updateProduct({
              productID: record?.productID,
              product: { available: e },
            })
          );
        };
        return <Switch defaultChecked={value} onChange={onChange} />;
      },
    },
    {
      title: "Tipologia",
      dataIndex: "type",
      filters: [
        {
          text: "Pianta",
          value: productTypesConstants.PLANT,
        },
        {
          text: "Bouquet",
          value: productTypesConstants.BOUQUET,
        },
        {
          text: "Fiori singoli",
          value: productTypesConstants.SINGLE_FLOWER,
        },
        {
          text: "Altro",
          value: productTypesConstants.OTHER,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      render: (value) => {
        let text = value;
        if (value === productTypesConstants.PLANT) {
          text = "pianta";
        } else if (value === productTypesConstants.SINGLE_FLOWER) {
          text = "fiori singoli";
        } else if (value === productTypesConstants.OTHER) {
          text = "altro";
        }
        return <Typography.Text>{text.toUpperCase()}</Typography.Text>;
      },
    },
    {
      title: "Prezzo",
      key: "price",
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      render: (value) => <Typography.Text>{`€ ${value}`}</Typography.Text>,
    },
    {
      title: "Azioni",
      key: "action",
      render: (value, record) => {
        const confirm = (e) => {
          dispatch(deleteProduct(record.productID));
        };

        return (
          <Space size="middle">
            <NavLink
              to={`${Path.ADMIN}/products/update-product?uid=${record.productID}`}
            >
              <EditOutlined />
            </NavLink>
            <Popconfirm
              title="Sicuro?"
              onConfirm={confirm}
              okText="Si"
              cancelText="No"
            >
              <DeleteOutlined />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const tableColumns = columns.map((item) => ({
    ...item,
    ellipsis: tableOptions.ellipsis,
  }));

  return (
    <div>
      <Table
        {...tableOptions}
        size="middle"
        pagination={{ position: ["bottomRight"] }}
        columns={tableColumns}
        dataSource={tableOptions.hasData ? productsList : null}
        rowKey={(record) => record?.image?.uid}
      />
    </div>
  );
};

export default ProductTable;
