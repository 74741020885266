import {
  CheckCircleFilled,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Divider, Form, Input, Row, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { updateOpeningInfo } from "../../../redux/Website/website.actions";
import { requestStatus } from "../../../utils";

interface Props {}

const OpeningHours = (props: Props) => {
  const dispatch = useDispatch();
  const { shopHours, fetching, status } = useSelector(
    (state: RootStateOrAny) => state.website.openingInfo
  );
  const [openingHoursForm] = useForm();

  openingHoursForm.setFieldsValue({ shopHours: shopHours });

  const onFinishOpeningHoursForm = (values) => {
    dispatch(updateOpeningInfo(values));
  };

  return (
    <div className="opening-hours-panel">
      <Form
        name="opening-hours-form"
        form={openingHoursForm}
        onFinish={onFinishOpeningHoursForm}
      >
        <Form.List name="shopHours">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key}>
                  <Form.Item
                    {...field}
                    name={[field.name, "day"]}
                    fieldKey={[field.fieldKey, "day"]}
                  >
                    <Input className="input-day-name" disabled />
                  </Form.Item>
                  <Form.List {...field} name={[field.name, "hours"]}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...field}
                              name={[field.name, "start"]}
                              fieldKey={[field.fieldKey, "start"]}
                              label="Apertura"
                              rules={[
                                {
                                  required: true,
                                  message: "Orario apertura mancante",
                                },
                              ]}
                            >
                              <Input placeholder="09:00" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "end"]}
                              fieldKey={[field.fieldKey, "end"]}
                              label="Chiusura"
                              rules={[
                                {
                                  required: true,
                                  message: "Orario chiusura mancante",
                                },
                              ]}
                            >
                              <Input placeholder="19:30" />
                            </Form.Item>
                            <MinusCircleOutlined
                              onClick={() => remove(field.name)}
                            />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          >
                            Aggiungi intervallo di apertura
                          </Button>
                        </Form.Item>
                        <Divider style={{ marginBottom: "30px" }} />
                      </>
                    )}
                  </Form.List>
                </div>
              ))}
            </>
          )}
        </Form.List>
        <Form.Item>
          <Row style={{ marginTop: "12px" }} align="middle">
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={fetching}
                form="opening-hours-form"
              >
                Salva
              </Button>
              {status === requestStatus.COMPLETED && (
                <CheckCircleFilled style={{ color: "#07b07a" }} />
              )}
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default OpeningHours;
