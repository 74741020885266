import { Card, Row, Typography } from "antd";
import React from "react";
import { FirestoreProduct } from "../../../types/firestore/product.firestore.type";
import { capitalize } from "../../../utils";

interface Props {
  isProductLoading: boolean;
  product: FirestoreProduct;
}

const ProductDetailCardPlantDetails = ({
  isProductLoading,
  product,
}: Props) => {
  return (
    <Card
      className="details-bouquet-card card-fixed-shadow"
      loading={isProductLoading}
    >
      <Row className="row-bouquet" justify="space-between">
        <div>
          <Typography.Text strong>
            {`irrigazione`.toLocaleUpperCase()}
          </Typography.Text>
        </div>
        <div className="paragraph-container">
          <Typography.Paragraph className="paragraph">
            {capitalize(product?.details?.watering)}
          </Typography.Paragraph>
        </div>
      </Row>
      <Row className="row-bouquet" justify="space-between">
        <div>
          <Typography.Text strong>
            {`illuminazione`.toLocaleUpperCase()}
          </Typography.Text>
        </div>
        <div className="paragraph-container">
          <Typography.Paragraph className="paragraph">
            {capitalize(product?.details?.lighting)}
          </Typography.Paragraph>
        </div>
      </Row>
      <Row className="row-bouquet" justify="space-between">
        <div>
          <Typography.Text strong>
            {`umidità`.toLocaleUpperCase()}
          </Typography.Text>
        </div>
        <div className="paragraph-container">
          <Typography.Paragraph className="paragraph">
            {capitalize(product?.details?.humidity)}
          </Typography.Paragraph>
        </div>
      </Row>
    </Card>
  );
};

export default ProductDetailCardPlantDetails;
