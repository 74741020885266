import React, { useEffect } from "react";

interface Props {}

const Statistics = (props: Props) => {
  useEffect(() => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
};

export default Statistics;
