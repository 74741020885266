import { all, call } from "redux-saga/effects";
import cartSagas from "./Cart/cart.sagas";
import checkoutSagas from "./Checkout/checkout.sagas";
import productsSagas from "./Products/products.sagas";
import shopSagas from "./Shop/shop.sagas";
import userSagas from "./User/user.sagas";
import websiteSagas from "./Website/website.sagas";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(productsSagas),
    call(shopSagas),
    call(cartSagas),
    call(checkoutSagas),
    call(websiteSagas),
  ]);
}
