import React from "react";
import { FirestoreUser } from "../../../types/firestore/user.firestore.type";
import MyInfo from "./MyInfo";
import MyInfoFavouriteAddresses from "./MyInfoFavouriteAddresses";

interface Props {
  handleInfoEditButton: () => void;
  userInfo: FirestoreUser | undefined;
  additionalStyle: any;
  handleAddFavouriteAddressButton: () => void;
  removeFavoriteAddressByUid: (uid: string | undefined) => void;
}

const MyInfoPanel = ({
  additionalStyle,
  userInfo,
  handleInfoEditButton,
  handleAddFavouriteAddressButton,
  removeFavoriteAddressByUid,
}: Props) => {
  return (
    <>
      <MyInfo
        column={1}
        additionalStyle={additionalStyle}
        userInfo={userInfo}
        handleInfoEditButton={handleInfoEditButton}
      />
      <MyInfoFavouriteAddresses
        userInfo={userInfo}
        handleAddFavouriteAddressButton={handleAddFavouriteAddressButton}
        removeFavoriteAddressByUid={removeFavoriteAddressByUid}
      />
    </>
  );
};

export default MyInfoPanel;
