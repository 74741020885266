/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Row, Select, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import {
  getAvailableCities,
  searchCities,
  updateAvailableCities,
} from "../../../redux/Website/website.actions";
import { requestStatus } from "../../../utils";
import { CheckCircleFilled } from "@ant-design/icons";

const { Option } = Select;

interface Props {}

const Zipcodes = (props: Props) => {
  const dispatch = useDispatch();
  const [zipcodeState, setZipcodeState] = useState<{
    data: any[];
    value: any[];
    fetching: boolean;
  }>({
    data: [],
    value: [],
    fetching: false,
  });

  const { availableCities, searchedCities } = useSelector(
    (state: RootStateOrAny) => state.website
  );

  useEffect(() => {
    dispatch(getAvailableCities());
  }, []);

  useEffect(() => {
    setZipcodeState({
      ...zipcodeState,
      value: availableCities.data.map((city) => {
        const cityStringify = JSON.stringify(city);
        return {
          value: cityStringify,
          label: `${city?.Place_Name} ${city?.Postal_Code}`,
          key: city?.objectId,
        };
      }),
    });
  }, [availableCities]);

 // console.debug(zipcodeState);

  useEffect(() => {
    setZipcodeState({
      ...zipcodeState,
      data: searchedCities,
      fetching: false,
    });
  }, [searchedCities]);

  const fetchZipcodes = debounce((value) => {
    setZipcodeState({
      ...zipcodeState,
      fetching: true,
    });
    if (value?.length > 0) {
      dispatch(searchCities(value));
    }
  }, 800);

  const handleChange = (objects) => {
    setZipcodeState({
      ...zipcodeState,
      value: objects,
    });
  };

  const onClickSaveAvailableCities = () => {
    const availableCitiesList = zipcodeState.value.map((item) =>
      JSON.parse(item.value)
    );
    const availableCitiesListFiltered = availableCitiesList.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.Postal_Code === thing.Postal_Code)
    );
    dispatch(updateAvailableCities(availableCitiesListFiltered));
  };

  return (
    <div>
      <Row>
        <Select
          mode="multiple"
          labelInValue
          value={zipcodeState.value}
          placeholder="Seleziona le citta'"
          notFoundContent={zipcodeState.fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={fetchZipcodes}
          onChange={handleChange}
          style={{ width: "100%" }}
        >
          {zipcodeState.data.map((d) => (
            <Option key={d?.objectId} value={JSON.stringify(d)}>
              {`${d?.Place_Name} ${d?.Postal_Code}`}
            </Option>
          ))}
        </Select>
      </Row>
      <Row style={{ marginTop: "12px" }} align="middle">
        <Space>
          <Button
            loading={availableCities.status === requestStatus.STARTED}
            onClick={onClickSaveAvailableCities}
            type="primary"
          >
            SALVA
          </Button>
          {availableCities.status === requestStatus.COMPLETED && (
            <CheckCircleFilled style={{ color: "#07b07a" }} />
          )}
        </Space>
      </Row>
    </div>
  );
};

export default Zipcodes;
