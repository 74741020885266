/* eslint-disable @typescript-eslint/no-unused-vars */
import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Divider, List, message, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useState } from "react";
import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { uploadImages } from "../../../redux/Website/website.actions";
import { requestStatus } from "../../../utils";

interface Props {}

const ImagesHandler = (props: Props) => {
  const dispatch = useDispatch();

  const { images, fetching, status, error } = useSelector(
    (state: RootStateOrAny) => state.website.uploadImages
  );
  
  const [fileList, setFileList] = useState<
    RcFile[] | UploadFile<any>[] | any[]
  >([]);

  const beforeUpload = (file: RcFile, FileList: RcFile[]): boolean => {
    setFileList([...fileList, ...FileList]);
    return false;
  };

  const onRemove = (file: UploadFile<any>) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice().splice(index, 1);
    setFileList(newFileList);
  };

  const handleUpload = () => {
    dispatch(uploadImages(fileList));
  };

  useEffect(() => {
    switch (status) {
      case requestStatus.COMPLETED:
        message.success("Immagini caricate");
        setFileList([]);
        break;

      default:
        break;
    }
  }, [status]);

 // console.debug(fileList);

  return (
    <div className="images-handler-panel">
      <Upload
        accept="image/*"
        listType="picture"
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        fileList={fileList}
        multiple
      >
        <Button icon={<UploadOutlined />}>Carica</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0}
        loading={fetching}
        style={{ marginTop: 16 }}
      >
        {fetching ? "Caricamento" : "Salva le nuove immagini"}
      </Button>
      <Divider />
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 6,
          xl: 6,
          xxl: 6,
        }}
        dataSource={images}
        renderItem={(item: string) => (
          <List.Item>
            <Card
              cover={<img alt="" src={item} />}
              bodyStyle={{ padding: 0 }}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default ImagesHandler;
