import {
  ImportOutlined,
  ShoppingFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Card, Layout, Row, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  updateUserInfo,
} from "../../../redux/User/user.actions";
import {
  FirestoreAddress,
  FirestoreUser,
} from "../../../types/firestore/user.firestore.type";
import { isAPhone, requestStatus } from "../../../utils";
import MyInfoAddFavouriteAddressModal from "./MyInfoAddFavouriteAddressModal";
import MyInfoModal from "./MyInfoModal";
import MyInfoPanel from "./MyInfoPanel";
import MyOrdersPanel from "./MyOrdersPanel";
import { Helmet } from "react-helmet";

const { Content } = Layout;
const { TabPane } = Tabs;

interface Props {}

const MyAccount = (props: Props) => {
  const dispatch = useDispatch();
  const { getUserInfo, updateUserInfo: setUserInfo } = useSelector(
    (state: RootStateOrAny) => state.user
  );
  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const [componentState, setComponentState] = useState<any>({
    tab: { tabPosition: "left" },
    description: { bordered: true },
  });
  const [isUserInfoLoading, setIsUserInfoLoading] = useState(false);
  const [
    showAddFavouriteAddressModal,
    setShowAddFavouriteAddressModal,
  ] = useState(false);

  useEffect(() => {
    if (isAPhone()) {
      setComponentState({
        tab: { tabPosition: undefined },
        description: { bordered: false },
      });
    }
  }, []);

  useEffect(() => {
    switch (setUserInfo.status) {
      case requestStatus.STARTED:
        setIsUserInfoLoading(true);
        break;
      case requestStatus.COMPLETED:
        setIsUserInfoLoading(false);
        break;
      case requestStatus.ERROR:
        setIsUserInfoLoading(false);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserInfo.status]);

  const handleOkMyInfoModal = (userInfo: FirestoreUser) => {
    dispatch(updateUserInfo(userInfo));
    setShowEditInfoModal(!showEditInfoModal);
  };

  const toggleMyInfoModal = () => {
    setShowEditInfoModal(!showEditInfoModal);
  };

  const handleOkMyInfoAddFavouriteAddressModal = (
    favouriteAddresses: FirestoreAddress[] | undefined
  ) => {
    toggleMyInfoAddFavouriteAddressModal();
    if (favouriteAddresses !== undefined) {
      dispatch(
        updateUserInfo({
          ...getUserInfo?.userInfo,
          shipping_addresses: favouriteAddresses,
        })
      );
    }
  };

  const removeFavoriteAddressByUid = (uid: string | undefined) => {
    let userInfo = Object.assign({}, getUserInfo?.userInfo);
    let newShippingAddresses = userInfo?.shipping_addresses?.filter(
      (address) => address.uid !== uid
    );
    userInfo = {
      ...userInfo,
      shipping_addresses: newShippingAddresses,
    };
    dispatch(updateUserInfo(userInfo));
  };

  const toggleMyInfoAddFavouriteAddressModal = () => {
    setShowAddFavouriteAddressModal(!showAddFavouriteAddressModal);
  };

  const handleOnTabClick = (key: string, event) => {
    switch (key) {
      case "sign_out":
        dispatch(signOutAction());
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>Il mio account | Le Clematis Fioreria</title>
        <meta name="description" content="Il mio account" />
      </Helmet>
      <MyInfoModal
        visible={showEditInfoModal}
        userInfo={getUserInfo?.userInfo}
        handleOk={handleOkMyInfoModal}
        handleCancel={toggleMyInfoModal}
        isUserInfoLoading={isUserInfoLoading}
      />
      <MyInfoAddFavouriteAddressModal
        visible={showAddFavouriteAddressModal}
        handleOk={handleOkMyInfoAddFavouriteAddressModal}
        handleCancel={toggleMyInfoAddFavouriteAddressModal}
        isUserInfoLoading={isUserInfoLoading}
        currentAddresses={getUserInfo?.userInfo?.shipping_addresses}
      />
      <Content className="main-content">
        <Row className="main-content-row" justify="center">
          <Typography.Title>IL MIO ACCOUNT</Typography.Title>
        </Row>
      </Content>
      <Content className="main-content">
        <Row
          className="main-content-row"
          justify="space-between"
          gutter={[{ lg: 24, sm: 0, xs: 0 }, 0]}
        >
          <Card style={{ width: "100%" }}>
            <Tabs
              tabPosition={componentState.tab.tabPosition}
              onTabClick={handleOnTabClick}
            >
              <TabPane
                tab={
                  <span>
                    <UserOutlined />
                    Informazioni
                  </span>
                }
                key="1"
              >
                <MyInfoPanel
                  additionalStyle={componentState.description}
                  userInfo={getUserInfo?.userInfo}
                  handleInfoEditButton={toggleMyInfoModal}
                  handleAddFavouriteAddressButton={
                    toggleMyInfoAddFavouriteAddressModal
                  }
                  removeFavoriteAddressByUid={removeFavoriteAddressByUid}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <ShoppingFilled />
                    Ordini
                  </span>
                }
                key="2"
              >
                <MyOrdersPanel />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <ImportOutlined />
                    Esci
                  </span>
                }
                key="sign_out"
              />
            </Tabs>
          </Card>
        </Row>
      </Content>
    </>
  );
};

export default MyAccount;
