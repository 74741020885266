import firebase from "firebase";
import websiteTypes from "./website.types";

export const toggleDrawer = () => ({
  type: websiteTypes.TOGGLE_DRAWER,
});

export const toggleCartDrawer = () => ({
  type: websiteTypes.TOGGLE_CART_DRAWER,
});

export const setAdminConsoleMenu = (number) => ({
  type: websiteTypes.SET_ADMIN_CONSOLE_MENU,
  payload: number,
});

export const setWebsiteProductDetails = (productDetails) => ({
  type: websiteTypes.SET_WEBSITE_PRODUCT_DETAILS,
  payload: productDetails,
});

export const getAvailableCities = () => ({
  type: websiteTypes.GET_AVAILABLE_CITIES,
});

export const setAvailableCities = (availableCities) => ({
  type: websiteTypes.SET_AVAILABLE_CITIES,
  payload: availableCities,
});

export const updateSetAvailableCitiesStatus = (status) => ({
  type: websiteTypes.UPDATE_SET_AVAILABLE_CITIES_STATUS,
  payload: status,
});

export const updateAvailableCities = (availableCities: any[]) => ({
  type: websiteTypes.UPDATE_AVAILABLE_CITIES,
  payload: availableCities,
});

export const searchCities = (searchParam: string) => ({
  type: websiteTypes.SEARCH_CITIES,
  payload: searchParam,
});

export const setSearchCities = (searchedCities: any[]) => ({
  type: websiteTypes.SET_SEARCHED_CITIES,
  payload: searchedCities,
});

export const getPages = () => ({
  type: websiteTypes.GET_PAGES,
});

export const setPages = (pages) => ({
  type: websiteTypes.SET_PAGES,
  payload: pages,
});

export const updatePages = (pages) => ({
  type: websiteTypes.UPDATE_PAGES,
  payload: pages,
});

export const getOpeningInfo = () => ({
  type: websiteTypes.GET_OPENING_INFO,
});

export const setOpeningInfo = (openingInfo) => ({
  type: websiteTypes.SET_OPENING_INFO,
  payload: openingInfo,
});

export const updateOpeningInfo = (openingInfo) => ({
  type: websiteTypes.UPDATE_OPENING_INFO,
  payload: openingInfo,
});

export const getNextAvailableMoment = () => ({
  type: websiteTypes.GET_NEXT_AVAILABLE_MOMENT,
});

export const setNextAvailableMoment = (data) => ({
  type: websiteTypes.SET_NEXT_AVAILABLE_MOMENT,
  payload: data,
});

export const getUploadImages = () => ({
  type: websiteTypes.GET_UPLOAD_IMAGES,
});

export const uploadImages = (fileList) => ({
  type: websiteTypes.UPLOAD_IMAGES,
  payload: fileList,
});

export const setUploadImages = (data) => ({
  type: websiteTypes.SET_UPLOAD_IMAGES,
  payload: data,
});

export const getAdminOrders = () => ({
  type: websiteTypes.GET_ADMIN_ORDERS,
});

export const setAdminOrders = (data) => ({
  type: websiteTypes.SET_ADMIN_ORDERS,
  payload: data,
});

export const updateOrderStatus = (order) => ({
  type: websiteTypes.UPDATE_ORDER_STATUS,
  payload: order,
});

export const setUpdateOrderStatus = (order) => ({
  type: websiteTypes.SET_UPDATE_ORDER_STATUS,
  payload: order,
});

export const getScheduledOccasions = () => ({
  type: websiteTypes.GET_SCHEDULED_OCCASIONS,
});

export const setScheduledOccasions = (data) => ({
  type: websiteTypes.SET_SCHEDULED_OCCASIONS,
  payload: data,
});

export const updateScheduledOccasions = (data) => ({
  type: websiteTypes.UPDATE_SCHEDULED_OCCASIONS,
  payload: data,
});

export const logAnalyticsEventAction = (data: { key: firebase.analytics.EventNameString; value: any }) => ({
  type: websiteTypes.LOG_ANALYTICS_EVENT,
  payload: data,
});
