import cartTypes from "./cart.types";

export const updateCart = (data) => ({
  type: cartTypes.UPDATE_CART,
  payload: data,
});

export const changeUpdateCartStatus = (status) => ({
  type: cartTypes.CHANGE_UPDATE_CART_STATUS,
  payload: status,
});

export const updateCartItemsCounter = (counter) => ({
  type: cartTypes.UPDATE_CART_ITEMS_COUNTER,
  payload: counter,
});

export const updateItemOnCartAction = (data) => ({
  type: cartTypes.UPDATE_ITEM_ON_CART,
  payload: data,
});

export const deleteItemOnCartAction = (productCartKey) => ({
  type: cartTypes.DELETE_ITEM_ON_CART,
  payload: productCartKey,
});

export const updateCartSubTotal = (total) => ({
  type: cartTypes.UPDATE_CART_SUB_TOTAL,
  payload: total,
});

export const updateCartArray = (cartArray) => ({
  type: cartTypes.UPDATE_CART_ARRAY,
  payload: cartArray,
});

export const updateCartMap = (cartMap) => ({
  type: cartTypes.UPDATE_CART_MAP,
  payload: cartMap,
});

export const setRetrievedCartArray = (cartArray) => ({
  type: cartTypes.SET_RETRIEVED_CART_ARRAY,
  payload: cartArray,
});

export const deleteCurrentCart = () => ({
  type: cartTypes.DELETE_CURRENT_CART,
});

export const deleteCurrentCartAction = () => ({
  type: cartTypes.DELETE_CURRENT_CART_ACTION,
});
