import { Button } from 'antd';
import React from 'react'
import CookieConsent from 'react-cookie-consent';
import { useHistory } from 'react-router-dom';

interface Props {
    
}

const CookieBanner = (props: Props) => {
    const history = useHistory();
    return (
        <CookieConsent
        location="bottom"
        buttonText="Capito"
        cookieName="user-has-accepted-cookies"
        style={{
          backgroundColor: "rgba(43, 55, 59, 0.8)",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "0.8rem",
          padding: "5px",
        }}
        contentStyle={{
          flex: "unset",
          margin: "0",
        }}
        buttonStyle={{
          backgroundColor: "#07b07a",
          margin: "0 15px",
          color: "#ffffff",
          fontSize: "13px",
        }}
        expires={150}
      >
        <span>
          Questo sito usa i cookie per migliorare la tua esperienza.{" "}
        </span>
        <span>
          <Button
            style={{ padding: "0", fontSize: "0.8rem" }}
            type="link"
            onClick={() => history.push("/privacy-and-cookie-policy")}
          >
            Privacy and Cookie Policy
          </Button>
        </span>
      </CookieConsent>
    )
}

export default CookieBanner
