import { Button, Popover } from "antd";
import React from "react";
import { ChromePicker } from "react-color";

interface OnChangeHandler {
  (e): void;
}
interface Props {
  value?: any;
  onChange?: OnChangeHandler;
}

const ColorPicker = ({ value, onChange }: Props) => {
  return (
    <div>
      <Popover
        content={<ChromePicker color={value} onChange={onChange} />}
      >
        <Button
          style={{
            backgroundColor: value ? value.hex : "#000000",
            fontWeight: "bold",
            color: "white",
          }}
          block
        >
          CAMPO DI PROVA
        </Button>
      </Popover>
    </div>
  );
};

export default ColorPicker;
