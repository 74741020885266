import { Button, Result } from "antd";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";

const AdminRoute = ({ children, ...rest }) => {
  const history = useHistory();
  const { is_admin } = useSelector(
    (state: RootStateOrAny) => state.user.getUserInfo?.userInfo
  );
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return is_admin ? (
          children
        ) : (
          <Result
            status="403"
            title="403"
            subTitle="Scusami, non sei autorizzato a vedere questa pagina."
            style={{ backgroundColor: "white" }}
            extra={
              <Button type="primary" onClick={() => history.push("/")}>
                Torna alla Home
              </Button>
            }
          />
        );
      }}
    />
  );
};

export default AdminRoute;
