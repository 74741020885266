import { Spin } from 'antd'
import React from 'react'

interface Props {
    
}

const PageLoader = (props: Props) => {
    return (
        <div
        style={{
          display: "flex",
          position: "absolute",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <img src="/assets/logo/logo.svg" width="200px" alt=""/>
        <Spin style={{ marginTop: "20px" }} />
      </div>
    )
}

export default PageLoader
