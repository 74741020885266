import { RedoOutlined } from "@ant-design/icons";
import { Button, Card, Row, Tabs, Typography } from "antd";
import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getAdminOrders } from "../../../redux/Website/website.actions";
import OrdersTable from "./OrdersTable";

const { TabPane } = Tabs;

interface Props {}

const Orders = (props: Props) => {
  const dispatch = useDispatch();

  const {
    adminOrders,
    inStoreOrders,
    deliveringOrders,
    todayOrders,
    fetching,
  } = useSelector((state: RootStateOrAny) => state.website.adminOrders);

  useEffect(() => {
    dispatch(getAdminOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const panels: any[] = [
    {
      key: "zipcodes",
      header_title: "GIORNALIERI",
      component: (
        <OrdersTable
          tableTitle="Ordini di oggi"
          orders={todayOrders}
          loading={fetching}
        />
      ),
    },
    {
      key: "inStoreOrdersTable",
      header_title: "IN NEGOZIO",
      component: (
        <OrdersTable
          tableTitle="Ritiri in negozio"
          orders={inStoreOrders}
          loading={fetching}
        />
      ),
    },
    {
      key: "home_page",
      header_title: "A DOMICILIO",
      component: (
        <OrdersTable
          tableTitle="Consegna a domicilio"
          orders={deliveringOrders}
          loading={fetching}
        />
      ),
    },
  ];

  //console.debug(adminOrders, inStoreOrders, deliveringOrders);
  return (
    <div>
      <Row
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={3}>Ordini</Typography.Title>
        <Button
          type="primary"
          icon={<RedoOutlined />}
          onClick={() => dispatch(getAdminOrders())}
          loading={fetching}
        />
      </Row>
      <Card style={{ width: "100%" }} bodyStyle={{ minHeight: "80vh" }}>
        {/* <TodayOrdersTable orders={todayOrders} loading={fetching} /> */}
        <Tabs
          tabPosition="left"
          style={{ width: "100%", height: "100%" }}
          tabBarStyle={{ height: "100%" }}
        >
          {panels.map((panel) => (
            <TabPane
              tab={
                <Typography.Text strong>{panel.header_title}</Typography.Text>
              }
              key={panel.key}
            >
              {panel.component}
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </div>
  );
};

export default Orders;
