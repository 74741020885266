import { Button, Card, Col, Row, Typography } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { CartProduct } from "../../../types/cartProduct.type";
import { FirestoreOrder } from "../../../types/firestore/order.firestore.type";
import { isAPhone } from "../../../utils";
import CheckoutPriceComponent from "./CheckoutPriceComponent";

interface Props {
  currentStep: number;
  orderState: any;
  setOrderState: Dispatch<SetStateAction<FirestoreOrder>>;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  cartArray: CartProduct[];
  itemsCounter: number;
  cartSubTotal: number;
  availableCities?: any[];
  nextAvailableMoment: moment.Moment;
}

const OverviewStep = ({
  currentStep,
  orderState,
  setCurrentStep,
  cartArray,
  availableCities,
  nextAvailableMoment,
}: Props) => {
  const onClickNextOverviewStep = () => {
    setCurrentStep(1);
  };
  return (
    <Row style={{ display: "flex", justifyContent: "space-around" }}>
      <Col md={12} xs={24}>
        {isAPhone() && (
          <Button
            style={{ marginBottom: "16px" }}
            type="primary"
            onClick={onClickNextOverviewStep}
            block
          >
            {`${"Procedi con l'ordine".toLocaleUpperCase()}`}
          </Button>
        )}
        {cartArray.map((cartItem) => (
          <Card
            className="overview-product-card card-fixed-shadow"
            key={cartItem.productCartKey}
          >
            <Row style={{ flexWrap: "nowrap" }} justify="space-between">
              <Col style={{ marginRight: "18px" }} flex="100px">
                <img
                  alt={cartItem?.image?.uid}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                  src={cartItem?.image?.url}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                flex="auto"
              >
                <Row>
                  <Typography.Title style={{ margin: 0 }} level={4}>
                    {cartItem?.name}
                  </Typography.Title>
                </Row>
                <Row>
                  <Typography.Text>
                    {cartItem?.configuration?.type?.toLocaleUpperCase()}
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Text>
                    {`${cartItem?.quantity} x € ${parseFloat(
                      (cartItem?.price).toString()
                    ).toFixed(2)}`}
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
          </Card>
        ))}
      </Col>
      <Col md={9} xs={24}>
        <CheckoutPriceComponent
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          orderState={orderState}
          onClickNextStep={onClickNextOverviewStep}
          isButtonDisabled={false}
          availableCities={availableCities}
          nextAvailableMoment={nextAvailableMoment}
        />
      </Col>
    </Row>
  );
};

export default OverviewStep;
