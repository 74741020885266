import { Row, Typography } from "antd";
import React from "react";
import { FirestoreProduct } from "../../../types/firestore/product.firestore.type";

interface Props {
  product: FirestoreProduct;
}

const ProductDetailCardPlant = ({ product }: Props) => {
  return (
    <Row style={{ marginTop: "12px" }}>
      <Row style={{ width: "100%" }} justify="space-between">
        <div>{`altezza con vaso`.toLocaleUpperCase()}</div>
        <div>
          <Typography.Text strong>
            {`${product?.details?.height_with_jar} cm`.toLocaleUpperCase()}
          </Typography.Text>
        </div>
      </Row>
      <Row style={{ width: "100%" }} justify="space-between">
        <div>{`diametro con vaso`.toLocaleUpperCase()}</div>
        <div>
          <Typography.Text strong>
            {`${product?.details?.diameter_with_jar} cm`.toLocaleUpperCase()}
          </Typography.Text>
        </div>
      </Row>
    </Row>
  );
};

export default ProductDetailCardPlant;
