import { Button, Descriptions } from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import React from "react";
import { FirestoreUser } from "../../../types/firestore/user.firestore.type";

interface Props {
  column?: number | Partial<Record<Breakpoint, number>> | undefined;
  userInfo: FirestoreUser | undefined;
  handleInfoEditButton: () => void;
  additionalStyle: any;
}

const MyInfo = ({
  column,
  userInfo,
  handleInfoEditButton,
  additionalStyle,
}: Props) => {
  return (
    <Descriptions
      bordered={additionalStyle.bordered}
      title="Informazioni"
      size="small"
      column={column}
      extra={
        <Button type="primary" onClick={handleInfoEditButton}>
          Modifica
        </Button>
      }
    >
      <Descriptions.Item label="Nome">{userInfo?.first_name}</Descriptions.Item>
      <Descriptions.Item label="Cognome">
        {userInfo?.last_name}
      </Descriptions.Item>
      <Descriptions.Item label="Email">{userInfo?.email}</Descriptions.Item>
      <Descriptions.Item label="Telefono">{userInfo?.phone}</Descriptions.Item>
      <Descriptions.Item label={`Indirizzo`}>
        {userInfo?.billing_address?.street_name && (
          <>
            {userInfo?.billing_address?.street_name},{" "}
            {userInfo?.billing_address?.street_number}
            <br />
            {userInfo?.billing_address?.city},{" "}
            {userInfo?.billing_address?.zipcode}
            <br />
            {userInfo?.billing_address?.province}{" "}
            {userInfo?.billing_address?.state}
          </>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default MyInfo;
