import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Typography } from "antd";
import React from "react";
import { RiMailLine } from "react-icons/ri";
import { commonRegEx } from "../../utils";

interface Props {
  handleOnFinish: (values: any) => void;
  disabled: boolean;
}

const PasswordLessSignIn = ({ handleOnFinish, disabled }: Props) => {
  return (
    <Form onFinish={handleOnFinish}>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Inserisci la tua email",
            pattern: commonRegEx.EMAIL,
          },
        ]}
      >
        <Input
          prefix={<RiMailLine className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>

      <Form.Item>
        <Button
          className="login-form-button"
          type="primary"
          htmlType="submit"
          disabled={disabled}
        >
          Invia link di accesso
        </Button>
      </Form.Item>

      <div
        style={{
          padding: "4px",
          backgroundColor: "#dedede",
          color: "#8c8c8c",
        }}
      >
        <InfoCircleOutlined style={{ width: "24px", marginBottom: "4px" }} />
        <Typography.Paragraph style={{ fontSize: "0.7em", margin: 0 }}>
          Ti verrà inviato un link per accedere alla tua area riservata.
        </Typography.Paragraph>
        <Typography.Paragraph style={{ fontSize: "0.7em", margin: 0 }} strong>
          RICORDATI DI CONTROLLARE NELLO SPAM
        </Typography.Paragraph>
      </div>
    </Form>
  );
};

export default PasswordLessSignIn;
