import { productTypesConstants } from ".";
import {
  FirestoreProduct,
  FirestoreProductDetails,
} from "../types/firestore/product.firestore.type";
import { FirestoreShortProduct } from "../types/firestore/shortProduct.firestore.type";
import { Product } from "../types/product.type";
import objectMapper from "object-mapper";

const populateDetails = (
  details: FirestoreProductDetails,
  values
): FirestoreProductDetails => {
  details.height_with_jar = values?.height_with_jar;
  details.diameter_with_jar = values?.diameter_with_jar;
  details.difficulty = values?.difficulty;
  details.watering = values?.watering;
  details.humidity = values?.humidity;
  details.lighting = values?.lighting;
  details.durability = values?.durability;
  details.fragrance = values?.fragrance;
  details.strength = values?.strength;
  return details;
};

const populateImageBlobList = (imageList, originalImages): Array<Blob> => {
  const uid_added = [] as Array<string>;
  originalImages.forEach((orImg) => {
    uid_added.push(orImg?.uid);
  });

  const imageBlobList = [] as Array<Blob>;
  imageList.forEach((img) => {
    if (uid_added.includes(img.uid)) {
      imageBlobList.push(img?.blob);
    }
  });

  return imageBlobList;
};

export const fromProductToFirestore = (
  values,
  imageList: Array<any>
): {
  imageBlobList: Array<Blob>;
  product: FirestoreProduct;
} => {
  let type = {
    is_a_plant: false,
    is_a_bouquet: false,
    is_a_single_flower: false,
    is_other: false,
  };

  let details = {} as FirestoreProductDetails;

  switch (values.type) {
    case productTypesConstants.BOUQUET:
      type.is_a_bouquet = true;
      details = populateDetails(details, values);
      break;
    case productTypesConstants.PLANT:
      type.is_a_plant = true;
      details = populateDetails(details, values);
      break;
    case productTypesConstants.OTHER:
      type.is_other = true;
      details = populateDetails(details, values);
      break;
    default:
      type.is_a_single_flower = true;
      details = populateDetails(details, values);
      break;
  }
  let imageBlobList = [] as Array<Blob>;
  if (values?.images !== undefined) {
    imageBlobList = populateImageBlobList(imageList, values?.images);
  }

  let min_price = values?.price ? values.price : Infinity;
  let prices = [] as Array<{
    type: string;
    price: number;
    stems_number: number;
  }>;

  values?.prices?.forEach((item) => {
    prices.push({
      type: item.type,
      price: item.price,
      stems_number: item.stems_number,
    });
    min_price = item.price < min_price ? item.price : min_price;
  });

  const product = {
    created_at: new Date(),
    active: values?.available,
    available: values?.available,
    name: values?.name,
    ...type,
    min_price: min_price,
    prices: prices,
    alerts: values?.alerts,
    currency: "EUR",
    popularity: 0,
    occasions: values?.occasions,
    details: {
      ...details,
      description: values?.description,
      tag: values?.occasions,
    },
  } as FirestoreProduct;

  return {
    imageBlobList: imageBlobList,
    product: product,
  };
};

export const fromFirestoreToForm = (data: FirestoreProduct): Product => {
  let responseData = {} as Product;

  if (data.is_a_bouquet) {
    responseData.type = productTypesConstants.BOUQUET;
  } else if (data.is_a_plant) {
    responseData.type = productTypesConstants.PLANT;
  } else if (data.is_a_single_flower) {
    responseData.type = productTypesConstants.SINGLE_FLOWER;
  } else if (data.is_other) {
    responseData.type = productTypesConstants.OTHER;
  }

  const firestoreDetails = data?.details as FirestoreProductDetails;

  responseData.height_with_jar = firestoreDetails?.height_with_jar;
  responseData.diameter_with_jar = firestoreDetails?.diameter_with_jar;
  responseData.difficulty = firestoreDetails?.difficulty;
  responseData.watering = firestoreDetails?.watering;
  responseData.humidity = firestoreDetails?.humidity;
  responseData.lighting = firestoreDetails?.lighting;
  responseData.durability = firestoreDetails?.durability;
  responseData.strength = firestoreDetails?.strength;
  responseData.fragrance = firestoreDetails?.fragrance;
  responseData.description = firestoreDetails?.description;
  responseData.occasions = data?.occasions;
  responseData.currency = data?.currency;
  responseData.alerts = data?.alerts;
  responseData.name = data?.name;
  responseData.firestoreImages = data?.images;
  responseData.prices = data?.prices;
  responseData.price = data?.min_price;

  return responseData;
};

const MAP_FIRESTORE_PRODUCT_TO_SHORT_PRODUCT = {
  uid: "productID",
  available: "available",
  name: "name",
  min_price: "price",
  occasions: "occasions",
};

export const fromFirestoreProductToShortProduct = (
  product: any
): FirestoreShortProduct => {
  let responseProduct = {} as FirestoreShortProduct;

  responseProduct = objectMapper(
    product,
    MAP_FIRESTORE_PRODUCT_TO_SHORT_PRODUCT
  );

  const image = product.images?.filter((image) => image.is_main_image)[0];
  responseProduct.image = image;

  let type;
  if (product.is_a_bouquet) {
    type = productTypesConstants.BOUQUET;
  } else if (product.is_a_single_flower) {
    type = productTypesConstants.SINGLE_FLOWER;
  } else if (product.is_a_plant) {
    type = productTypesConstants.PLANT;
  } else {
    type = productTypesConstants.OTHER;
  }

  responseProduct.type = type;

  return responseProduct;
};
