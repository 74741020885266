export const collection = {
  users: "users",
  generic_infos: "generic_infos",
  products: "products",
  orders: "orders",
  product_list: "product_list",
  opening_info: "opening_info",
  pages: "pages",
  images: "images",
  scheduled_occasions: "scheduled_occasions",
};
