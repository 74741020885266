import { all, put, select, takeLatest } from "redux-saga/effects";
import { shopTypes } from "./shop.types";
import { firestore } from "../../firebase/utils";
import { requestStatus } from "../../utils";
import { changeGetShopProductsStatus, setShopProducts } from "./shop.actions";
import { setWebsiteProductDetails } from "../Website/website.actions";
import { queryProductsList } from "../../utils/shopUtils";
import { FirestoreShortProduct } from "../../types/firestore/shortProduct.firestore.type";

interface ActionWithPayload {
  type: string;
  payload?: any;
}

/**
 * This method is used to customize the search in firestore
 * @param filters Used to customize the query to send to firestore
 */
export function* onGetShopProducts({ payload: filters }: ActionWithPayload) {
  //STARTED
  yield put(changeGetShopProductsStatus(requestStatus.STARTED));
  try {
    //RETRIEVE PRODUCTS LIST
    const productsList: any[] = yield select(
      (state) => state.products.productsList
    );
    const queriedProducts: FirestoreShortProduct[] = yield queryProductsList(
      productsList,
      filters
    );
    /**
    let query = yield firestore.collection("products");
    if (
      filters?.types &&
      filters?.types[0] !== "all" &&
      filters?.types[0] !== ""
    ) {
      query = query.where(filters?.types[0], "==", true);
    }
    if (filters?.occasions) {
      if (filters?.occasions.length !== 0) {
        if (filters?.occasions[0] !== "") {
          query = query.where(
            "occasions",
            "array-contains-any",
            filters?.occasions
          );
        }
      }
    }
    if (
      filters?.price &&
      !isNaN(filters?.price[0]) &&
      !isNaN(filters?.price[1])
    ) {
      query = query.where("min_price", ">", filters?.price[0]);
      query = query.where("min_price", "<=", filters?.price[1]);
    }

    const querySnapshot = yield query.get();
    querySnapshot.forEach((doc) => {
      queriedProducts.push({ uid: doc?.id, ...doc.data() });
    });
    queriedProducts = queriedProducts.sort(
      (a, b) => a?.popularity - b?.popularity
    );
    */
    //COMPLETED
    yield put(setShopProducts(queriedProducts));
    yield put(changeGetShopProductsStatus(requestStatus.COMPLETED));
  } catch (error) {
    //ERROR
    console.error(error);
    yield put(changeGetShopProductsStatus(requestStatus.ERROR));
  }
}

/**
 * This method is used to retrieve the generic infos of shop details
 */
export function* onGetShopProductDetails() {
  try {
    const docSnapshot = yield firestore
      .collection("generic_infos")
      .doc("shop_details")
      .get();
    const data = docSnapshot.data();
    yield put(
      setWebsiteProductDetails({
        types: data?.product_types,
        occasions: data?.occasions,
      })
    );
  } catch (error) {
    console.error(error);
  }
}
/**
 * *Export sagas
 */
export default function* shopSagas() {
  yield all([
    takeLatest(shopTypes.GET_SHOP_PRODUCTS, onGetShopProducts),
    takeLatest(shopTypes.GET_SHOP_PRODUCT_DETAILS, onGetShopProductDetails),
  ]);
}
