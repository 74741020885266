import { takeLatest, call, all, put, select } from "redux-saga/effects";
import {
  auth,
  authConstants,
  getCurrentUser,
  handleUserProfile,
  isAdmin,
  GoogleProvider,
  actionCodeSettings,
  firestore,
  firebaseStorage,
  analytics,
} from "./../../firebase/utils";
import userTypes from "./user.types";
import {
  changeLoginStatus,
  changePasswordLessSignInStatus,
  eraseUserDataAction,
  loginError,
  setGetUserInfo,
  setGetUserInfoError,
  setGetUserInfoStatus,
  setUserOrderAction,
  setUserOrderErrorAction,
  setUserOrderFetchingAction,
  setUserOrderLastSnapshotAction,
  signInSuccess,
  updateUserInfoChangeStatus,
  updateUserInfoSetError,
} from "./user.actions";
import { localStorageKeys, requestStatus } from "../../utils";
import store from "../createStore";
import { collection } from "../../utils/constants";
import config from "../../config";
interface ISignInPL {
  type: string;
  payload?: any;
}

/**
 *
 */
export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (userAuth?.uid === undefined) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (err) {}
}

/**
 * ! NOT USED
 * @param user
 * @param additionalData
 */
export function* getSnapshotFromUserAuth(user, additionalData = {}) {
  try {
    const userRef = yield call(handleUserProfile, {
      userAuth: user,
      additionalData,
    });
    const snapshot = yield userRef.get();
    yield put(
      signInSuccess({
        uid: snapshot.id,
        //is_admin: admin,
        ...snapshot.data(),
      })
    );
  } catch (err) {}
}

/**
 * ! NOT USED
 * * Email password sign-in
 * @param param0 Credential to login
 */
export function* emailSignIn({ payload: credential }: ISignInPL) {
  const { email, password } = credential;
  try {
    yield put(changeLoginStatus(requestStatus.STARTED));
    const { user } = yield auth
      .setPersistence(authConstants.Auth.Persistence.LOCAL)
      .then(() => auth.signInWithEmailAndPassword(email, password));
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    yield put(loginError(err));
    yield put(changeLoginStatus(requestStatus.ERROR));
  }
  yield put(changeLoginStatus(requestStatus.COMPLETED));
}

/**
 * Get info of the authenticated user.
 * @param uid UID to identify the use in firestore
 */
export function* getUserInfoFromUid({ payload: userParams }: ISignInPL) {
  yield put(setGetUserInfoStatus(requestStatus.STARTED));
  /**
   * Call firestore to get user with uid
   * if exists -> save the infos in state
   * else -> create the user with uid
   */
  try {
    let uid = userParams?.uid;
    let email = userParams?.email;
    if (!uid && !email) {
      const auth = yield store.getState().firebase.auth;
      uid = auth.uid;
      email = auth.email;

      analytics.setUserId(uid);
    }

    const userRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = yield firestore.doc(
      `users/${uid}`
    );
    const userSnapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = yield userRef.get();

    //console.debug(userRef, userSnapshot.data(), userSnapshot.exists);

    // If user doesn't exists
    if (!userSnapshot.exists) {
      // Create new user with id document = UID
      const userInfo = {
        uid,
        email,
        created_at: new Date(),
      };
      yield userRef.set(userInfo);
      // Save in STATE the user info
      yield put(setGetUserInfo(userInfo));
    } else {
      yield put(
        setGetUserInfo({
          uid: userSnapshot.id,
          ...userSnapshot.data(),
        })
      );
    }
    const isUserAnAdmin: boolean = yield isAdmin();
    if (isUserAnAdmin) {
      yield put(
        setGetUserInfo({
          is_admin: isUserAnAdmin,
        })
      );
    }
  } catch (error) {
    yield put(setGetUserInfoError(error));
    yield put(setGetUserInfoStatus(requestStatus.ERROR));
  }
  yield put(setGetUserInfoStatus(requestStatus.COMPLETED));
}

/**
 * *Google sign-in
 */
export function* googleSignIn() {
  GoogleProvider.setCustomParameters({ prompt: "select_account" });
  try {
    yield put(changeLoginStatus(requestStatus.STARTED));
    yield auth.setPersistence(authConstants.Auth.Persistence.LOCAL).then(() => {
      return auth.signInWithPopup(GoogleProvider);
    });
  } catch (err) {
    yield put(loginError(err));
    yield put(changeLoginStatus(requestStatus.ERROR));
  }
  yield put(changeLoginStatus(requestStatus.COMPLETED));
}

/**
 * * Passwordless sign-in
 * @param param0 email to sign in
 */
export function* passwordLessSignIn({ payload: email }: ISignInPL) {
  try {
    yield put(changeLoginStatus(requestStatus.STARTED));
    yield auth.sendSignInLinkToEmail(email, actionCodeSettings);
    yield localStorage.setItem(localStorageKeys.PASSWORDLESS_EMAIL, email);
  } catch (error) {
    yield put(loginError(error));
    yield put(changeLoginStatus(requestStatus.ERROR));
  }
  yield put(changePasswordLessSignInStatus({ emailSent: true }));
  yield put(changeLoginStatus(requestStatus.COMPLETED));
}

export function* completePasswordLessSignIn({
  payload: locationHref,
}: ISignInPL) {
  try {
    // Confirm the link is a sign-in with email link.
    if (yield auth.isSignInWithEmailLink(locationHref)) {
      let email = yield localStorage.getItem(
        localStorageKeys.PASSWORDLESS_EMAIL
      );
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = yield window.prompt(
          "Fornisci la tua email per completare il login"
        );
      }
      // The client SDK will parse the code from the link for you.
      yield auth.signInWithEmailLink(email, locationHref);
      // Clear email from storage.
      localStorage.removeItem(localStorageKeys.PASSWORDLESS_EMAIL);
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
    }
  } catch (error) {
    yield put(loginError(error));
  }
}

/**
 * * UPDATE USER INFO
 * @param param0
 */
export function* setUpdateUserInfo({ payload: userInfo }: ISignInPL) {
  yield put(updateUserInfoChangeStatus(requestStatus.STARTED));
  try {
    const requestBody = JSON.parse(JSON.stringify({ ...userInfo }));
    yield firestore
      .collection("users")
      .doc(yield select((state) => state.firebase?.auth?.uid))
      .update({
        ...requestBody,
        updated_at: firebaseStorage.firestore.FieldValue.serverTimestamp(),
      });
    yield put(
      setGetUserInfo({
        ...userInfo,
      })
    );
    // COMPLETED
    yield put(updateUserInfoChangeStatus(requestStatus.COMPLETED));
  } catch (error) {
    yield put(updateUserInfoSetError(error));
    // ERROR
    yield put(updateUserInfoChangeStatus(requestStatus.ERROR));
  }
}

export function* signOut() {
  try {
    yield firebaseStorage.auth().signOut();
    yield put(eraseUserDataAction());
  } catch (error) {
    console.error(error);
  }
}

/**
 * This function is used to get order pagination
 *
 * @param payload limit
 */
export function* getUserOrderSaga({ payload: limit }: ISignInPL) {
  yield put(setUserOrderFetchingAction(true));
  try {
    const userUID = yield select((state) => state.firebase?.auth?.uid);
    const last_snapshot = yield select(
      (state) => state.user.orders.last_snapshot
    );
    const orders = yield select((state) => state.user.orders.orders);
    // console.debug(last_snapshot, limit, userUID);
    let snapshots: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = yield firestore
      .collection(collection.users)
      .doc(userUID)
      .collection(collection.orders);

    switch (config.env) {
      case "LOCAL":
        query = query.where("is_a_local_test", "==", true);
        break;
      case "TEST":
        query = query.where("is_a_test", "==", true);
        break;
      default:
        query = query.where("is_live", "==", true);
        break;
    }

    query = query.orderBy("delivering_date", "desc");

    if (last_snapshot) {
      query = yield query.startAfter(last_snapshot.data().delivering_date);
    }

    snapshots = yield query.limit(limit).get();
    let results = [] as firebase.firestore.DocumentData[];

    const orderIDs = orders.map((order) => order.orderID);

    results = [...orders];

    snapshots.forEach((snap) => {
      if (!orderIDs.includes(snap.data().orderID)) {
        results.push(snap.data());
      }
    });

    yield put(setUserOrderAction(results));
    yield put(
      setUserOrderLastSnapshotAction(snapshots.docs[snapshots.docs.length - 1])
    );
    yield put(setUserOrderFetchingAction(false));
  } catch (error) {
    yield put(setUserOrderErrorAction(error));
    yield put(setUserOrderFetchingAction(false));
  }
}

// EXPORT
export default function* userSagas() {
  yield all([
    takeLatest(userTypes.SIGN_IN_PL_USER_START, passwordLessSignIn),
    takeLatest(userTypes.SIGN_UP_PL_FINISH_LOGIN, completePasswordLessSignIn),
    takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn),
    takeLatest(userTypes.EMAIL_SIGN_IN_START, emailSignIn),
    takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated),
    takeLatest(userTypes.GET_USER_INFO, getUserInfoFromUid),
    takeLatest(userTypes.UPDATE_USER_INFO, setUpdateUserInfo),
    takeLatest(userTypes.SIGN_OUT, signOut),
    takeLatest(userTypes.GET_USER_ORDER_ACTION, getUserOrderSaga),
  ]);
}
