import { Card, Col, Row, Spin, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { finishSignUpPasswordLess } from "../../../redux/User/user.actions";

interface Props {}

const FinishSignUp = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(finishSignUpPasswordLess(window.location.href));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row style={{ marginTop: "50px" }} justify="center">
      <Col xs={20} xxl={5} xl={6} lg={8} sm={12}>
        <Card
          bodyStyle={{
            height: "400px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src="/assets/logo/logo.svg"
              width="80%"
              alt=""
              loading="lazy"
            />
          </div>
          <div>
            <Spin size="large" />
          </div>
          <Typography.Paragraph>
            Stiamo facendo gli ultimi controlli...
          </Typography.Paragraph>
        </Card>
      </Col>
    </Row>
  );
};

export default FinishSignUp;
