import React from "react";
import { Layout } from "antd";
import { Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Statistics from "./pages/Statistics";
import Products from "./pages/Products";
import AddProduct from "./pages/Products/AddProduct/AddProduct";
import Path from "../utils/pathConstants";
import AdminRoute from "../authentication/AdminRoute";
import AdminDrawer from "./AdminDrawer";
import Configurations from "./pages/Configurations";
import Orders from "./pages/Orders/Orders";

const { Content } = Layout;
interface Props {}

const AdminConsole = (props: Props) => {
  return (
    <BrowserRouter>
      <Layout>
        <Layout>
          <AdminDrawer />
          <Layout style={{ padding: "0 24px 24px", minHeight: "90vh" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
              }}
            >
              <Switch>
                <AdminRoute exact path={`${Path.ADMIN}/dashboard`}>
                  <Dashboard />
                </AdminRoute>
                <AdminRoute exact path={`${Path.ADMIN}/orders`}>
                  <Orders />
                </AdminRoute>
                <AdminRoute exact path={`${Path.ADMIN}/stats`}>
                  <Statistics />
                </AdminRoute>
                <AdminRoute exact path={`${Path.ADMIN}/products`}>
                  <Products />
                </AdminRoute>
                <AdminRoute exact path={`${Path.ADMIN}/products/add-product`}>
                  <AddProduct />
                </AdminRoute>
                <AdminRoute
                  exact
                  path={`${Path.ADMIN}/products/update-product`}
                >
                  <AddProduct />
                </AdminRoute>
                <AdminRoute exact path={`${Path.ADMIN}/configurations`}>
                  <Configurations />
                </AdminRoute>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
};

export default AdminConsole;
