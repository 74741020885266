import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
// import reducers
import websiteReducer from "./Website/website.reducer";
import productsReducer from "./Products/products.reducer";
import userReducer from "./User/user.reducer";
import shopReducer from "./Shop/shop.reducer";
import cartReducer from "./Cart/cart.reducer";
import checkoutReducer from "./Checkout/checkout.reducer";

export default combineReducers({
  user: userReducer,
  website: websiteReducer,
  products: productsReducer,
  shop: shopReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  //all the names refers to useSelector use
});
