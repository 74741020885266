import React from "react";
import { Card, Typography } from "antd";
import { Helmet } from "react-helmet";

const { Title, Paragraph } = Typography;

interface Props {}

/**
 *
 * @param props https://www.unipiazza.it/policy/centroesteticodaanna
 * @returns
 */
const TerminiECondizioni = (props: Props) => (
  <>
    <Helmet>
      <title>Terms and Conditions | Le Clematis Fioreria</title>
      <meta name="description" content="Terms and Conditions" />
    </Helmet>
    <Card bodyStyle={{ textAlign: "start" }}>
      <div>
        <Title>TERMINI E CONDIZIONI GENERALI DI VENDITA</Title>
        <Paragraph>
          Le presenti condizioni generali di vendita e i rapporti contrattuali
          tra{" "}
          <strong>
            Fioreria Le Clematis Di Menato Antonella, Galzignano Terme (PD) Via
            Porto 3{" "}
          </strong>{" "}
          e l’Acquirente sono disciplinati dalla legge italiana. In caso di
          controversia, prima di avviare qualsiasi azione giudiziaria, le parti
          si impegnano a cercare una soluzione stragiudiziale.
        </Paragraph>
        <Paragraph>
          Vi invitiamo a leggere attentamente le presenti condizioni generali di
          vendita (di seguito "CGV"), nonché la Privacy and Cookie Policy che
          regolamentano la navigazione su questo sito nonché i nostri rapporti
          nel caso in cui ordiniate Prodotti e Servizi su questo Sito.
        </Paragraph>
        <Paragraph>
          Qualsiasi acquisto di un prodotto proposto sul Sito presuppone la
          consultazione e l’accettazione esplicita delle CGV. Per l’accettazione
          non è necessaria una firma autografa da parte vostra.
        </Paragraph>
        <Paragraph>
          Ai sensi delle disposizioni legislative e normative applicabili e
          relative alla firma elettronica, si ricorda che la convalida
          dell’ordine di acquisto, come precisata nelle presenti CGV,
          costituisce firma elettronica, che ha tra le parti lo stesso valore
          della firma autografa, vale quale prova dell’ordine di acquisto e
          dell’esigibilità delle somme dovute ai sensi del suddetto ordine.
        </Paragraph>
        <Paragraph>
          Nell’ambito delle presenti CGV si conviene quanto segue: "Voi" o
          l’"Acquirente" indica la persona che naviga sul Sito e/o acquista i
          prodotti sul Sito; "Noi" o il "Venditore" indica la società
          identificata nelle note legali di cui sopra; l’Acquirente e il
          Venditore saranno congiuntamente denominati le "Parti" e singolarmente
          la "Parte".
        </Paragraph>

        <ol>
          <li>
            <Title level={3}>CONDIZIONI DI ACQUISTO</Title>
            <Paragraph>
              I Prodotti e i servizi che proponiamo sul Sito sono riservati alle
              persone: (i) maggiorenni (o minorenni titolari di
              un’autorizzazione del soggetto esercitante la responsabilità
              genitoriale e che consente di effettuare un ordine sul Sito); (ii)
              in possesso della piena capacità giuridica per effettuare ordini
              sul Sito.
            </Paragraph>
            <Paragraph>
              Ci riserviamo il diritto in qualsiasi momento di chiedervi un
              documento d’identità che attesti la vostra età e/o la suddetta
              autorizzazione. Ci riserviamo il diritto di non dare seguito a un
              ordine d’acquisto e/o di escludere dal nostro database di
              clienti/potenziali clienti qualsiasi Acquirente che non abbia
              risposto alla richiesta di cui sopra o che non abbia rispettato i
              principi enunciati nella presente clausola.
            </Paragraph>
          </li>
          <li>
            <Title level={3}>PRODOTTI E PREZZI</Title>
            <ol type="a">
              <li>
                <Title level={5}>Prodotti</Title>
                <Paragraph>
                  I prodotti cosmetici proposti in vendita sul Sito, nonché i
                  servizi accessori eventualmente collegati e/o i prodotti
                  complementari (di seguito “Prodotti”) sono quelli presenti sul
                  Sito il giorno della consultazione e/o navigazione sullo
                  stesso da parte dell’Acquirente. Se un Prodotto non è
                  disponibile, l’Acquirente viene informato di tale
                  indisponibilità successivamente alla validazione dell’ordine e
                  prima della consegna, mediante e-mail. L’Acquirente è
                  informato del fatto che le fotografie e i testi che illustrano
                  i Prodotti sono soggetti a variazioni.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Prezzi</Title>
                <Paragraph>
                  I prezzi sono indicati in Euro (€) e sono validi in tutto il
                  territorio italiano, ad esclusione di Livigno, Campione
                  di’Italia, Repubblica di San Marino e Stato Città del
                  Vaticano, dove non verrà effettuata la spedizione. I prezzi
                  tengono conto di eventuali sconti e dell’IVA applicabili nel
                  giorno di effettuazione dell’ordine. I prezzi indicati non
                  tengono conto dei costi di elaborazione e di consegna che
                  saranno fatturati in aggiunta e il cui importo per ciascun
                  ordine è indicato nella tabella sottostante:
                </Paragraph>
                <Paragraph>
                  <table style={{ width: "100%" }}>
                    <tr>
                      <th>MODALITA' DI CONSEGNA</th>
                      <th>TARIFFA</th>
                    </tr>
                    <tr>
                      <td>Consegna a domicilio</td>
                      <td>€ 5.00</td>
                    </tr>
                    <tr>
                      <td>Ritiro in negozio</td>
                      <td>GRATUITA</td>
                    </tr>
                  </table>
                </Paragraph>
                <Paragraph>
                  Si precisa che le eventuali promozioni accordate sotto forma
                  di buoni sconto sono valide esclusivamente per un solo ordine
                  per utenza e in un determinato periodo di validità e non sono
                  cumulabili con altri sconti. Il Venditore si riserva il
                  diritto di modificare i prezzi in qualsiasi momento; tuttavia
                  i Prodotti sono fatturati in base alle tariffe in vigore al
                  momento della registrazione dell’ordine, fatta salva la
                  disponibilità degli stessi.
                </Paragraph>
              </li>
            </ol>
          </li>
          <li>
            <Title level={3}>REGISTRAZIONE E CONVALIDA DELL’ORDINE</Title>
            <ol type="a">
              <li>
                <Title level={5}>Modalità di inserimento di un ordine</Title>
                <Paragraph>
                  Per inserire un ordine l’Acquirente deve dapprima collegarsi
                  al Sito. Successivamente, l’Acquirente può approfondire la
                  conoscenza dei diversi Prodotti proposti in vendita il giorno
                  della consultazione del Sito e scegliere i Prodotti (i)
                  navigando nelle varie categorie di Prodotti o (ii) digitando
                  direttamente il nome del Prodotto nel motore di ricerca
                </Paragraph>
                <Paragraph>
                  Durante la navigazione sul Sito l’Acquirente che desidera
                  effettuare un ordine può farlo cliccando semplicemente sul
                  pulsante destinato ad aggiungere il Prodotto al carrello. Una
                  volta effettuata questa operazione, l’Acquirente visualizzerà
                  il numero di Prodotti presenti all’interno del proprio
                  carrello.
                </Paragraph>
                <Paragraph>
                  L’Acquirente può proseguire la visita o cliccare sul carrello
                  per visualizzarne il contenuto, visualizzando tutti gli
                  elementi dell’ordine, le caratteristiche fondamentali dei
                  Prodotti, l’importo complessivo dell’ordine, i dati per la
                  consegna, le tempistiche, i costi e le eventuali limitazioni
                  di consegna nonché i diritti e le garanzie legali a
                  disposizione dell’Acquirente.
                </Paragraph>
                <Paragraph>
                  L’Acquirente può ritornare sul suo ordine, completarlo,
                  modificarlo, annullarlo solo se non lo ha ancora convalidato
                  effettivamente - ossia fino all’effettivo pagamento alle
                  condizioni previste nelle presenti CGV. Se l’Acquirente
                  desidera scegliere altri Prodotti, può proseguire la
                  navigazione sul Sito, accertandosi che il/i Prodotto/i che ha
                  selezionato sia/siano presente/i nel suo carrello.
                </Paragraph>
                <Paragraph>
                  Una volta terminata la selezione dei Prodotti, l’Acquirente
                  può convalidare l’ordine mediante l’apposito pulsante. A quel
                  punto si aprirà una nuova pagina che invita l’Acquirente a
                  compilare l’indirizzo di spedizione del/dei prodotti. Dopo
                  aver preso visione dell’ordine l’Acquirente è invitato a:
                  cliccare sul pulsante che consente di accedere al pagamento
                  dell’ordine, alle condizioni descritte nelle presenti CGV,
                  oppure tornare indietro per modificare il suo carrello e/o i
                  dati precedentemente inseriti.
                </Paragraph>
                <Paragraph>
                  I dati registrati dal Venditore costituiscono la prova di
                  tutte le transazioni intercorse tra il Venditore e
                  l’Acquirente.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Conferma dell’ordine</Title>
                <Paragraph>
                  Una volta confermato l’ordine e convalidato l’Acquirente
                  riceverà all’indirizzo e-mail indicato in fase di
                  registrazione, una conferma d’ordine con il riepilogo degli
                  articoli ordinati. Il Venditore inoltre provvederà a inviare,
                  nel più breve tempo possibile, un’e-mail di conferma di presa
                  in carico dell’ordine all’indirizzo di posta elettronica
                  comunicato dall’Acquirente.
                </Paragraph>
                <Paragraph>
                  Al fine di tutelare il Venditore da eventuali frodi e
                  nell’ipotesi in cui il Venditore o il fornitore del servizio
                  di pagamento online sospetti il carattere fraudolento di un
                  ordine, il Venditore o il fornitore del servizio di pagamento
                  si riserva il diritto di chiedere all’Acquirente (prima
                  dell’elaborazione dell’ordine) ulteriori documenti (in
                  particolare giustificativi di domicilio e di addebito a nome
                  dell’Acquirente, fotocopia della carta d’identità
                  dell’Acquirente, fotocopia della carta di credito
                  dell’Acquirente) allo scopo di verificare il carattere non
                  fraudolento dell’ordine.
                </Paragraph>
                <Paragraph>
                  In assenza di risposta da parte dell’Acquirente entro i 15
                  (quindici) giorni successivi all’invio della richiesta da
                  parte del Venditore o del fornitore del servizio di pagamento
                  on line, l’ordine sarà annullato e il Venditore procederà
                  entro i 14 (quattordici) giorni successivi alla scadenza del
                  termine summenzionato al rimborso dell’ordine secondo la
                  modalità di pagamento utilizzata dall’Acquirente.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Prova dell’ordine</Title>
                <Paragraph>
                  Per esplicito accordo tra il Venditore e l’Acquirente i
                  messaggi di posta elettronica fanno fede tra le Parti, così
                  come i sistemi automatici di registrazione utilizzati sul
                  Sito, in particolare relativamente alla tipologia e alla data
                  dell’ordine.
                </Paragraph>
              </li>
            </ol>
          </li>
          <li>
            <Title level={3}>PAGAMENTO</Title>
            <Paragraph>
              L’importo dovuto dall'Acquirente è quello indicato nella conferma
              d’ordine e visualizzato sulla pagina internet dedicata e
              successivamente trasmesso mediante posta elettronica dal Venditore
              all'Acquirente. Al momento della convalida dell’ordine
              l'Acquirente può scegliere tra diverse modalità di pagamento. Il
              pagamento può essere effettuato:
            </Paragraph>
            <ol type="a">
              <li>
                <Title level={5}>Carta di credito/debito</Title>
                <Paragraph>
                  Immediatamente tramite Internet, con carta di credito (Visa,
                  Mastercard, Maestro); In ogni caso, il Venditore si riserva il
                  diritto di rifiutare qualsiasi ordine o qualsiasi consegna in
                  caso di (i) controversia in atto con l'Acquirente; (ii)
                  rifiuto di autorizzazione di pagamento mediante carta di
                  credito da parte degli istituti bancari.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>In negozio</Title>
                <Paragraph>
                  In negozio, presso l'attivita'{" "}
                  <strong>
                    Fioreria Le Clematis Di Menato Antonella, Galzignano Terme
                    (PD) Via Porto 3{" "}
                  </strong>
                  .
                </Paragraph>
              </li>
            </ol>
          </li>
          <li>
            <Title level={3}>CONSEGNA E RICEVIMENTO</Title>
            <ol type="a">
              <li>
                <Title level={5}>Modalità di consegna</Title>
                <Paragraph>
                  Una volta preparato, l’ordine viene spedito all’indirizzo
                  comunicato dall’Acquirente al momento dell’effettuazione
                  dell’ordine. I Prodotti sono consegnati dal Titolare
                  dell'attività o da un addetto subordinato. In caso di assenza
                  dell’Acquirente, il Trasportatore lascia un avviso di
                  passaggio tramite indirizzo email fornito. L’Acquirente deve
                  contattare il Titolare per concordare una nuova data di
                  consegna.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Tempistiche</Title>
                <Paragraph>
                  Gli ordini saldati mediante carta di credito sono confermati
                  entro il termine massimo di 2 (due) giorni lavorativi. Si
                  ricorda che l’ordine viene preparato soltanto il giorno
                  stabilito dall'Acquirente. Le domeniche e i giorni festivi
                  sono considerati giorni di consegna. In ogni caso, l’ordine
                  viene evaso entro il termine della giornata prestabilita. In
                  caso di mancata consegna alla scadenza del suddetto termine,
                  l’Acquirente ha la possibilità di annullare l’ordine alle
                  condizioni previste nell’articolo 5, lett. c) - “Ritardo nella
                  consegna” riportato qui di seguito.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Ritardo nella consegna</Title>
                <Paragraph>
                  In caso di superamento del termine massimo di consegna,
                  l’Acquirente può esercitare il diritto di recesso contattando
                  il Venditore. A partire dall’esercizio del diritto di recesso,
                  il Venditore effettua un rimborso di tutte le somme versate
                  dall’Acquirente (comprese le spese di consegna), secondo la
                  modalità di pagamento utilizzata dall’Acquirente, entro il
                  termine massimo di 14 (quattordici) giorni a partire dal
                  ricevimento della notifica, con esclusione di qualsiasi altro
                  risarcimento. Qualsiasi ritardo nella consegna deve essere
                  segnalato il più rapidamente possibile dall’Acquirente,
                  affinché il Venditore possa effettuare verifiche.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Ricevimento</Title>
                <Paragraph>
                  Ogni consegna è considerata effettuata sin dalla messa a
                  disposizione del/dei Prodotto/i presso l’Acquirente da parte
                  del Trasportatore. Spetta all’Acquirente verificare
                  immediatamente, al momento del ricevimento del/dei Prodotto/i,
                  la conformità e l’integrità del/dei Prodotto/i spedito/i.
                  Qualsiasi anomalia/difetto individuato al momento della
                  consegna (in particolare ritardata consegna, prodotto mancante
                  o danneggiato) deve essere segnalato in maniera completa e
                  precisa dall’Acquirente sulla ricevuta consegnata dal
                  Trasportatore al momento della messa a disposizione dei
                  Prodotti e/o confermata mediante cominucazione al Venditore
                  entro la giornata di ricevimento dell'ordine. Qualsiasi
                  difetto deve essere notificato entro il più breve tempo
                  possibile al Venditore.
                </Paragraph>
              </li>
            </ol>
          </li>
          <li>
            <Title level={3}>GARANZIE LEGALI</Title>
            <Paragraph>
              Ai sensi degli articoli 129 e ss. del D.Lgs n. 21/2014 (Codice del
              Consumo), il Venditore è tenuto a consegnare all’Acquirente
              Prodotti conformi all’ordine effettuato dall’Acquirente. Si
              presume che i Prodotti siano conformi se: sono idonei all’uso al
              quale servono abitualmente beni dello stesso tipo; sono conformi
              alla descrizione fornita dal Venditore e possiedono le qualità del
              bene che il Venditore ha presentato all’Acquirente come campione o
              modello; presentano le qualità che un Acquirente può
              legittimamente aspettarsi tenuto conto della natura del bene e
              delle dichiarazioni pubbliche formulate dal Venditore sulle
              caratteristiche specifiche dei beni; sono idonei all’uso
              particolare voluto dall’Acquirente che sia stato da questi portato
              a conoscenza del Venditore che lo abbia accettato.
            </Paragraph>
            <Paragraph>
              Parimenti, ai sensi dell’articolo 1490 del Codice Civile, il
              Venditore è tenuto a garantire che i Prodotti venduti siano immuni
              da vizi occulti che li rendano inidonei all’uso a cui sono
              destinati o che ne diminuiscano in modo apprezzabile il valore. Se
              i Prodotti consegnati non sono conformi ai Prodotti ordinati
              dall’Acquirente o se presentano vizi occulti, l’Acquirente deve
              inviare un’e-mail al Venditore per comunicare la non-conformità o
              i vizi occulti del/dei suo/i Prodotto/i. L’Acquirente ha a
              disposizione 2 (due) anni di tempo a partire dalla consegna dei
              Prodotti acquistati per esercitare la garanzia contro i vizi
              occulti e deve essere fatta valere dall’Acquirente entro due mesi
              dalla scoperta del difetto. Qualsiasi reclamo formulato dopo il
              suddetto termine di 2 (due) sarà respinto e Fioreria Le Clematis
              Di Menato Antonella sarà sollevata da qualsiasi responsabilità. A
              seguito della segnalazione di non-conformità o di presenza di
              vizi, Il Servizio Clienti del Venditore confermerà di aver
              ricevuto la richiesta dell’Acquirente e lo informerà sulla
              procedura da seguire. Al ricevimento delle istruzioni da parte del
              Servizio Clienti del Venditore, l’Acquirente rimanderà i Prodotti
              non conformi o interessati da un vizio occulto al Venditore al
              seguente indirizzo: Fioreria Le Clematis Di Menato Antonella,
              Galzignano Terme (PD) Via Porto 3
            </Paragraph>
          </li>
          <li>
            <Title level={3}>ESCLUSIONE DEL DIRITTO DI RECESSO</Title>
            <Paragraph>
              Trattandosi di vendita di prodotti che “rischiano di deteriorarsi
              o scadere rapidamente” essendo soggetti ad alterazione anche in
              conseguenza di una conservazione non appropriata, per motivi
              igienici e di tutela dei Clienti, il diritto di recesso non è
              applicabile. All'Acquirente viene pertanto concessa la facoltà di
              restituire i beni acquistati unicamente per non conformità
              dell’ordine o dei prodotti (cioè per prodotto o package
              manifestamente danneggiato alla consegna o quantitativi e/o
              tipologia scorretta rispetto all’ordine).
            </Paragraph>
          </li>
          <li>
            <Title level={3}>VARIE</Title>
            <ol type="a">
              <li>
                <Title level={5}>Forza maggiore</Title>
                <Paragraph>
                  Nessuna delle Parti sarà responsabile per l'inadempimento
                  totale o parziale o per il ritardo nell'esecuzione delle
                  proprie obbligazioni in forza del presente contratto che siano
                  causati da un evento che non può essere impedito (di seguito
                  gli “Eventi di Forza Maggiore”). Gli Eventi di Forza Maggiore
                  potranno includere, a mero titolo esemplificativo e non
                  esaustivo, guerre, sommosse, insurrezioni, grave disturbo
                  della sicurezza su Internet, guasti tecnici, accessi non
                  autorizzati e/o intrusioni nei server del sito web, scioperi
                  di qualunque forma, guasti telematici o telefonici, ecc. La
                  Parte che assume si sia verificato o vi sia il rischio che si
                  verifichi un Evento di Forza Maggiore, dovrà informare l'altra
                  Parte entro 5 giorni lavorativi dall’evento o dal predetto
                  rischio. Le Parti concordano di incontrarsi al fine di
                  determinare congiuntamente i termini e le condizioni per la
                  compilazione degli ordini durante la presenza di un Evento di
                  Forza Maggiore. Dopo 1 (un) mese di interruzione dovuto ad un
                  evento di Forza Maggiore, Fioreria Le Clematis Di Menato
                  Antonella potrà scegliere di annullare gli ordini e di
                  rimborsare l'Acquirente, ove dovuto.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Nullità parziale</Title>
                <Paragraph>
                  Se una o più clausole delle presenti CGV saranno considerate
                  nulle o dichiarate tali in applicazione di una legge, di un
                  regolamento o in seguito a una decisione definitiva di una
                  giurisdizione competente, le altre clausole rimarranno valide
                  ed efficaci.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Intero accordo</Title>
                <Paragraph>
                  Le presenti CGV e il riepilogo dell’ordine trasmesso
                  all'Acquirente formano un insieme contrattuale e costituiscono
                  l’integralità dei rapporti contrattuali stipulati tra le
                  Parti. In caso di discordanza tra i suddetti documenti,
                  prevarranno le presenti CGV.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Modifica delle CGV</Title>
                <Paragraph>
                  Si precisa che l’Acquirente può salvare o stampare le presenti
                  CGV, a condizione di non modificarle. Le CGV in vigore possono
                  essere consultate in qualsiasi momento alla voce “Condizioni
                  Generali di Vendita” accessibile da tutte le pagine del Sito.
                  Il Venditore potrà, in qualsiasi momento, aggiornare le
                  presenti CGV. Ogni versione aggiornata delle presenti CGV
                  verrà resa disponibile sul Sito nella sezione dedicata:
                  Fioreria Le Clematis Di Menato Antonella invita pertanto tutti
                  gli utenti a consultare periodicamente il Sito per essere
                  sempre informati dell'ultima versione caricata.
                </Paragraph>
              </li>
              <li>
                <Title level={5}>Legge applicabile e Foro competente</Title>
                <Paragraph>
                  Alle CGV ed ai rapporti di natura contrattuale che
                  intercorrono tra Fioreria Le Clematis Di Menato Antonella e
                  l'Acquirente si applica la legge italiana. Per qualsivoglia
                  controversia che dovesse insorgere in merito alla
                  interpretazione e/o esecuzione del presente contratto, sarà
                  competente il giudice del luogo di residenza o di domicilio
                  del consumatore, se ubicati nel territorio dello Stato.
                </Paragraph>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </Card>
  </>
);

export default TerminiECondizioni;
