const productsTypes = {
  ADD_IMG_URL: "ADD_IMG_URL",
  ADDED_IMAGE_PRODUCT: "ADDED_IMAGE_PRODUCT",
  START_UPLOADING: "START_UPLOADING",
  START_UPLOAD_PRODUCT: "START_UPLOAD_PRODUCT",
  CHANGE_UPLOAD_PRODUCT_STATUS: "CHANGE_UPLOAD_PRODUCT_STATUS",
  STORE_PRODUCT_IMAGE: "STORE_PRODUCT_IMAGE",
  GET_PRODUCTS_LIST: "GET_PRODUCTS_LIST",
  CHANGE_GET_PRODUCT_STATUS: "CHANGE_GET_PRODUCT_STATUS",
  SET_PRODUCTS_LIST: "SET_PRODUCTS_LIST",
  DELETE_PRODUCT: "DELETE_PRODUCT",
  CHANGE_DELETION_PRODUCT_STATUS: "CHANGE_DELETION_PRODUCT_STATUS",
  UPDATE_PRODUCT: "UPDATE_PRODUCT",
  CHANGE_UPDATING_PRODUCT_STATUS: "CHANGE_UPDATING_PRODUCT_STATUS",
  GET_PRODUCT: "GET_PRODUCT",
  CHANGE_PRODUCTS_LIST_STATUS: "CHANGE_PRODUCTS_LIST_STATUS",
  ADD_FORM_PRODUCT_DATA: "ADD_FORM_PRODUCT_DATA",
  RESET_FORM_PRODUCT_DATA: "RESET_FORM_PRODUCT_DATA",
  START_UPDATE_UPLOAD_PRODUCT: "START_UPDATE_UPLOAD_PRODUCT",
  UPDATE_IMAGE_FORM_PRODUCT_DATA: "UPDATE_IMAGE_FORM_PRODUCT_DATA",
  GET_PLAIN_PRODUCT_DATA: "GET_PLAIN_PRODUCT_DATA",
  RESET_PLAIN_PRODUCT_DATA: "RESET_PLAIN_PRODUCT_DATA",
};

export default productsTypes;
