import { Input } from "antd";
import React from "react";
import config from "../../config";

interface OnChangeHandler {
  (e): void;
}
interface Props {
  value?: any;
  onChange?: OnChangeHandler;
}

const CustomInputUrl = ({ value, onChange }: Props) => {
  const onChangeCustomInput = (e) => {
    console.log(e?.currentTarget?.value);
    e.currentTarget.value = e?.currentTarget?.value?.replace(
      /h*t*t*p*s*:*\/*\/*fioreria-le-clematis\.web\.app|h*t*t*p*s*:*\/*\/*fiorerialeclematis\.it|h*t*t*p*s*:*\/*\/*localhost:3000/g,
      ""
    );
    console.log(e?.currentTarget?.value);
    onChange && onChange(e);
  };

  return (
    <Input
      addonBefore={config.siteEndpoint}
      value={value}
      onChange={onChangeCustomInput}
    />
  );
};

export default CustomInputUrl;
